import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { ButtonConsensus } from 'components';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { colors } from 'utils/colorsSteps';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import aiAssistantService from 'services/ai-assistant/aiAssistantService';
import BetoAIIcon from '../../../../assets/images/Beto AI.png';
import { brown } from '@mui/material/colors';
import { setAiMessages, setChatAiFeature } from 'actions';
import { useLocation } from 'react-router';
import { useFilterInquiry } from 'hooks/useFilterInquiry';
import history from 'utils/history';
import { CHAT_AI_FEATURES, CHAT_AI_SENDERS, CHAT_AI_TEXT_TYPES } from 'constants/ai/chatAiFeatures';
import MuiMarkdown from 'mui-markdown';
import { listClassesAll } from 'utils/constsFormInvestigateds';
import { Container, Link, styled } from '@mui/material';
import TypingIndicator from './components/TypingIndicator';
import { formatDateToISO8601 } from 'utils/date-time.utils';
import InvestigatedDataModal from './components/InvestigatedDataModal';

const useStyles = makeStyles(theme => ({
  chatAiButton: {
    position: 'fixed',
    bottom: 20,
    right: 20,
  },
  chatAi: {
    position: 'fixed',
    bottom: 20,
    right: 20,
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    width: 350,
    height: 450,
    borderRadius: 16,
    boxShadow:
      '0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px -7px rgba(0, 0, 0, 0.2)',
  },
  header: {
    backgroundColor: '#ffffff',
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    flex: 1,
    overflowY: 'auto',
    padding: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    top: '300',
    padding: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#F0F0F0',
  },
  container: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: '15px',
    width: 300,
    padding: '16px',
    borderRadius: '16px',
    backgroundColor: '#F5F5F5',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  },
  chipHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  title: {
    color: '#00597b',
    fontWeight: 'bold',
  },
  description: {
    color: '#light-grey',
    marginTop: '8px',
  },
  iconButton: {
    padding: 0,
    color: '#666',
  },
  avatarImage: {
    width: 15,
  },
  avatarImage2: {
    width: 25,
  },
  titleChat: {
    position: 'relative',
    right: '70px',
    fontFamily: 'Roboto',
  },
  regularMessage: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    color: 'black',
    textAlign: 'left',
    marginTop: 2,
  },
}));

const ChatAi = props => {
  const { handleClose } = props;

  const StyledContainer = styled(Container)(({ theme }) => ({
    '& ul': {
      paddingLeft: theme.spacing(4),
    },
    '& ol': {
      paddingLeft: theme.spacing(4),
    },
    '& h1': {
      fontSize: '2.25rem',
    },
    '& h2': {
      fontSize: '2rem',
    },
  }));
  const StyledContainerForMessages = styled(Container)(({ theme }) => ({
    '& ul': {
      paddingLeft: theme.spacing(4),
    },
    '& ol': {
      paddingLeft: theme.spacing(4),
    },
    '& h1': {
      fontSize: '1.75rem',
    },
    '& h2': {
      fontSize: '1.5rem',
    },
    '& p': {
      textAlign: 'justify',
      fontSize: 14,
    },
  }));

  const reducerMessages = useSelector(state => state.chatAi);
  const loggedUser = useSelector(state => state.logedUser);
  const { selectedIndexGroup } = useSelector(state => state.groupMenu);
  const dispatch = useDispatch();

  const classes = useStyles();
  const location = useLocation();
  const {
    setProsecutorContext,
    setClassInquiryContext,
    setExcludePersecutionContext,
    setProsecutorOfficeIdContext,
    setExcludeNonPersecutionContext,
    setStartDateContext,
    setEndDateContext,
    setInquiryStepContext,
    setInternalSystemNumberContext,
    setInvestigatedNameContext,
    setPoliceInquiryNumberContext,
    setInvestigatedCpfContext,
    setIsArrestedContext,
    setReturnedFromPmContext,
    setIsFlagrantContext,
    setHasPendingInquiryArrivedContext,
    setLawContext,
    setPriorityContext,
    setResponsibleUserContext,
    setCrimeContext,
    setForwarderInstitutionContext,
    setFinalInstitutionContext,
    setHasPendenciesInAgreement,
    setHasDiligenceResponseContext,
    setTasksDeadline,
    setAwaitingInstitutionResponseContext,
    setHasActivatedTaskContext,
    setAgreementStatus,
    setIsRequesFromAiContext,
  } = useFilterInquiry();

  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoadingAnswer, setIsLoadingAnswer] = useState('');

  const [openReadMoreModal, setOpenReadMoreModal] = useState(false);
  const [readMoreModalText, setReadMoreModalText] = useState('');
  const [readMoreModalTextType, setReadMoreModalTextType] = useState(CHAT_AI_TEXT_TYPES.TEXT);
  const [readMoreModalTitle, setReadMoreModalTitle] = useState('');
  const [openInvestigatedDataModal, setOpenInvestigatedDataModal] = useState(false);
  const [investigatedData, setInvestigatedData] = useState(false);
  const messageMaxLength = 500;
  const userMessageParams = {
    alt: 'User avatar',
    src: '',
    bgColor: '',
    name: 'Você',
  };
  const aiMessageParams = {
    alt: 'Beto avatar',
    src: BetoAIIcon,
    bgColor: brown[700],
    name: 'Beto',
  };

  useEffect(() => {
    if (reducerMessages?.messages) {
      setMessages(reducerMessages.messages);
    }
  }, []);

  const handleCloseModal = () => {
    setOpenReadMoreModal(false);
  };

  const handleSend = () => {
    if (input.trim()) {
      handleAddNewMessage(CHAT_AI_SENDERS.USER, input);
    }
  };

  const handleSearchInvestigatedData = () => {
    dispatch(setChatAiFeature(CHAT_AI_FEATURES.SEARCH_INVESTIGATED_DATA));
    handleAddNewMessage(
      CHAT_AI_SENDERS.AI,
      'Qual o cpf do investigado do qual você deseja obter mais informações?',
    );
  };

  const handleLegislationQuestion = () => {
    dispatch(setChatAiFeature(CHAT_AI_FEATURES.QUERY_LAW));
    handleAddNewMessage(
      CHAT_AI_SENDERS.AI,
      'Claro, ficarei feliz em ajudar com informações sobre um tema específico da legislação brasileira. Por favor, me informe qual é o tema que você gostaria de saber mais e eu farei o meu melhor para fornecer uma explicação clara e útil.',
    );
  };

  const handleFindInquiryQuestion = () => {
    dispatch(setChatAiFeature(CHAT_AI_FEATURES.SEARCH_INQUIRIES));
    handleAddNewMessage(
      CHAT_AI_SENDERS.AI,
      'Claro, ficarei feliz em lhe ajudar a encontrar um inquérito específico, ou um grupo de inquéritos, existente no Consensus. Quais os parâmetros do(s) inquérito(s) que você deseja encontrar? Cite dados como classe, cpf do investigado, número interno e etc...',
    );
  };

  const handleAddNewMessage = async (sender, text, lastState, type, filterParams, payload) => {
    const data = lastState || messages;
    const newMessage = {
      sender,
      text,
      type: type || CHAT_AI_TEXT_TYPES.TEXT,
      params: filterParams,
      queryMadeOnFindInquiriesScreen: location.pathname === '/inqueritos',
      payload,
    };
    setMessages([...data, newMessage]);
    setInput('');
    dispatch(setAiMessages(newMessage));
    if (filterParams && location.pathname === '/inqueritos') {
      handleMessageButton(filterParams);
    }
    if (sender === CHAT_AI_SENDERS.USER) {
      if (location.pathname === '/inqueritos') {
        const lastFilterParams = data.findLast(e => !!e.params)?.params;
        await generateAiAnswer(text, [...data, newMessage], lastFilterParams);
        return;
      }
      generateAiAnswer(text, [...data, newMessage]);
    }
  };

  const generateAiAnswer = async (text, lastState, lastFilterParams) => {
    setIsLoadingAnswer(true);
    const conversationHistory = lastState.map(({sender, text}) => {
      return {
        sender, text
      }
    }).filter(({sender}) => sender === CHAT_AI_SENDERS.USER);

    aiAssistantService
      .generateAnswerForQuestion({
        feature: reducerMessages?.lastFeature,
        message: text,
        conversationHistory,
        prosecutorOfficeGroupId: loggedUser.groups[selectedIndexGroup].id,
        lastFilterParams,
        lastPandoraPayload: lastState.findLast(e => !!e.payload)?.payload,
      })
      .then(({ data }) => {
        dispatch(setChatAiFeature(data.feature));
        handleAddNewMessage(
          CHAT_AI_SENDERS.AI,
          data.message.text,
          lastState,
          data.message.type,
          data.params,
          data.payload,
        );
      })
      .catch(e => {
        handleAddNewMessage(CHAT_AI_SENDERS.AI, 'FALHA NA CONEXÃO', lastState);
      })
      .finally(() => {
        setIsLoadingAnswer(false);
      });
  };

  const handleMessageButton = params => {
    if (location.pathname === '/inqueritos') {
      history.push('/Dashboard');
      setFilterContexts(params);
      history.push('/inqueritos');
    } else {
      setFilterContexts(params);
      history.push('/inqueritos');
    }
  };

  const viewInvestigatedInfo = payload => {
    setInvestigatedData(payload);
    setOpenInvestigatedDataModal(true);
  };

  const setFilterContexts = generatedFilters => {
    const { filters } = generatedFilters;
    setProsecutorContext(filters.prosecutorId);
    setExcludePersecutionContext(!filters.excludePersecution);
    setProsecutorOfficeIdContext(filters.prosecutorOfficeId);
    setExcludeNonPersecutionContext(!filters.excludeNonPersecution);
    setStartDateContext(filters.startDate && formatDateToISO8601(filters.startDate));
    setEndDateContext(filters.endDate && formatDateToISO8601(filters.endDate));
    setInquiryStepContext(filters.inquiryStep);
    setInternalSystemNumberContext(filters.internalSystemNumber);
    setInvestigatedNameContext(filters.investigatedName);
    setPoliceInquiryNumberContext(filters.policeInquiryNumber);
    setInvestigatedCpfContext(filters.investigatedCpf);
    setIsArrestedContext(filters.isArrested);
    setReturnedFromPmContext(filters.returnedFromPm);
    setIsFlagrantContext(filters.isFlagrant);
    setHasPendingInquiryArrivedContext(filters.hasPendingInquiryArrived);
    setLawContext(filters.lawId);
    setForwarderInstitutionContext(filters.forwarderInstitutionId)
    setFinalInstitutionContext(filters.finalInstitutionId)
    setHasPendenciesInAgreement(filters.pending)
    setHasDiligenceResponseContext(filters.hasDiligenceResponse);
    setTasksDeadline(filters.tasksDeadline);
    setAwaitingInstitutionResponseContext(filters.awaitingInstitutionResponse);
    setHasActivatedTaskContext(filters.hasActivatedTask);
    setAgreementStatus(filters.agreementWasApproved === false ? 'notApproved' : filters.agreementStatus);
    setIsRequesFromAiContext(true);
    
    if (filters.responsibleUserId) {
      setResponsibleUserContext({
        id: filters.responsibleUserId,
        name: generatedFilters.responsibleName
      })
    }

    if (filters.responsibleUserId) {
      setResponsibleUserContext({
        id: filters.responsibleUserId,
        name: generatedFilters.responsibleName
      })
    }

    if (filters.crimeId) {
      setCrimeContext(generatedFilters.crimeData);
    }
    
    if (!generatedFilters.classGroup) {
      setClassInquiryContext(listClassesAll.find(({ code }) => code == filters.classe));
    } else {
      const classesCodes = filters.classe.split(',').map(c => ({ description: '', code: c }));
      setClassInquiryContext({
        description: generatedFilters.classGroup,
        classes: classesCodes,
      });
    }

    let priority = '';
    if (filters.lawId) {
      priority = 'ViolenceAgainstWomen';
    }
    if (filters.isVictimChildTeen) {
      priority = 'ChildTeen';
    }
    if (filters.isVictimElderly) {
      priority = 'Elderly';
    }
    setPriorityContext(priority);
  };

  return (
    <>
      <Box className={classes.chatAi}>
        <div className={classes.header}>
          <Avatar
            alt="Beto avatar"
            src={BetoAIIcon}
            style={{ backgroundColor: '#ffffff', border: `2px solid ${colors.primary}` }}
            imgProps={{ className: classes.avatarImage2 }}
          />
          <h3 className={classes.titleChat}>Beto 1.0</h3>
          <IconButton
            sx={{ color: theme => theme.palette.grey[500] }}
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
        <div className={classes.content}>
          <List>
            {messages.length === 0 && (
              <>
                <div style={{ textAlign: 'center', fontFamily: 'Roboto', fontSize: 14 }}>
                  <b>
                    Olá {loggedUser?.name?.split(' ')[0]}, pergunte-me qualquer coisa ou escolha uma
                    solução para começar! &#x1F60A;
                  </b>
                </div>
                <Box className={classes.container} onClick={handleFindInquiryQuestion}>
                  <div className={classes.chipHeader}>
                    <Typography variant="h6" className={classes.title}>
                      Buscar Inquéritos
                    </Typography>
                    <IconButton className={classes.iconButton}>
                      <ArrowOutwardIcon fontSize="small" />
                    </IconButton>
                  </div>
                  <Typography variant="body2" className={classes.description}>
                    Encontre inquéritos pelo nome ou status...
                  </Typography>
                </Box>
                <Box className={classes.container} onClick={handleLegislationQuestion}>
                  <div className={classes.chipHeader}>
                    <Typography variant="h6" className={classes.title}>
                      Consulta sobre Legislação /<br />
                      Jurisprudência de leis
                    </Typography>
                    <IconButton className={classes.iconButton}>
                      <ArrowOutwardIcon fontSize="small" />
                    </IconButton>
                  </div>
                  <Typography variant="body2" className={classes.description}>
                    Pergunte qualquer assunto sobre legislação...
                  </Typography>
                </Box>
                <Box className={classes.container} onClick={handleSearchInvestigatedData}>
                  <div className={classes.chipHeader}>
                    <Typography variant="h6" className={classes.title}>
                      Dados do Investigado
                    </Typography>
                    <IconButton className={classes.iconButton}>
                      <ArrowOutwardIcon fontSize="small" />
                    </IconButton>
                  </div>
                  <Typography variant="body2" className={classes.description}>
                    Busque informações a partir do CPF...
                  </Typography>
                </Box>
              </>
            )}
            {messages.map((message, index) => {
              const params =
                message.sender === CHAT_AI_SENDERS.USER ? userMessageParams : aiMessageParams;
              return (
                <ListItem key={index}>
                  <div style={{ marginLeft: 8 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        alt={params.alt}
                        src={params.src}
                        style={{
                          width: 30,
                          height: 30,
                          marginRight: 8,
                          backgroundColor: params.bgColor,
                        }}
                        imgProps={{ className: classes.avatarImage }}
                      />
                      <b style={{ fontFamily: 'Roboto' }}>{params.name}</b>
                    </div>
                    {message.text.length <= messageMaxLength ? (
                      message.type === CHAT_AI_TEXT_TYPES.MARKDOWN ? (
                        <StyledContainerForMessages>
                          <MuiMarkdown>{message.text}</MuiMarkdown>
                        </StyledContainerForMessages>
                      ) : (
                        <Typography className={classes.regularMessage}>{message.text}</Typography>
                      )
                    ) : (
                      <>
                        {message.type === CHAT_AI_TEXT_TYPES.MARKDOWN ? (
                          <StyledContainerForMessages>
                            <MuiMarkdown>{message.text.slice(0, messageMaxLength)}</MuiMarkdown>
                          </StyledContainerForMessages>
                        ) : (
                          <Typography className={classes.regularMessage}>
                            {message.text.slice(0, messageMaxLength)}
                          </Typography>
                        )}
                        <ButtonConsensus
                          title="Ver Mais"
                          size="small"
                          backgroundColor={colors.primary}
                          hoverColor={colors.primary}
                          onClick={() => {
                            setReadMoreModalText(message.text);
                            setReadMoreModalTitle(messages[index - 1].text);
                            setReadMoreModalTextType(message.type);
                            setOpenReadMoreModal(true);
                          }}
                        />
                      </>
                    )}
                    {message.params && !message.queryMadeOnFindInquiriesScreen && (
                      <ButtonConsensus
                        title="Visualizar Inquéritos"
                        size="small"
                        backgroundColor={colors.primary}
                        hoverColor={colors.primary}
                        onClick={() => {
                          handleMessageButton(message.params);
                        }}
                      />
                    )}
                    {message.payload && (
                      <ButtonConsensus
                        title="Visualizar Informações"
                        size="small"
                        backgroundColor={colors.primary}
                        hoverColor={colors.primary}
                        onClick={() => {
                          viewInvestigatedInfo(message.payload);
                        }}
                      />
                    )}
                  </div>
                  <br />
                </ListItem>
              );
            })}
            {isLoadingAnswer && (
              <ListItem>
                <div style={{ marginLeft: 8 }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                      alt={aiMessageParams.alt}
                      src={aiMessageParams.src}
                      style={{
                        width: 30,
                        height: 30,
                        marginRight: 8,
                        backgroundColor: aiMessageParams.bgColor,
                      }}
                      imgProps={{ className: classes.avatarImage }}
                    />
                    <b style={{ fontFamily: 'Roboto' }}>{aiMessageParams.name}</b>
                  </div>
                  <div style={{ marginTop: 8, marginLeft: 6 }}>
                    <TypingIndicator />
                  </div>
                </div>
              </ListItem>
            )}
          </List>
        </div>
        <div className={classes.actions}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Pergunte-me qualquer coisa..."
            value={input}
            disabled={isLoadingAnswer}
            onChange={e => setInput(e.target.value)}
            onKeyPress={e => e.key === 'Enter' && handleSend()}
            InputProps={{
              style: {
                height: '36px',
                backgroundColor: 'white',
              },
            }}
          />
          <Avatar
            onClick={() => !isLoadingAnswer && handleSend()}
            style={{
              marginLeft: 6,
              backgroundColor: isLoadingAnswer ? colors.greySecundary : colors.primary,
              cursor: isLoadingAnswer ? 'wait' : 'pointer',
            }}
          >
            <SendIcon />
          </Avatar>
        </div>
      </Box>
      <Dialog open={openReadMoreModal} fullWidth maxWidth="md">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h2 style={{ marginLeft: 8 }}>{readMoreModalTitle}</h2>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{ color: theme => theme.palette.grey[500] }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          {readMoreModalTextType === CHAT_AI_TEXT_TYPES.MARKDOWN ? (
            <StyledContainer>
              <MuiMarkdown
                options={{
                  overrides: {
                    a: {
                      component: Link,
                      props: {
                        target: '_blank',
                        rel: 'noopener noreferrer',
                      },
                    },
                  },
                }}
              >
                {readMoreModalText}
              </MuiMarkdown>
            </StyledContainer>
          ) : (
            readMoreModalTextType
          )}
        </DialogContent>
        <DialogActions>
          <ButtonConsensus
            autoFocus
            onClick={handleCloseModal}
            title="Fechar"
            hovercolor={colors.greySecundary}
            color={colors.greyShadow}
            backgroundColor="#fff"
          />
        </DialogActions>
      </Dialog>
      <InvestigatedDataModal
        open={openInvestigatedDataModal}
        handleClose={() => setOpenInvestigatedDataModal(false)}
        data={investigatedData}
      />
    </>
  );
};

export default ChatAi;
