import React, { useState, useEffect, useCallback } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import InputConsensus from 'components/InputConsensus';
import prosecutorOfficeService from 'services/prosecutor-office/prosecutorOfficeService';
import debounce from 'lodash.debounce';
import { Chip } from '@mui/material';

const OtherActivePolesAutoComplete = props => {
  const initialGetDataValues = { page: 1, size: 20 };
  const { formState, setFormState, handleChange } = props;

  const [prosecutorOffices, setProsecutorOffices] = useState(formState.activePoles || []);

  const [prosecutorOfficesOptions, setProsecutorOfficesOptions] = useState([]);
  const [inputSearchProsecutorOffice, setInputSearchProsecutorOffice] = useState('');

  const [pageProsecutorOffice, setPageProsecutorOffice] = useState(0);
  const [lastPageProsecutorOffice, setLastPageProsecutorOffice] = useState(0);
  const [loadingProsecutorOfficeOptions, setLoadingProsecutorOfficeOptions] = useState(false);

  const { inquiryJudicialDistrictId } = props;

  useEffect(() => {
    debounceGetProsecutorOffice('');
  }, []);

  const debounceGetProsecutorOffice = useCallback(
    debounce(newValue => getProsecutorOffice(newValue), 500),
    [],
  );

  const getProsecutorOffice = newValue => {
    setLoadingProsecutorOfficeOptions(true);
    setPageProsecutorOffice(initialGetDataValues.page);

    let params = {
      judicialDistrictId: inquiryJudicialDistrictId,
      page: initialGetDataValues.page,
      size: initialGetDataValues.size,
      name: newValue,
    };

    prosecutorOfficeService
      .getProsecutorOfficesService(params)
      .then(response => {
        setProsecutorOfficesOptions(response.data.data);
        setLastPageProsecutorOffice(response.data.lastPage);
      })
      .finally(() => setLoadingProsecutorOfficeOptions(false));
  };

  const updateValueProsecutorOffice = newValue => {
    if (!prosecutorOfficesOptions.find(prosecutorOffice => prosecutorOffice.name === newValue)) {
      debounceGetProsecutorOffice(newValue);
      setInputSearchProsecutorOffice(newValue);
    }
  };

  const loadMoreProsecutorOffice = () => {
    const nextPage = pageProsecutorOffice + 1;
    setPageProsecutorOffice(nextPage);
    setLoadingProsecutorOfficeOptions(true);

    let params = {
      page: nextPage,
      size: initialGetDataValues.size,
      judicialDistrictId: inquiryJudicialDistrictId,
    };

    prosecutorOfficeService
      .getProsecutorOfficesService(params)
      .then(response => {
        setProsecutorOfficesOptions(prosecutorOfficeOptions => [
          ...prosecutorOfficeOptions,
          ...response.data.data,
        ]);
        setLastPageProsecutorOffice(response.data.lastPage);
      })
      .finally(() => setLoadingProsecutorOfficeOptions(false));
  };

  const handleScrollProsecutorOffice = event => {
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (
      listboxNode.scrollHeight - position <= 1 &&
      pageProsecutorOffice < lastPageProsecutorOffice &&
      !loadingProsecutorOfficeOptions
    ) {
      loadMoreProsecutorOffice();
    }
  };

  const handleChangeProsecutorOffice = (event, newValue) => {
    event.persist();
    setProsecutorOffices(newValue);
    setInputSearchProsecutorOffice('');
    debounceGetProsecutorOffice('');
    handleChange('activePoles', newValue);
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Autocomplete
        multiple={true}
        value={prosecutorOffices}
        onInputChange={(_, newValue) => updateValueProsecutorOffice(newValue)}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={option => option.name || ''}
        fullWidth
        noOptionsText="Nada foi encontrado"
        loadingText="Buscando..."
        loading={loadingProsecutorOfficeOptions}
        clearOnBlur={false}
        options={prosecutorOfficesOptions}
        onChange={handleChangeProsecutorOffice}
        renderOption={option => <>{`${option.name}`}</>}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              label={option.name}
              size="small" // Define o tamanho da tag
              style={{ fontSize: '12px' }} // Ajusta o tamanho da fonte
            />
          ))
        }
        renderInput={params => (
          <InputConsensus
            {...params}
            label="Outros Polos Ativos (promotorias)"
            name="prosecutorOffices"
            fullWidth
            onClick={() => debounceGetProsecutorOffice(inputSearchProsecutorOffice)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loadingProsecutorOfficeOptions ? (
                    <CircularProgress color="primary" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        ListboxProps={{
          onScroll: handleScrollProsecutorOffice,
        }}
      />
    </Grid>
  );
};

export default OtherActivePolesAutoComplete;
