/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-extra-boolean-cast */
import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Card,
  Chip,
  TextField,
  Grid,
  Typography,
  IconButton,
  Box,
  Tooltip,
  LinearProgress,
  FormControlLabel,
  Checkbox,
  MenuItem,
  CircularProgress,
  Switch,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import {
  InputConsensus,
  ModalConfirm,
  ButtonConsensus,
  HeaderModal,
  ProsecutorOfficeAndProsecutorForm,
} from 'components';
import {
  ModalAddFileChangeLevelForm,
  TableModalChangeLevelForm,
  ModalSubtype,
  ModalApplyToAllError,
} from './components';
import { AttachFile, Add } from '@material-ui/icons';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Moment from 'react-moment';
import { Alert, Autocomplete, Skeleton } from '@material-ui/lab';
import { colors } from 'utils/colorsSteps';
import { documentStatusList } from 'constants/subtypes/subtypes';
import history from 'utils/history';
import { toast } from 'react-toastify';
import inquiryService from 'services/inquiries/inquiryService';
import organService from 'services/organ/organService';
import movementTaskService from 'services/movement-task/movementTaskService';
import debounce from 'lodash.debounce';
import { formatDateToISOString } from 'utils/date-time.utils';
import { onCloseWithBackdropDialog } from 'utils/materialUI/backdropClickDialog';
import { STEPS_INQUIRY } from 'constants/inquirys/stepsInquiry';
import { useSelector } from 'react-redux';
import { USER_TYPE } from 'constants/inquirys/userType';
import FormPreRegisterPJE from 'components/FormPreRegisterPJE';
import pjeIntegrationService from 'services/pje-integration/pjeIntegrationService';
import ModalAddPjeFile from 'components/ModalFormPJE/components/ModalAddFile';
import { listClassesAtosInfracionais } from 'utils/constsFormInvestigateds';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import _ from 'lodash';
import ModalDocsEdit from './components/ModalDocsEdit';
import { Badge } from '@mui/material';
import { listInfanciaEJuventude } from '../../constants/subtypes/subtypes';

const ModalChangeLevelForm = props => {
  const {
    open,
    onClose,
    className,
    inquiry,
    title,
    refreshTypeInquiry,
    setRefreshTasks,
    isMigration = false,
    loading = false,
    ...rest
  } = props;

  const { automaticMovements, connectWithAIService } = useSelector(state => state.customerConfig);
  const isInfraction = listClassesAtosInfracionais.some(act => act.code === inquiry.inquiry.classe);
  let automaticTasks;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const loggedUser = useSelector(state => state.logedUser);
  const isProsecutor = loggedUser.type === USER_TYPE.Prosecutor;
  const { enablePreRegisterPje } = useCustomerConfig();

  const [confirm, setConfirm] = useState(false);
  const [textMsg, setTextMsg] = useState('Atividade realizada com sucesso');
  const [disabledBtn, setDisabledBtn] = useState(false);

  const [openModalAddPjeFiles, setOpenModalAddPjeFiles] = useState(false);
  const [pjeFileOptions, setPjeFileOptions] = useState([]);
  const [loadingPjeData, setLoadingPjeData] = useState(false);

  const [openModalListFileNewInquiry, setOpenModalListFileNewInquiry] = useState(false);
  const [uploadObject, setUploadObject] = useState([]);
  const [fileInBase64, setFileInBase64] = useState([]);
  const [haveTask, setHaveTask] = useState(true);
  const [loadTask, setLoadTask] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [row, setRow] = useState(null);

  const [isComplaint, setIsComplaint] = useState(false);
  const { isMPDFT } = useCustomerConfig();

  const initialValues = {
    justification: '',
    type: '',
    name: '',
    date: '',
    place: null,
    shouldMoveInquiry: false,
    subtype: {
      code: '',
      name: '',
      targetDocumentStatus: '',
    },
    applyToAll: null,
    prosecutor: isProsecutor
      ? { ...loggedUser, id: loggedUser.userId }
      : inquiry.inquiry.prosecutor,
    prosecutorOffice: inquiry.inquiry.prosecutorOffice,
    integrateWithPJE: false,
    pjeIntegrationId: '',
    pjeJurisdiction: '',
    pjeClass: '',
    pjeSecretId: inquiry.inquiry?.isConfidential ? 1 : 0,
    pjeSubjects: [],
    pjeProvince: '',
    pjeMainSubject: '',
    causeValue: 0,
    freeJustice: true,
    npus: [],
    npu: '',
    antecipationRequest: false,
    activePoles: [],
  };

  const [showPlace, setShowPlace] = useState(false);
  const [formState, setFormState] = useState({ ...initialValues });
  const [openModalSubtype, setOpenModalSubtype] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);

  const [pjeData, setPjeData] = useState({});

  const [loadingOrgans, setLoadingOrgans] = useState(false);
  const [inputSearchOrgans, setInputSearchOrgans] = useState('');
  const [pageOrgans, setPageOrgans] = useState(1);
  const [lastPageOrgans, setLastPageOrgans] = useState(1);
  const [organOptions, setOrganOptions] = useState([]);

  const [showPrazoPlace, setShowPrazoPlace] = useState(false);
  const [disabledCheckTransition, setDisabledCheckTransition] = useState(false);

  const [loadingVerifyApplyToAll, setLoadingVerifyApplyToAll] = useState(false);

  const [openModalApplyToAllError, setOpenModalApplyToAllError] = useState(false);
  const [openModalDocsEditFile, setOpenModalDocsEditFile] = useState(false);

  const [manualDocToEditId, setManualDocToEditId] = useState(null);

  const [canBeAppliedToAll, setCanBeAppliedToAll] = useState({
    canBeAppliedToAll: false,
    reason: '',
    reasonData: [],
  });

  const [isAutomaticMovementTask, setIsAutomaticMovementTask] = useState(false);

  const [ableToHasNotificationOfArchiving, setAbleToHasNotificationOfArchiving] = useState(false);

  const useStyles = makeStyles(theme => ({
    root: {
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    },
    containerModal: {},
    containerConfirm: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      color: '#787878',
      fontSize: 16,
      fontWeight: 'bold',
      paddingLeft: 5,
      paddingRight: 5,
      marginTop: 30,
      marginBottom: 50,
      '@media (max-width:690px)': {
        padding: 30,
      },
    },
    header: {
      width: '100%',
      backgroundColor: '#00597b',
      height: 50,
      color: '#ffffff',
      paddingLeft: 30,
      fontSize: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    label: {
      color: '#00597b',
      fontWeight: 'bold',
      fontSize: 15,
    },
    dadosCriminais: {
      color: '#00597b',
      fontWeight: 'bold',
      fontSize: 16,
    },
    containerDadosPessoais: {
      backgroundColor: '#ededed',
      marginTop: 5,
      marginBottom: 5,
      padding: 10,
      borderRadius: 25,
    },
    labelDadoaPessoais: {
      color: '#00597b',
      fontWeight: 'bold',
      fontSize: 12,
    },
    labelCrime: {
      fontWeight: 'bold',
      color: '#000',
      height: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#f8cf5f',
      width: '60%',
      '@media (max-width:960px)': {
        width: '100%',
      },
    },
    textArea: {
      width: '100%',
      maxWidth: '100%',
      padding: 5,
      resize: 'none',
    },
    containerBtn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    containerBtnArea: {
      padding: 10,
    },
    btnConfirm: {
      backgroundColor: '#00b1ac',
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
      borderRadius: 25,
      height: 50,
      width: 150,
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#00597b',
      },
      '@media (max-width:600px)': {
        width: '100%',
      },
    },
    input: {
      display: 'none',
    },
    inputMaterial: {
      width: '100%',
    },
    containerTable: {
      marginTop: title !== 'DESPACHO' && title !== 'Tarefas' && haveTask ? 30 : 5,
    },
    chip: {
      margin: 2,
      backgroundColor: '#00b1ac',
      color: '#ffffff',
    },
    cardCrime: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'start',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      minHeight: 40,
      overflowX: 'auto',
      float: 'left',
      '@media (max-width:600px)': {
        float: 'none',
      },
      marginBottom: 10,
    },
    dialogAction: {
      marginRight: theme.spacing(2),
    },
    containerBtnRight: {
      padding: theme.spacing(0.5),
    },
  }));

  const classes = useStyles();

  const isSubtypeEnablePjeIntegration = subtype => {
    return (
      subtype === 'Denúncia' ||
      listInfanciaEJuventude.some(s => s.name === subtype && s.name !== 'Despacho')
    );
  };

  const handleChangeProsecutor = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      prosecutor: newValue,
    }));
  };

  const handleChangeProsecutorOffice = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      prosecutorOffice: newValue,
    }));
  };

  const handleOpenModalEditDocs = () => {
    if (formState.subtype && formState.type) {
      return setOpenModalDocsEditFile(true);
    }
    toast.info('Selecione um tipo e subtipo para criar um novo documento');
  };

  const mapFilesProcess = (attachments, filesProcess) => {
    const movementFiles = [
      ...((row &&
        isSubtypeEnablePjeIntegration(row.subType?.name) &&
        row.requestDocuments?.map(f => ({ ...f, disabledReprocess: true, sendToPJE: true }))) ||
        []),
    ];
    const files = [...movementFiles, ...attachments];
    return _.uniqBy(
      files.map(file => {
        const fileProcess = filesProcess?.find(f => f.inquiryFile.id == file.id);
        return {
          ...file,
          fileProcess,
          disabledReprocess: file.disabledReprocess || false,
          sendToPJE: file.sendToPJE || fileProcess?.sendToPJE || false,
        };
      }),
      'id',
    );
  };

  const getInquiryPjeData = async () => {
    setLoadingPjeData(true);
    const response = await pjeIntegrationService.getPjeIntegrationService({
      inquiryId: inquiry.inquiry.id,
    });
    const pjeIntegrationData = response.data;
    if (pjeIntegrationData.pjeIntegration) {
      const pjeIntegration = pjeIntegrationData.pjeIntegration;
      setPjeData({
        pjeIntegrationId: pjeIntegration.id,
        pjeJurisdiction: pjeIntegration.jurisdiction,
        pjeClass: pjeIntegration.classe,
        pjeSecretId: pjeIntegration.pjeSecret.id,
        pjeSubjects: pjeIntegration.cnjSubjects
          .filter(subject => !subject.isMain)
          .map(sbj => sbj?.pjeSubject),
        pjeMainSubject: pjeIntegration.cnjSubjects.find(subject => subject.isMain)?.pjeSubject,
        pjeProvince: pjeIntegration.pjeProvince,
        causeValue: pjeIntegration.causeValue ? parseFloat(pjeIntegration.causeValue) : undefined,
        freeJustice: pjeIntegration.freeJustice,
        antecipationRequest: pjeIntegration.antecipationRequest,
        pjeAttachmentsInquiry: pjeIntegrationData.attachments,
        pjeFilesProcess: pjeIntegration.pjeFilesProcess,
        npu: pjeIntegration.npu,
        activePoles: pjeIntegration.activePoles,
        npus: pjeIntegration.pjeIntegrationNpuRelations,
      });
    }
    setPjeFileOptions(
      mapFilesProcess(
        pjeIntegrationData.attachments || [],
        pjeIntegrationData.pjeIntegration?.pjeFilesProcess || [],
      ),
    );
    setLoadingPjeData(false);
  };

  const getDelegacias = (input = '') => {
    setLoadingOrgans(true);

    setPageOrgans(1);

    let params = {
      page: 1,
      size: 20,
      search: input,
    };

    if (isMPDFT) {
      params.category = 'JUDICIARY';
    }

    organService
      .getDelegaciasService(params)
      .then(response => {
        setOrganOptions(response.data.data);
        setLastPageOrgans(response.data.lastPage);
      })
      .finally(() => setLoadingOrgans(false));
  };

  const loadMoreDelegaciasResults = () => {
    const nextPage = pageOrgans + 1;

    setPageOrgans(nextPage);

    setLoadingOrgans(true);

    let params = {
      page: nextPage,
      size: 20,
      search: inputSearchOrgans,
    };

    if (isMPDFT) {
      params.category = 'JUDICIARY';
    }

    organService
      .getDelegaciasService(params)
      .then(response => {
        setOrganOptions([...organOptions, ...response.data.data]);
        setLastPageOrgans(response.data.lastPage);
      })
      .finally(() => setLoadingOrgans(false));
  };

  const debouncedGetDelegacias = useCallback(
    debounce(newValue => getDelegacias(newValue), 500),
    [],
  );

  const updateValueDelegacias = newValue => {
    if (Boolean(!organOptions.find(delegacia => delegacia.name === newValue))) {
      debouncedGetDelegacias(newValue);
      setInputSearchOrgans(newValue);
    }
  };

  const handleScrollDelegacias = event => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (
      listboxNode.scrollHeight - position <= 1 &&
      pageOrgans < lastPageOrgans &&
      !loadingOrgans
    ) {
      loadMoreDelegaciasResults();
    }
  };

  const handleChangeJudgmentPlace = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      place: newValue,
    }));
  };

  const verifyApplyToAll = useCallback(
    currentApplyTotall => {
      setFormState(formState => {
        let applyToAll = currentApplyTotall;

        if (applyToAll) {
          applyToAllRequest();
        }

        return {
          ...formState,
          applyToAll,
        };
      });
    },
    [
      setFormState,
      setLoadingVerifyApplyToAll,
      movementTaskService.checkCanBeApplyToAllService,
      setCanBeAppliedToAll,
      setOpenModalApplyToAllError,
      setLoadingVerifyApplyToAll,
      inquiry.id,
      formState.subtype.name,
    ],
  );

  const handleChangeApplyToAll = event => {
    event.persist();

    verifyApplyToAll(event.target.checked);
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      [event.target.name]:
        event.target.type === 'checkbox' ? event.target.checked : event.target.value,
    }));
  };

  const handleForm = async (target, value) => {
    setFormState(formState => ({
      ...formState,
      [target]: value,
    }));
  };

  const handleChangeTargetDocumentStatus = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      subtype: {
        ...formState.subtype,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleOpenModalSubType = () => {
    setOpenModalSubtype(true);
  };

  const handleCloseModalSubType = () => {
    setOpenModalSubtype(false);
  };

  const handleClearFormState = () => {
    setFormState({ ...initialValues });
    setFileInBase64([]);
    setUploadObject([]);
    setShowPrazoPlace(false);
  };

  const handleCancel = () => {
    setConfirm(false);
    onClose();
    handleClearFormState();
    history.goBack();
  };

  const fetchTasks = () => {
    setLoadTask(true);
    setHaveTask(true);

    let params = {
      //type: title === 'DENÚNCIA' ? 'DENUNCIA' : title === 'DECLINAR' ? 'DECLINAR' : title === 'ARQUIVAR' ? 'ARQUIVAR' : title === 'DESPACHO' ? 'ENCAMINHAR_DILIGENCIAS' : '',
      inquiryInvestigatedId: inquiry.id,
    };

    if (title === 'DESPACHO') {
      params.isActive = true;
    }

    movementTaskService
      .getMovementTasksService(params)
      .then(response => {
        setTasks(response.data.data);
        if (inquiry.investigatedCertificates?.length > 0) {
          const hasVictimCertificate = inquiry.investigatedCertificates.some(
            certificate => certificate.type === 'Vítima',
          );
          const hasInvestigatedCertificate = inquiry.investigatedCertificates.some(
            certificate => certificate.type === 'Investigado',
          );
          const hasDelegateCertificate = inquiry.investigatedCertificates.some(
            certificate => certificate.type === 'Delegado',
          );
          setAbleToHasNotificationOfArchiving(
            hasVictimCertificate && hasInvestigatedCertificate && hasDelegateCertificate
              ? true
              : false,
          );
        }
        handleClearFormState();
        response.data.data.length > 0 ? setHaveTask(true) : setHaveTask(false);
        setDisabledBtn(false);
      })
      .finally(() => {
        setLoadTask(false);
        setDisabledBtn(false);
      });
  };

  const confirmAddOrUpdateTask = () => {
    const { type, subtype } = formState;

    let { justification } = formState;

    if (!justification) justification = '';

    if (title === 'DESPACHO' || title === 'Tarefas') {
      setDisabledBtn(true);

      if (row) {
        updateTask(
          justification,
          type,
          subtype,
          fileInBase64.filter(file => !file.ignore),
          row,
        );
        return;
      }

      addTask(
        inquiry.id,
        justification,
        type,
        subtype,
        fileInBase64.filter(file => !file.ignore),
      );
    } else if (title === 'PERSECUÇÃO') {
      fetchNextLevel(inquiry.id, 'PERSECUCAO', justification);
    } else if (title === 'ANALISE') {
      if (row) {
        updateTask();
        return;
      }
      fetchNextLevel(inquiry.id, 'ARQUIVAR', justification);
    }
  };

  const handleSendForm = e => {
    e.preventDefault();

    const {
      type,
      subtype,
      pjeJurisdiction,
      pjeClass,
      pjeSecretId,
      pjeMainSubject,
      pjeProvince,
      integrateWithPJE,
    } = formState;

    if (!type || type === '' || !subtype || subtype === '') {
      toast.warn('Defina uma Tarefa para prosseguir');
      return;
    }

    if (!subtype.targetDocumentStatus || subtype.targetDocumentStatus === '') {
      toast.warn('Informe o status do documento');
      return;
    }

    if (integrateWithPJE && isSubtypeEnablePjeIntegration(subtype.name)) {
      if (!pjeJurisdiction) {
        toast.warning('É necessário preencher o campo "Jurisdição"');
        return;
      }
      if (!pjeClass) {
        toast.warning('É necessário preencher o campo "Classe PJE"');
        return;
      }
      if (pjeSecretId === undefined || pjeSecretId === null) {
        toast.warning('É necessário preencher o campo "Nível de Sigilo"');
        return;
      }
      if (!pjeMainSubject) {
        toast.warning('É necessário preencher o campo "Assunto Principal"');
        return;
      }
      if (!pjeProvince) {
        toast.warning('É necessário preencher o campo "Competência"');
        return;
      }
      if (!fileInBase64.length) {
        toast.warning('Anexe pelo menos um arquivo para integrar a tarefa com o PJE.');
        return;
      }
    }

    if (!fileInBase64[0]) {
      setModalConfirm(true);
    } else {
      confirmAddOrUpdateTask();
    }
  };

  const handleRemoveTask = task => {
    movementTaskService
      .deleteMovementTasksService(task.id)
      .then(() => {
        fetchTasks();
        setRefreshTasks(true);
        handleClearFormState();
        refreshTypeInquiry();
        setIsComplaint(false);
        toast.success('Tarefa executada com sucesso!');
      })
      .finally(() => setDisabledBtn(false));
  };

  const updateTask = (justification = '', type, subType, documents, task) => {
    const {
      date,
      place,
      shouldMoveInquiry,
      applyToAll,
      prosecutor,
      prosecutorOffice,
      integrateWithPJE,
      pjeIntegrationId,
      causeValue,
      freeJustice,
      pjeJurisdiction,
      pjeClass,
      pjeSecretId,
      pjeSubjects,
      pjeProvince,
      pjeMainSubject,
      antecipationRequest,
      npu,
      npus,
      activePoles,
    } = formState;

    let params = {
      shouldMoveInquiry,
      type,
      subType: {
        name: subType.name,
        code: subType.code,
      },
      requestDocuments: documents.filter(doc => !doc.googleDocumentId),
      requestDocumentFromGoogle: documents.filter(doc => doc.googleDocumentId),
      targetDocumentStatus: subType.targetDocumentStatus,
      integrateWithPJE,
      pjeIntegrationId: pjeIntegrationId || undefined,
      causeValue,
      freeJustice,
      jurisdictionId: pjeJurisdiction?.id,
      pjeClassId: pjeClass?.id,
      pjeSecretId: pjeSecretId,
      cnjSubjectIds: pjeSubjects?.map(subject => subject?.id),
      pjeMainSubjectId: pjeMainSubject?.id,
      pjeProvinceId: pjeProvince?.id,
      antecipationRequest,
      inquiryFileToPjeProcess: enablePreRegisterPje ? pjeFileOptions : undefined,
      npus: npus,
      npu: `${npu}`.replace(/\D/g, ''),
      activePoles: activePoles?.map(pole => pole?.id),
    };

    if (justification !== '') params.justification = justification;

    if (showPrazoPlace) {
      if (!date) {
        toast.warn('Verifique o campo prazo');
        setDisabledBtn(false);
        return;
      }
      if (date && date !== '') {
        params.date = formatDateToISOString(date);
      }
    }

    if (place) {
      params.place = place;
    }

    if (applyToAll !== null) {
      params.applyToAll = applyToAll;
    }

    if (showPlace && !place && subType.name !== 'Mesmo ramo' && subType.name !== 'Outro ramo') {
      toast.warn('Verifique o campo Vara/local');
      setDisabledBtn(false);
      return;
    }

    if (!prosecutorOffice) {
      toast.warn('Verifique o campo promotoria');
      setDisabledBtn(false);
      return;
    } else {
      params.prosecutorOfficeId = prosecutorOffice.id;
    }

    if (!prosecutor) {
      toast.warn('Verifique o campo promotor');
      setDisabledBtn(false);
      return;
    } else {
      params.prosecutorId = prosecutor.id;
    }

    movementTaskService
      .updateMovementTasksService(task.id, params)
      .then(() => {
        toast.success('Tarefa executada com sucesso!');
        fetchTasks();
        getInquiryPjeData();
        setRefreshTasks(true);
        handleClearFormState();
        setIsComplaint(false);
        refreshTypeInquiry();
      })
      .finally(() => setDisabledBtn(false));
  };

  const addTask = (idInquiry, justification = 'proxima_fase', type, subType, documents) => {
    const {
      date,
      place,
      shouldMoveInquiry,
      applyToAll,
      prosecutor,
      prosecutorOffice,
      integrateWithPJE,
      pjeIntegrationId,
      causeValue,
      freeJustice,
      pjeJurisdiction,
      pjeClass,
      pjeSecretId,
      pjeSubjects,
      pjeProvince,
      pjeMainSubject,
      antecipationRequest,
      npu,
      npus,
      activePoles,
    } = formState;

    let params = {
      shouldMoveInquiry,
      type,
      subType: {
        name: subType.name,
        code: subType.code,
      },
      requestDocuments: documents.filter(doc => doc.base64 && !doc.googleDocumentId),
      requestDocumentsFiles: documents.filter(doc => !doc.base64 && !doc.googleDocumentId),
      requestDocumentFromGoogle: documents.filter(doc => doc.googleDocumentId),
      inquiryInvestigatedId: idInquiry,
      targetDocumentStatus: subType.targetDocumentStatus,
      integrateWithPJE,
      pjeIntegrationId: pjeIntegrationId,
      jurisdictionId: pjeJurisdiction.id,
      pjeClassId: pjeClass.id,
      pjeSecretId: pjeSecretId,
      cnjSubjectIds: pjeSubjects?.map(subject => subject?.id),
      pjeProvinceId: pjeProvince?.id,
      pjeMainSubjectId: pjeMainSubject?.id,
      causeValue,
      freeJustice,
      antecipationRequest,
      npus: npus,
      npu: `${npu}`.replace(/\D/g, ''),
      inquiryFileToPjeProcess: enablePreRegisterPje ? pjeFileOptions : undefined,
      activePoles: activePoles?.map(pole => pole?.id),
    };

    if (justification !== '') params.justification = justification;

    if (showPrazoPlace) {
      if (!date) {
        toast.warn('Verifique o campo prazo');
        setDisabledBtn(false);
        return;
      }
      if (date && date !== '') {
        params.date = formatDateToISOString(date);
      }
    }

    if (place) {
      params.place = place;
    }

    if (applyToAll !== null) {
      params.applyToAll = applyToAll;
    } else if (
      inquiry.inquiry?.investigated?.length > 1 &&
      !isAutomaticMovementTask &&
      !isMigration
    ) {
      params.applyToAll = false;
    }

    if (showPlace && !place && subType.name !== 'Mesmo ramo' && subType.name !== 'Outro ramo') {
      toast.warn('Verifique o campo Vara/local');
      setDisabledBtn(false);
      return;
    }

    if (!prosecutorOffice) {
      toast.warn('Verifique o campo promotoria');
      setDisabledBtn(false);
      return;
    } else {
      params.prosecutorOfficeId = prosecutorOffice.id;
    }

    if (!prosecutor) {
      toast.warn('Verifique o campo promotor');
      setDisabledBtn(false);
      return;
    } else {
      params.prosecutorId = prosecutor.id;
    }

    movementTaskService
      .registerMovementTasksService(params)
      .then(() => {
        toast.success('Tarefa criada com sucesso!');
        fetchTasks();
        getInquiryPjeData();
        setRefreshTasks(true);
        handleClearFormState();
        setIsComplaint(false);
        refreshTypeInquiry();
      })
      .finally(() => setDisabledBtn(false));
  };

  const fetchNextLevel = (idInquiry, step, justification = 'proxima_fase', subType, document) => {
    let params = {
      step,
      justification,
      subType,
      document,
      integrateWithPJE: formState.integrateWithPJE,
      jurisdictionId: formState.jurisdiction,
      pjeClassId: formState.pjeClass,
      pjeSecretId: formState.pjeSecretId,
      cnjSubjectIds: formState.pjeSubjects?.map(subject => subject.id),
      pjeProvinceId: formState.pjeProvince,
      pjeMainSubjectId: formState.pjeMainSubject.id,
      causeValue: formState.causeValue,
      freeJustice: formState.freeJustice,
      antecipationRequest: formState.antecipationRequest,
      npu: `${formState.npu}`.replace(/\D/g, '') || '',
      activePoles: formState.activePoles?.map(pole => pole?.id),
    };

    inquiryService
      .updateChangeStepService(idInquiry, params)
      .then(response => {
        if (response.data.step) {
          setConfirm(true);

          if (response.data.step === STEPS_INQUIRY.TRIAGEM) {
            setTextMsg('Inquérito enviado para a fase de Triagem Não Persecução');
          } else if (response.data.step === STEPS_INQUIRY.PESQUISA) {
            setTextMsg('Inquérito enviado para a fase de Pesquisa e Distribuição');
          } else if (response.data.step === STEPS_INQUIRY.ANALISE) {
            setTextMsg('Inquérito enviado para a fase de Análise');
          } else if (response.data.step === STEPS_INQUIRY.INTIMACAO) {
            setTextMsg('Inquérito enviado para a fase de Intimação');
          }

          toast.success('Fase alterada com sucesso!');
        } else {
          setConfirm(true);
          setTextMsg('Alto deu errado');
        }
      })
      .finally(() => setDisabledBtn(false));
  };

  const handleCloseModalListFilesNewInquiry = () => {
    setOpenModalListFileNewInquiry(false);
  };

  const handleCloseModalAddPjeFiles = () => {
    setOpenModalAddPjeFiles(false);
  };

  const handleOpenModalAddPjeFiles = () => {
    setOpenModalAddPjeFiles(true);
  };

  const handleOpenModalAddFile = () => {
    setOpenModalListFileNewInquiry(true);
  };

  const handleCancelEditTask = () => {
    setHaveTask(true);
    setRow(null);
    //handleClearFormState();
  };

  const handleAddTask = () => {
    handleClearFormState();
    setHaveTask(false);
    setRow(null);
  };

  const handleAddSubtype = (_event, subtype, type) => {
    const complaint = isSubtypeEnablePjeIntegration(subtype?.name);
    setIsComplaint(complaint);
    setFormState(formState => ({
      ...formState,
      subtype: subtype,
      name: subtype.name,
      type,
      ...(complaint && !_.isEmpty(pjeData) ? { ...pjeData, integrateWithPJE: true } : {}),
    }));
  };

  const verifySubType = subtype => {
    let message =
      'Este inquérito possui número de NPU e/ou Local de Destino? Atualize-os nos Dados da Justiça';
    switch (subtype) {
      case 'Manifestação ao Judiciário':
        toast.info(message);
        //setShowNpuPlace(true);
        //setNotShowInputs(false);
        setShowPlace(true);
        setShowPrazoPlace(false);
        break;
      case 'Manifestação ao Judiciário (Caso NF)':
        toast.info(message);
        //setShowNpuPlace(true);
        //setNotShowInputs(false);
        setShowPlace(true);
        setShowPrazoPlace(false);
        break;
      case 'Manifestação ao Judiciário (Homologação)':
        toast.info(message);
        //setShowNpuPlace(true);
        //setNotShowInputs(false);
        setShowPlace(true);
        setShowPrazoPlace(false);
        break;
      case 'Denúncia':
        toast.info(message);
        //setShowNpuPlace(true);
        //setNotShowInputs(false);
        setShowPlace(false);
        setShowPrazoPlace(false);
        break;
      case 'Inexistência de Crime':
        toast.info(message);
        //setShowNpuPlace(true);
        //setNotShowInputs(false);
        setShowPlace(true);
        setShowPrazoPlace(false);
        break;
      case 'Prescrição':
        toast.info(message);
        //setShowNpuPlace(true);
        //setNotShowInputs(false);
        setShowPlace(true);
        setShowPrazoPlace(false);
        break;
      case 'Decadência':
        toast.info(message);
        //setShowNpuPlace(true);
        //setNotShowInputs(false);
        setShowPlace(true);
        setShowPrazoPlace(false);
        break;
      case 'Ausência/insuf.Prova':
        toast.info(message);
        //setShowNpuPlace(true);
        //setNotShowInputs(false);
        setShowPlace(true);
        setShowPrazoPlace(false);
        break;
      case 'Desconhecimento do Autor':
        toast.info(message);
        //setShowNpuPlace(true);
        //setNotShowInputs(false);
        setShowPlace(true);
        setShowPrazoPlace(false);
        break;
      case 'Morte do agente':
        toast.info(message);
        //setShowNpuPlace(true);
        //setNotShowInputs(false);
        setShowPlace(true);
        setShowPrazoPlace(false);
        break;
      case 'Dilação de prazo (com prazo)':
        //setShowNpuPlace(false);
        //setNotShowInputs(false);
        setShowPlace(true);
        setShowPrazoPlace(true);
        break;
      case 'Ofício requisição (com prazo)':
      case 'Arquivamento de Flagrante Aguardando IP':
        //setShowNpuPlace(false);
        //setNotShowInputs(false);
        setShowPlace(true);
        setShowPrazoPlace(true);
        break;
      case 'Req. Diligência':
        //setShowNpuPlace(false);
        //setNotShowInputs(false);
        setShowPlace(true);
        setShowPrazoPlace(true);
        break;
      case 'Inst de IP (com prazo)':
        //setShowNpuPlace(false);
        //setNotShowInputs(false);
        setShowPlace(true);
        setShowPrazoPlace(true);
        break;
      case 'Mesmo ramo':
        //setShowNpuPlace(false);
        //setNotShowInputs(false);
        //setShowPrazoPlace(false);
        setShowPlace(true);
        break;
      case 'Outro ramo':
        //setShowNpuPlace(false);
        //setNotShowInputs(false);
        //setShowPrazoPlace(false);
        setShowPlace(true);
        break;
      case 'Despacho':
        //setShowNpuPlace(false);
        //setShowPrazoPlace(false);
        setShowPlace(false);
        //setNotShowInputs(true);
        break;
      case 'Iniciar cumprimento acordo':
        //setShowNpuPlace(false);
        //setShowPrazoPlace(false);
        setShowPlace(false);
        //setNotShowInputs(true);
        break;
      case 'Arq. Interno':
        //setShowNpuPlace(false);
        //setShowPrazoPlace(false);
        setShowPlace(false);
        //setNotShowInputs(true);
        break;
      case 'Arquivamento com Remessa por Cumprimento de Acordo':
        //setShowNpuPlace(true);
        //setNotShowInputs(false);
        !automaticMovements && toast.info(message);
        setShowPlace(!automaticMovements ? true : false);
        setShowPrazoPlace(false);
        break;
      case 'Cumulada com medida protetiva':
      case 'Advertência':
      case 'Liberdade Assistida':
      case 'Prestação de Serviço à Comunidade':
      case 'Reparação de Dano':
      case 'Simples':
      case 'Representação por Ato Infracional':
        setShowPlace(true);
        break;
      default:
        //setShowNpuPlace(false);
        setShowPrazoPlace(false);
        setShowPlace(false);
        //setNotShowInputs(true);
        break;
    }
  };

  const debouncedVerifySubtype = useCallback(
    debounce(subtype => verifySubType(subtype), 500),
    [],
  );

  useEffect(() => {
    if (showPlace) {
      debouncedGetDelegacias(inputSearchOrgans);
    }
  }, [showPlace]);

  const applyToAllRequest = useCallback(() => {
    setLoadingVerifyApplyToAll(true);
    movementTaskService
      .checkCanBeApplyToAllService(inquiry.id)
      .then(({ data }) => {
        setCanBeAppliedToAll(data);
        if (!data.canBeAppliedToAll) {
          setOpenModalApplyToAllError(true);
        }
      })
      .finally(() => setLoadingVerifyApplyToAll(false));
  }, [
    setLoadingVerifyApplyToAll,
    movementTaskService.checkCanBeApplyToAllService,
    setCanBeAppliedToAll,
    setOpenModalApplyToAllError,
    inquiry.id,
  ]);

  useEffect(() => {
    const { type } = formState;

    if (
      formState.applyToAll &&
      (['Mesmo ramo', 'Outro ramo', 'Req. Diligência'].includes(formState.subtype.name) ||
        (inquiry.movementTasks || []).reduce((acc, task) => acc || task.applyToAll, false))
    ) {
      setFormState(formState => ({ ...formState, applyToAll: true }));
      applyToAllRequest();
    } else if (
      (type === STEPS_INQUIRY.ENCAMINHAR_DILIGENCIAS || type === STEPS_INQUIRY.DECLINAR) &&
      formState.applyToAll
    ) {
      applyToAllRequest();
    }

    if (type !== STEPS_INQUIRY.ENCAMINHAR_DILIGENCIAS && type !== '') {
      setDisabledCheckTransition(true);
      setFormState(formState => ({ ...formState, shouldMoveInquiry: true }));
    } else {
      setDisabledCheckTransition(false);
      setFormState(formState => ({ ...formState, shouldMoveInquiry: false }));
    }
  }, [formState.type]);

  useEffect(() => {
    if (formState.subtype) {
      debouncedVerifySubtype(formState.subtype.name);
      if (['Mesmo ramo', 'Outro ramo', 'Req. Diligência'].includes(formState.subtype.name)) {
        verifyApplyToAll(true);
      }
    }
    if (automaticMovements?.length) {
      automaticTasks = automaticMovements.reduce((acc, [type, name]) => {
        acc.push({ type, name });
        return acc;
      }, []);
      const isAutomatic = automaticTasks.find(movement => {
        return movement.name.includes(formState.subtype.name) && movement.type === formState.type;
      });
      setIsAutomaticMovementTask(isAutomatic ? true : false);
    }
  }, [formState.subtype]);

  useEffect(() => {
    setFormState(formState => ({
      ...formState,
    }));
  }, [formState.justification]);

  useEffect(() => {
    if (open) {
      if (
        inquiry.step === STEPS_INQUIRY.ANALISE ||
        inquiry.step === STEPS_INQUIRY.TRAMITACAO_CONTROLE ||
        inquiry.step === STEPS_INQUIRY.MIGRACAO
      ) {
        fetchTasks();
      }
      if (enablePreRegisterPje) {
        getInquiryPjeData();
      }
    }
  }, [open]);

  useEffect(() => {
    if (row) {
      if (row.requestDocuments !== null) {
        const { requestDocuments } = row;
        setFileInBase64(requestDocuments);
        setUploadObject(requestDocuments);
      }
      if (row.applyToAll) {
        setCanBeAppliedToAll({
          canBeAppliedToAll: true,
          reason: '',
          reasonData: [],
        });
      }
      setIsComplaint(row?.subType.name === 'Denúncia');
      if (row.applyToAll) {
        setCanBeAppliedToAll({
          canBeAppliedToAll: true,
          reason: '',
          reasonData: [],
        });
      }
      setFormState(formState => ({
        ...formState,
        justification: row?.justification || '',
        type: row.type,
        name: row?.subType.name,
        place: row.place,
        shouldMoveInquiry: row.shouldMoveInquiry,
        date: row.date ? row.date.substring(0, 10) : '',
        applyToAll: row.applyToAll || null,
        subtype: {
          code: row?.subType.code,
          name: row?.subType.name,
          targetDocumentStatus: row?.targetDocumentStatus,
        },
        prosecutor: row?.prosecutor || '',
        prosecutorOffice: row?.prosecutorOffice || '',
        integrateWithPJE: row?.integrateWithPJE || false,
        ...(enablePreRegisterPje
          ? {
              ...pjeData,
              pjeAttachmentsInquiry: undefined,
              pjeFilesProcess: undefined,
            }
          : {}),
      }));
      setPjeFileOptions(
        mapFilesProcess(pjeData.pjeAttachmentsInquiry || [], pjeData.pjeFilesProcess || []),
      );
    }
  }, [row]);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Dialog
        fullScreen={fullScreen}
        onClose={(event, reason) => onCloseWithBackdropDialog(event, reason, onClose)}
        open={open}
        aria-labelledby="responsive-dialog-title"
        className={classes.root}
        maxWidth="md"
        fullWidth
        scroll="paper"
      >
        <HeaderModal colorIconTitle="#fff" backgroundColor={colors.navBar} onClose={onClose}>
          {title}
        </HeaderModal>
        <DialogContent dividers>
          {!confirm ? (
            <>
              {!isMigration && (
                <>
                  <Grid container spacing={0} className={classes.dadosCriminais}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Box style={{ width: '100%' }}>
                        <Typography className={classes.label}>Dados Criminais</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      {inquiry.inquiry.policeInquiryNumber}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      style={{ textAlign: 'end', paddingRight: 30 }}
                    >
                      {inquiry.inquiry.policeReportNumber}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className={classes.containerDadosPessoais}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography className={classes.label}>Dados Pessoais</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                      <Typography className={classes.labelDadoaPessoais}>
                        {inquiry.investigated?.name || 'não informado'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                      <Typography className={classes.labelDadoaPessoais}>
                        Nascimento:
                        {!inquiry.investigated?.birthDate ? (
                          ' Não informado'
                        ) : (
                          <Moment format="DD/MM/YYYY" local>
                            {inquiry.investigated.birthDate}
                          </Moment>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                      <Typography className={classes.labelDadoaPessoais}>
                        CPF: {inquiry.investigated?.taxpayerRegistry || 'Não informado'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                      <Typography className={classes.labelDadoaPessoais}>
                        RG: {inquiry.investigated?.rg?.code || 'Não informado'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                      <Typography className={classes.labelDadoaPessoais}>
                        Org. Emissor: {inquiry.investigated?.rg?.issuingBody || 'Não informado'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                      <Typography className={classes.labelDadoaPessoais}>
                        Telefone: {inquiry.investigated?.phone || 'Não informado'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography className={classes.labelDadoaPessoais}>
                        Endereço:
                        {!inquiry.investigated?.address ? (
                          ' Não informado'
                        ) : !inquiry.investigated?.address?.street &&
                          !inquiry.investigated?.address?.number &&
                          !inquiry.investigated?.address?.neighborhood &&
                          !inquiry.investigated?.address?.city &&
                          !inquiry.investigated?.address?.uf ? (
                          ' Não informado'
                        ) : (
                          <>
                            {inquiry.investigated?.address?.street &&
                              inquiry.investigated?.address?.street + ', '}
                            {inquiry.investigated?.address?.number &&
                              inquiry.investigated?.address?.number + ', '}
                            {inquiry.investigated?.address?.neighborhood &&
                              inquiry.investigated?.address?.neighborhood + ', '}
                            {inquiry.investigated?.address?.city &&
                              inquiry.investigated?.address?.city + '-'}
                            {inquiry.investigated?.address?.uf &&
                              inquiry.investigated?.address?.uf + ', '}
                          </>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Typography className={classes.labelDadoaPessoais}>
                        E-mail: {inquiry.investigated?.email || 'Não informado'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography className={classes.labelDadoaPessoais}>
                        Mãe: {inquiry.investigated?.motherName || 'Não informado'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Card className={classes.cardCrime}>
                        {inquiry.crimes.length > 0 ? (
                          inquiry.crimes.map((crime, index) => (
                            <Chip
                              key={index}
                              className={classes.chip}
                              size="small"
                              label={crime.taxonomy}
                            />
                          ))
                        ) : (
                          <Chip className={classes.chip} size="small" label="Não informado" />
                        )}
                      </Card>
                    </Grid>
                  </Grid>
                </>
              )}
              <form id="form-justification" onSubmit={handleSendForm}>
                {(loading || loadTask) && <LinearProgress />}
                {!loading && !loadTask && !haveTask && (
                  <Grid container spacing={1} className={classes.dadosCriminais}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}
                    >
                      {!isMigration && (
                        <div className={classes.containerBtnRight}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formState.shouldMoveInquiry}
                                onChange={handleChange}
                                name="shouldMoveInquiry"
                                disabled={disabledCheckTransition}
                              />
                            }
                            label="Deseja tramitar o inquérito?"
                            labelPlacement="start"
                          />
                        </div>
                      )}
                      <div className={classes.containerBtnRight}>
                        <Tooltip title="Adicionar arquivo" arrow placement="left">
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={
                              formState.integrateWithPJE &&
                              isSubtypeEnablePjeIntegration(formState.subtype?.name)
                                ? handleOpenModalAddPjeFiles
                                : handleOpenModalAddFile
                            }
                          >
                            <Badge badgeContent={fileInBase64.length || '0'} max={99} color="error">
                              <AttachFile />
                            </Badge>
                          </IconButton>
                        </Tooltip>
                      </div>
                      {connectWithAIService && loggedUser.permissions.aiAssistant.read && (
                        <div className={classes.containerBtnRight}>
                          <Tooltip title="Criar novo documento" arrow placement="left">
                            <IconButton
                              color="primary"
                              aria-label="edit inquiry file"
                              component="span"
                              onClick={() => {
                                setManualDocToEditId(null);
                                handleOpenModalEditDocs();
                              }}
                            >
                              <AutoAwesomeIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <InputConsensus
                        label="Subtipo *"
                        name="name"
                        variant="standard"
                        value={formState.name}
                        className={classes.inputMaterial}
                        autoComplete="off"
                        onClick={handleOpenModalSubType}
                      />
                    </Grid>

                    {formState.subtype.name && (
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <InputConsensus
                          select
                          label="Status Documento"
                          name="targetDocumentStatus"
                          variant="standard"
                          value={formState.subtype.targetDocumentStatus || ''}
                          className={classes.inputMaterial}
                          onChange={handleChangeTargetDocumentStatus}
                        >
                          {documentStatusList(formState.subtype).map(status => (
                            <MenuItem key={status.value} value={status.value}>
                              {status.name}
                            </MenuItem>
                          ))}
                        </InputConsensus>
                      </Grid>
                    )}

                    {showPrazoPlace && (
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <InputConsensus
                          label="Prazo"
                          name="date"
                          type="date"
                          variant="standard"
                          value={formState.date || ''}
                          InputLabelProps={{ shrink: true }}
                          className={classes.inputMaterial}
                          onChange={handleChange}
                          required
                        />
                      </Grid>
                    )}

                    {showPlace && (
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Autocomplete
                          value={formState.place}
                          onInputChange={(_, newInputValue) => updateValueDelegacias(newInputValue)}
                          getOptionSelected={(option, value) => option.name === value.name}
                          getOptionLabel={option => option.name}
                          filterOptions={x => x}
                          fullWidth
                          noOptionsText="Nada foi encontrado"
                          loadingText="Buscando..."
                          loading={loadingOrgans}
                          clearOnBlur={false}
                          options={organOptions}
                          onChange={handleChangeJudgmentPlace}
                          renderOption={option => <>{option.name}</>}
                          renderInput={params => (
                            <InputConsensus
                              {...params}
                              label="Vara/Local"
                              fullWidth
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {loadingOrgans ? (
                                      <CircularProgress color="primary" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                          ListboxProps={{
                            onScroll: handleScrollDelegacias,
                          }}
                        />
                      </Grid>
                    )}
                    {enablePreRegisterPje &&
                      isSubtypeEnablePjeIntegration(formState.subtype?.name) && (
                        <>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControlLabel
                              style={{
                                padding: '10px 4px'
                              }}
                              control={
                                <Switch
                                  checked={formState.integrateWithPJE}
                                  onChange={handleChange}
                                  name="integrateWithPJE"
                                  color="primary"
                                  size="small"
                                />
                              }
                              label={'Integrar automaticamente com o PJE'}
                            />
                          </Grid>
                          {formState.integrateWithPJE &&
                            isSubtypeEnablePjeIntegration(formState.subtype?.name) && (
                              <FormPreRegisterPJE
                                formState={formState}
                                setFormState={setFormState}
                                handleChange={handleForm}
                                isInAnalysis={true}
                                investigatedNPU={inquiry?.npu}
                                inquiryJudicialDistrictId={
                                  inquiry.inquiry?.prosecutorOfficeGroup?.judicialDistrict?.id
                                }
                              />
                            )}
                        </>
                      )}

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <ProsecutorOfficeAndProsecutorForm
                        prosecutor={formState.prosecutor}
                        prosecutorOffice={formState.prosecutorOffice}
                        onProsecutorChange={handleChangeProsecutor}
                        onProsecutorOfficeChange={handleChangeProsecutorOffice}
                        isProsecutor={isProsecutor}
                        prosecutorOfficeGroup={inquiry.inquiry.prosecutorOfficeGroup}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        label="Justificativa"
                        multiline
                        maxRows={3}
                        minRows={2}
                        name="justification"
                        value={formState.justification}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>

                    {inquiry.inquiry?.investigated?.length > 1 &&
                    !isAutomaticMovementTask &&
                    !isMigration ? (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box display="flex" justifyContent="flex-end">
                          <Alert severity="warning" variant="outlined">
                            <Box display="flex" flexDirection="column">
                              Deseja realizar a movimentação {formState.subtype.name} para os demais
                              investigados deste inquérito?
                              <Box display="flex" justifyContent="flex-end">
                                {['Mesmo ramo', 'Outro ramo', 'Req. Diligência'].includes(
                                  formState.subtype.name,
                                ) ? (
                                  <Tooltip
                                    title={`Uma tarefa do subtipo ${formState.subtype.name} deve ser realizada obrigatoriamente para todos os investigados do inquérito`}
                                    arrow
                                    placement="left"
                                  >
                                    <FormControlLabel
                                      style={{ marginRight: 0 }}
                                      control={
                                        <Switch
                                          checked={formState.applyToAll}
                                          name="applyToAll"
                                          color="primary"
                                          size="small"
                                          disabled
                                        />
                                      }
                                      label={formState.applyToAll ? 'Sim' : 'Não'}
                                    />
                                  </Tooltip>
                                ) : (
                                  <FormControlLabel
                                    style={{ marginRight: 0 }}
                                    control={
                                      <Switch
                                        checked={formState.applyToAll}
                                        onChange={handleChangeApplyToAll}
                                        name="applyToAll"
                                        color="primary"
                                        size="small"
                                        disabled={loadingVerifyApplyToAll || disabledBtn}
                                      />
                                    }
                                    label={formState.applyToAll ? 'Sim' : 'Não'}
                                  />
                                )}
                              </Box>
                            </Box>
                          </Alert>
                        </Box>
                      </Grid>
                    ) : null}
                  </Grid>
                )}
                {!loading && !loadTask && haveTask && (
                  <>
                    {(title === 'DESPACHO' || title === 'Tarefas') && (
                      <Box display="flex" justifyContent="flex-end" alignItems="center">
                        <Tooltip title="Adicionar tarefa" arrow placement="left">
                          <IconButton onClick={handleAddTask}>
                            <Add />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                    <TableModalChangeLevelForm
                      className={classes.containerTable}
                      setFormState={setFormState}
                      tasks={tasks}
                      setRow={setRow}
                      setFileInBase64={setFileInBase64}
                      setUploadObject={setUploadObject}
                      setHaveTask={setHaveTask}
                      handleRemoveTask={handleRemoveTask}
                    />
                  </>
                )}
              </form>
            </>
          ) : (
            <></>
          )}

          {confirm ? <div className={classes.containerConfirm}>{textMsg}</div> : ''}
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          {!confirm ? (
            <>
              {!haveTask && (
                <>
                  <ButtonConsensus
                    title="Cancelar"
                    backgroundColor="#fff"
                    hovercolor={colors.greySecundary}
                    color={colors.greyShadow}
                    onClick={handleCancelEditTask}
                  />
                  <ButtonConsensus
                    title={disabledBtn ? 'Enviando...' : 'confirmar'}
                    backgroundColor={colors.primary}
                    hovercolor={colors.primary}
                    disabled={
                      disabledBtn ||
                      loadingVerifyApplyToAll ||
                      (canBeAppliedToAll.canBeAppliedToAll === false && formState.applyToAll)
                    }
                    type="submit"
                    form="form-justification"
                  />
                </>
              )}
            </>
          ) : (
            <ButtonConsensus
              title="Voltar"
              backgroundColor="#fff"
              hovercolor={colors.greySecundary}
              color={colors.greyShadow}
              onClick={handleCancel}
            />
          )}
        </DialogActions>
      </Dialog>
      {modalConfirm && (
        <ModalConfirm
          title="Alerta"
          open={modalConfirm}
          handleClose={() => setModalConfirm(false)}
          onClick={confirmAddOrUpdateTask}
        >
          <Typography variant="h5">Você não anexou arquivos, deseja continuar?</Typography>
        </ModalConfirm>
      )}

      <ModalSubtype
        open={openModalSubtype}
        onClose={handleCloseModalSubType}
        onClick={handleAddSubtype}
        tasks={tasks}
        isInfraction={isInfraction}
        ableToHasNotificationOfArchiving={ableToHasNotificationOfArchiving}
      />

      <ModalAddFileChangeLevelForm
        open={openModalListFileNewInquiry}
        onClose={handleCloseModalListFilesNewInquiry}
        fileInBase64={fileInBase64}
        setFileInBase64={setFileInBase64}
        uploadObject={uploadObject}
        setUploadObject={setUploadObject}
        setManualDocToEditId={setManualDocToEditId}
        setOpenModalDocsEditFile={setOpenModalDocsEditFile}
      />

      {openModalAddPjeFiles && (
        <ModalAddPjeFile
          open={openModalAddPjeFiles}
          onClose={handleCloseModalAddPjeFiles}
          movementFiles={fileInBase64}
          setMovementFiles={setFileInBase64}
          pjeFileOptions={pjeFileOptions}
          setPjeFileOptions={setPjeFileOptions}
          disableFiles={loadingPjeData && !formState.integrateWithPJE}
          setManualDocToEditId={setManualDocToEditId}
          setOpenModalDocsEditFile={setOpenModalDocsEditFile}
        />
      )}

      {openModalApplyToAllError && (
        <ModalApplyToAllError
          open={openModalApplyToAllError}
          onClose={() => setOpenModalApplyToAllError(false)}
          canBeAppliedToAll={canBeAppliedToAll}
        />
      )}

      {openModalDocsEditFile && (
        <ModalDocsEdit
          policeInquiryNumber={inquiry.inquiry?.policeInquiryNumber}
          onClose={() => setOpenModalDocsEditFile(false)}
          open={openModalDocsEditFile}
          files={fileInBase64}
          setFiles={setFileInBase64}
          setUploadObject={setUploadObject}
          editDocumentId={manualDocToEditId}
          inquiry={inquiry}
          taskId={row?.id}
          type={formState.type}
          subtype={formState.subtype.name}
          prosecutorOffice={formState.prosecutorOffice}
          prosecutor={formState.prosecutor}
        />
      )}
    </div>
  );
};

export default ModalChangeLevelForm;
