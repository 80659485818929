import React from 'react';
import InputMask from 'react-input-mask';
import InputConsensus from 'components/InputConsensus';
import AddIcon from '@material-ui/icons/Add';
import {
  IconButton,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputLabel,
  Grid,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import { npuMask } from 'masks/masks';
import { NpuTypesTreated } from 'constants/npu-types';
import { FormControl, MenuItem, Select } from '@mui/material';

const useStyles = makeStyles({
  root: {
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid #000',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#000',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: '2px solid #000',
    },
    '& .MuiInput-input': {
      color: '#000',
      fontSize: 14,
    },
    '& label.Mui-focused': {
      color: '#000',
    },
    '& label.MuiFormLabel-root': {
      color: '#000',
      fontSize: 12,
    },
    '& .Mui-error': {
      fontSize: 10,
      color: 'red',
    },
  },
});

const PjeNpus = props => {
  const { npuList, handleRemoveNpu, handleAddNpu, handleChange, formState, changeNpuType } = props;

  const classes = useStyles();

  return (
    <Box>
      <>
        <Box
          margin={1}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          component="form"
          onSubmit={e => {
            e.preventDefault();
            handleAddNpu();
          }}
        >
          <InputMask
            onChange={event => {
              handleChange('npu', event.target.value);
            }}
            value={formState.npu || ''}
            mask={npuMask}
            maskChar={null}
          >
            {inputProps => (
              <InputConsensus
                {...inputProps}
                label="Número de vínculo"
                name="npu"
                type="npu"
                variant="standard"
                autoComplete="no"
                fullWidth
              />
            )}
          </InputMask>
          <IconButton color="primary" aria-label="add npu" onClick={() => handleAddNpu()}>
            <AddIcon style={{ cursor: 'pointer' }} />
          </IconButton>
        </Box>
        {npuList?.length > 0 && (
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">NPU</TableCell>
                  <TableCell align="center">Tipo de vínculo</TableCell>
                  <TableCell align="center">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {npuList.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row" align="center">
                      {item.npu}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormControl className={classes.root}>
                          <Select
                            value={item.relationType}
                            onChange={event => changeNpuType(event, index)}
                            name='relationType'
                          >
                            {NpuTypesTreated.map((relationType, index) => (
                              <MenuItem
                                value={relationType.value}
                                key={index}
                                style={{ fontSize: 12 }}
                              >
                                {relationType.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => handleRemoveNpu(item)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </>
    </Box>
  );
};

export default PjeNpus;
