import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid, TextareaAutosize, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import agreementService from 'services/agreement/agreementService';
import InputConsensus from 'components/InputConsensus';
import { Autocomplete, Skeleton } from '@material-ui/lab';
import associationService from 'services/association/associationService';
import { PARTNER_SITUATIONS } from 'constants/partner-situations';
import { colors } from 'utils/colorsSteps';
import ButtonConsensus from 'components/ButtonConsensus';
import LazyAutocomplete from 'components/LazyAutocomplete';
import projectService from 'services/project/projectService';
import { AutoCompleteConfig } from 'constants/autocomplete';

const useStyles = makeStyles(theme => ({
  containerForm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  containerParcels: {
    padding: theme.spacing(2),
    backgroundColor: '#ececec',
    borderRadius: '0.7rem',
    margin: theme.spacing(0, 0.8, 1, 0.8),
  },
}));

const GeneralPenalties = props => {
  const {
    setDisabledActionsPenaltyTable = {},
    selectedIndex = null,
    setSelectedIndex = {},
    generalPenalties,
    setGeneralPenalties,
    institutionType,
    institutionSelected,
    judicialDistrictId,
  } = props;

  const classes = useStyles();
  const initialValues = {
    description: '',
    type: '',
    association: null,
    project: null,
  };

  const [row, setRow] = useState(null);
  const [formState, setFormState] = useState({
    values: { ...initialValues },
    touched: {},
    errors: {},
  });

  const [projectOptions, setProjectOptions] = useState([]);

  const { isMPDFT, enablePenaltyTypes } = useCustomerConfig();

  const handleChangeType = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        type: newValue,
      },
    }));
  };

  const fetchTypes = async () => {
    const result = await agreementService.getPenaltyTypes();
    return {
      data: result.data.generalTypes,
    };
  };

  const handleChangeCompany = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        association: newValue,
        project: null,
      },
    }));
    setProjectOptions([]);
  };

  const fetchAssociations = async (search, page) => {
    const params = {
      forward: false,
      includeAssociationsWithNullSituation: true,
      judicialDistrictId: judicialDistrictId,
      search,
      page,
      size: AutoCompleteConfig.PAGINATION_SIZE,
    };

    if (isMPDFT) {
      delete params.includeAssociationsWithNullSituation;
      params.situation = PARTNER_SITUATIONS.ACCREDITED;
      delete params.judicialDistrictId;
    }

    const result = await associationService.getAssociationsService(params);

    return {
      data: result.data.data,
      lastPage: result.data.lastPage,
    };
  };

  const fetchProjects = useCallback(
    async (search, page) => {
      if (formState.values.association?.id) {
        const params = {
          associationId: formState.values.association?.id,
          onlyActiveProjects: true,
          search,
          page,
          size: AutoCompleteConfig.PAGINATION_SIZE,
        };

        const result = await projectService.getProjectsService(params);
        setProjectOptions(result.data.data);
      }
    },
    [formState.values.association?.id],
  );

  const handleChangeProject = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        project: newValue,
      },
    }));
  };

  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      values:
        selectedIndex !== null
          ? { ...generalPenalties[selectedIndex.index] }
          : { ...initialValues },
    }));
  }, [selectedIndex]);

  useEffect(() => {
    if (row) {
      setFormState(formState => ({
        ...formState,
        values: { ...row },
      }));
    }
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
      },
    }));
  }, [row, institutionSelected]);

  const handleSubmit = event => {
    event.preventDefault();

    const { description, type } = formState.values;

    if (!description || (!type && enablePenaltyTypes)) {
      return toast.warn(`Favor preencher o campo de ${isMPDFT ? 'medida' : 'penalidade'}`);
    }

    if (selectedIndex !== null) {
      const newArray = [...generalPenalties];

      newArray[selectedIndex.index] = {
        penalty: `Outras ${isMPDFT ? 'Medidas' : 'Penalidades'}`,
        ...formState.values,
      };

      setGeneralPenalties(newArray);
    } else {
      setGeneralPenalties([
        ...generalPenalties,
        {
          penalty: `Outras ${isMPDFT ? 'Medidas' : 'Penalidades'}`,
          ...formState.values,
        },
      ]);
    }

    handleClearFormState();
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleClearFormState = () => {
    setRow(null);
    if (selectedIndex !== null) {
      setDisabledActionsPenaltyTable(prevState => ({
        ...prevState,
        [selectedIndex.penaltiesIndex]: { ...prevState[selectedIndex.penaltiesIndex], edit: false },
      }));
      setSelectedIndex(null);
    }
    setFormState(formState => ({
      ...formState,
      values: { ...initialValues },
    }));
    setProjectOptions([]);
  };

  const handleToRead = () => {
    handleClearFormState();
  };

  return (
    <Box className={classes.container}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} className={classes.containerForm}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {enablePenaltyTypes && (
              <LazyAutocomplete
                autoHighlight
                onChange={handleChangeType}
                value={formState.values.type}
                renderOption={option => <React.Fragment>{option || ''}</React.Fragment>}
                enablePagination
                fetchOptions={fetchTypes}
                renderInput={params => (
                  <InputConsensus
                    {...params}
                    label="Tipo*"
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.loading ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            )}
          </Grid>
          {institutionType === 'final' && (
            <>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <LazyAutocomplete
                  autoHighlight
                  onChange={handleChangeCompany}
                  value={formState.values.association}
                  getOptionLabel={option => option.name || ''}
                  renderOption={option => <React.Fragment>{option.name || ''}</React.Fragment>}
                  enablePagination
                  fetchOptions={fetchAssociations}
                  renderInput={params => (
                    <InputConsensus
                      {...params}
                      label="Instituição*"
                      variant="standard"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {params.InputProps.loading ? (
                              <CircularProgress color="primary" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                {institutionType === 'final' && formState.values.association?.id && (
                  <LazyAutocomplete
                    value={formState.values.project}
                    getOptionLabel={option => option.name}
                    onChange={handleChangeProject}
                    renderOption={option => <>{option.name}</>}
                    fetchOptions={fetchProjects}
                    options={projectOptions}
                    enablePagination
                    renderInput={params => (
                      <InputConsensus
                        {...params}
                        name="project"
                        label="Projeto"
                        variant="standard"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.loading ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
            </>
          )}
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography align="left" variant="h5" className={classes.form}>
              {isMPDFT ? 'Detalhar Medida' : 'Detalhar Penalidade'}:
            </Typography>
            <TextareaAutosize
              style={{ width: '100%', padding: 5, resize: 'none' }}
              maxRows={4}
              minRows={4}
              aria-label="maximum height"
              placeholder={`Informar qual será a ${isMPDFT ? 'medida' : 'penalidade'}`}
              onChange={handleChange}
              name="description"
              value={formState.values.description || ''}
            />
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <ButtonConsensus
                title={`${selectedIndex !== null ? 'Alterar' : 'Incluir'} ${
                  isMPDFT ? 'Medida' : 'Penalidade'
                }`}
                backgroundColor={colors.primary}
                hovercolor={colors.primary}
                type="submit"
                size="medium"
              />
              <ButtonConsensus
                title={`${selectedIndex !== null ? 'Cancelar' : 'Limpar'}`}
                backgroundColor={colors.primary}
                hovercolor={colors.primary}
                onClick={() => handleToRead()}
                size="medium"
                style={{ marginLeft: '-6px' }}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default GeneralPenalties;
