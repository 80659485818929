/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Grid,
  IconButton,
  Checkbox,
  TextareaAutosize,
  MenuItem,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import validate from 'validate.js';
import Tooltip from '@material-ui/core/Tooltip';
import ModalAddFile from './components/ModalAddFile';
import Moment from 'react-moment';
import { setFactInquiryReducer, setStatusInquiryReducer } from '../../actions';
import { InputConsensus } from 'components';
import { listUf } from 'utils/citysPE';
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import { toast } from 'react-toastify';
import pmService from 'services/PM/pmService';
import inquiryService from 'services/inquiries/inquiryService';
import debounce from 'lodash.debounce';
import { useUserTypes } from 'hooks/useUserTypes';
import { KeyboardDatePicker } from 'components';
import judicialDistrictsService from 'services/judicial-district/judicialDistrictsService';
import PmService from 'services/PM/pmService';
import LazyAutocomplete from '../LazyAutocomplete';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
  },
  titulo: {
    display: 'flex',
    alignItems: 'center',
    color: '#00597b',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 20,
    fontFamily: 'roboto-regular',
  },
  containerLabelInputCrime: {
    padding: 5,
  },
  containerLabelInput: {
    padding: 5,
  },
  selectForm: {
    height: 25,
    outline: 0,
    width: '100%',
    border: 0,
    borderBottom: `1px solid #000`,
    backgroundColor: '#ededed',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  },
  form: {
    heigth: '100%',
    width: '100%',
  },
  labelForm: {
    display: 'flex',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    fontSize: 11,
  },
  labelFormCrime: {
    display: 'flex',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    fontSize: 11,
    color: '#00597b',
  },
  inputForm: {
    display: 'block',
    height: 25,
    outline: 0,
    padding: 5,
    width: '100%',
    border: 0,
    borderBottom: `1px solid #000`,
    backgroundColor: 'transparent',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  },
  inputFormCrime: {
    display: 'block',
    height: 20,
    outline: 0,
    width: '100%',
    border: 0,
    backgroundColor: 'transparent',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    fontWeight: 'bold',
    color: '#00597b',
    textAlign: 'left',
  },
  inputFormCrimeEdit: {
    display: 'block',
    height: 20,
    outline: 0,
    width: '100%',
    border: 0,
    backgroundColor: 'transparent',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    fontWeight: 'bold',
    color: '#00597b',
    borderBottom: '1px solid #000',
  },
  textArea: {
    marginTop: 5,
    maxWidth: '100%',
    minWidth: '100%',
    width: '100%',
    padding: 5,
    outline: 0,
    border: `1px solid #000`,
    backgroundColor: 'transparent',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    borderRadius: 5,
    resize: 'none',
  },
  textAreaNoEdit: {
    textAlign: 'justify',
    color: '#00597b',
    maxWidth: '100%',
    minWidth: '100%',
    fontWeight: 'bold',
    width: '100%',
    padding: theme.spacing(0.5, 0.5, 0.5, 0),
    backgroundColor: 'transparent',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  },
  inputMaterial: {
    width: '100%',
  },
  containerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  titleLeft: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
  },
  titleRight: {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

const FormFactInquiry = props => {
  const {
    inquiryId,
    editFact,
    setEditFact,
    clearFormFac,
    setClearFormFac,
    fact = undefined,
    status = null,
    otherStep,
    write,
    handleOpenSnackbar,
    refreshPage,
    canEdit = false,
  } = props;

  const { isPM } = useUserTypes();

  const classes = useStyles();
  const dispatch = useDispatch();

  const factInquiryReducer = useSelector(state => state.factInquiry);

  const [key, setKey] = useState(false);
  const [judicialDistrictObject, setJudicialDistrictObject] = useState(null);
  const [inquiryStatus, setInquiryStatus] = useState(status || '');
  const [inquiryStatusTextInput, setInquiryStatusTextInput] = useState(status || '');

  const initialState = {
    date: '',
    time: '',
    shift: '',
    uf: 'PE',
    judicialDistrict: '',
    locality: '',
    isFlagrant: false,
    report: '',
  };

  const [formState, setFormState] = useState({
    fact: fact
      ? {
        date: fact.date,
        time: fact.time,
        shift: fact.shift,
        uf: fact.uf,
        judicialDistrict: fact.judicialDistrict,
        locality: fact.locality,
        isFlagrant: fact.isFlagrant,
        report: fact.report,
      }
      : { ...initialState },
  });

  useEffect(() => {
    if (editFact || !fact) {
      setFormState(formState => ({
        ...formState,
        fact: {
          ...formState.fact,
          judicialDistrict: judicialDistrictObject ? judicialDistrictObject.name : '',
        },
      }));
    }
  }, [judicialDistrictObject]);

  const [openModalAddFile, setOpenModalAddFile] = useState(false);

  const fetchComarcas = async (search, page) => {
    const params = {
      uf: formState.fact.uf,
      search,
      page,
    }

    const result = isPM
      ? await pmService.getJudicialDistrictsPmService(params)
      : await judicialDistrictsService.getJudicialDistrictsService(params);

    if (fact) {
      setJudicialDistrictObject(
        result.data.data.find(
          comarca => comarca?.name?.toLowerCase() === fact?.judicialDistrict?.toLowerCase(),
        ) || null,
      );
    }

    return {
      data: result.data.data,
      lastPage: result.data.lastPage,
    }
  }

  useEffect(() => {
    const errors = validate(formState.fact);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));

    dispatch(setFactInquiryReducer(formState.fact));
  }, [formState.fact]);

  useEffect(() => {
    if (clearFormFac) {
      setFormState(_formState => ({
        fact: { ...initialState },
      }));
      setJudicialDistrictObject(null);
      dispatch(setStatusInquiryReducer(''));
      setInquiryStatus('');
      setInquiryStatusTextInput('');
      setClearFormFac(false);
    }
  }, [clearFormFac]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      fact: {
        ...formState.fact,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
    }));
  };

  const listShift = [
    {
      name: 'Manhã',
      value: 'MANHÃ',
    },
    {
      name: 'Tarde',
      value: 'TARDE',
    },
    {
      name: 'Noite',
      value: 'NOITE',
    },
  ];

  const handleCloseModalAddFile = () => {
    setOpenModalAddFile(false);
  };

  const handleChangeJudicialDistrict = (event, newValue) => {
    setJudicialDistrictObject(newValue);
    setKey(!key);
  };

  const handleEditFactToFalse = () => {
    setFormState(formState => ({
      ...formState,
      fact: fact
        ? {
          date: fact.date,
          time: fact.time,
          shift: fact.shift,
          uf: fact.uf,
          judicialDistrict: fact.judicialDistrict,
          locality: fact.locality,
          isFlagrant: fact.isFlagrant,
          report: fact.report,
        }
        : { ...initialState },
    }));
    setInquiryStatus(status || '');
    setInquiryStatusTextInput(status || '');
    setEditFact(false);
  };

  const handleEditFactToTrue = () => {
    setEditFact(true);
  };

  const handleUpdateFacto = () => {
    if (formState.fact.judicialDistrict === '' || !formState.fact.judicialDistrict) {
      toast.warn('Selecione uma Comarca');
      return;
    }

    let params = {
      fact: factInquiryReducer,
      status: inquiryStatus,
    };

    if (isPM) {
      PmService.updateInquiryService(inquiryId, params).then(() => {
        toast.success('Dados alterados com sucesso!');
        setEditFact(false);
        refreshPage();
      });
    } else {
      inquiryService.updateInquiryService(inquiryId, params).then(() => {
        toast.success('Dados alterados com sucesso');
        setEditFact(false);
        refreshPage();
      });
    }
  };

  const debouncedChangeInquiryStatus = useCallback(
    debounce(newValue => setInquiryStatus(newValue), 500),
    [],
  );

  const handleChangeInquiryStatus = event => {
    event.persist();
    setInquiryStatusTextInput(event.target.value);
  };

  useEffect(() => {
    debouncedChangeInquiryStatus(inquiryStatusTextInput);
  }, [inquiryStatusTextInput]);

  useEffect(() => {
    dispatch(setStatusInquiryReducer(inquiryStatus));
  }, [inquiryStatus]);

  return (
    <Box className={classes.root}>
      <Box className={classes.containerTitle}>
        <Box className={classes.titleLeft}>
          <Typography className={classes.titulo}>Fato</Typography>
        </Box>
        <Box className={classes.titleRight}>
          {!editFact && fact && ((!otherStep && !isPM) || canEdit) ? (
            <Tooltip title="Alterar Fato" arrow placement="top">
              <IconButton
                className={classes.buttonEditar}
                size="small"
                onClick={() => (!write ? handleOpenSnackbar() : handleEditFactToTrue())}
              >
                <CreateIcon style={{ color: '#00b1ac' }} />
              </IconButton>
            </Tooltip>
          ) : (
            editFact && (
              <>
                <Box style={{ marginRight: 10 }}>
                  <Tooltip title="Salvar alterações" arrow placement="top">
                    <IconButton
                      className={classes.buttonEditar}
                      size="small"
                      onClick={handleUpdateFacto}
                      form="my-form-edit"
                      type="submit"
                    >
                      <SaveIcon style={{ color: '#1976d2' }} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box>
                  <Tooltip title="Cancelar" arrow placement="top">
                    <IconButton
                      className={classes.buttonEditar}
                      size="small"
                      onClick={handleEditFactToFalse}
                    >
                      <ClearIcon style={{ color: '#e1296a' }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </>
            )
          )}
        </Box>
      </Box>
      {editFact || !fact ? (
        <>
          <form className={classes.form}>
            <Grid container spacing={1}>
              <Grid item xs={7} sm={3}>
                <div className={classes.containerLabelInput}>
                  <KeyboardDatePicker
                    className={classes.inputMaterial}
                    variant="inline"
                    name="date"
                    maxDate={new Date()}
                    label="Data *"
                    value={formState.fact.date || null}
                    onChange={newDate =>
                      setFormState(formState => ({
                        ...formState,
                        fact: {
                          ...formState.fact,
                          date: newDate,
                        },
                      }))
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={5} sm={3}>
                <div className={classes.containerLabelInput}>
                  <InputConsensus
                    id="time"
                    label="Hora"
                    name="time"
                    type="time"
                    onChange={handleChange}
                    value={formState.fact.time || ''}
                    className={classes.inputMaterial}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={4} sm={2}>
                <div className={classes.containerLabelInput}>
                  <InputConsensus
                    className={classes.inputMaterial}
                    id="standard-select-currency-uf-turno"
                    select
                    label="Turno"
                    value={formState.fact.shift || ''}
                    onChange={handleChange}
                    name="shift"
                  >
                    {listShift.map((v, i) => (
                      <MenuItem key={i} value={v.value}>
                        {v.name}
                      </MenuItem>
                    ))}
                  </InputConsensus>
                </div>
              </Grid>
              <Grid item xs={8} sm={4}>
                <div className={classes.containerLabelInput}>
                  <InputConsensus
                    className={classes.inputMaterial}
                    id="custom-css-standard-input-locality"
                    label="Local"
                    value={formState.fact.locality || ''}
                    onChange={handleChange}
                    name="locality"
                    type="text"
                  />
                </div>
              </Grid>
              <Grid item xs={3} sm={2}>
                <div className={classes.containerLabelInput}>
                  <InputConsensus
                    className={classes.inputMaterial}
                    id="standard-select-currency-uf-address"
                    select
                    label="UF"
                    name="uf"
                    value={formState.fact.uf || ''}
                    onChange={handleChange}
                  >
                    {listUf().map((v, i) => (
                      <MenuItem key={i} value={v.value}>
                        {v.name}
                      </MenuItem>
                    ))}
                  </InputConsensus>
                </div>
              </Grid>

              <Grid item xs={7} sm={4}>
                <div className={classes.containerLabelInput}>
                  <LazyAutocomplete
                    value={judicialDistrictObject || ''}
                    onChange={handleChangeJudicialDistrict}
                    getOptionLabel={option => option.name}
                    renderOption={option => <>{option.name}</>}
                    enablePagination
                    fetchOptions={fetchComarcas}
                    className={classes.inputMaterial}
                    fullWidth
                    autoHighlight
                    key={key}
                    renderInput={params => (
                      <InputConsensus
                        {...params}
                        className={classes.inputMaterial}
                        name="judicialDistrict"
                        label='Comarca'
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </div>
              </Grid>

              <Grid item xs={2} sm={1} style={{ justifyContent: 'start', display: 'flex' }}>
                <div className={classes.containerLabelInput}>
                  <label className={classes.labelForm}>Flagrante</label>
                  <Checkbox
                    checked={formState.fact.isFlagrant}
                    name="isFlagrant"
                    size="small"
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <div className={classes.containerLabelInput}>
                  <label className={classes.labelForm}>Observação</label>
                  <TextareaAutosize
                    className={classes.textArea}
                    minRows={2}
                    maxRows={2}
                    aria-label="maximum height"
                    required
                    name="report"
                    autoComplete="no"
                    onChange={handleChangeInquiryStatus}
                    value={inquiryStatusTextInput || ''}
                  ></TextareaAutosize>
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <div className={classes.containerLabelInput}>
                  <label className={classes.labelForm}>Relato</label>
                  <TextareaAutosize
                    className={classes.textArea}
                    minRows={3}
                    maxRows={4}
                    aria-label="maximum height"
                    required
                    name="report"
                    autoComplete="no"
                    onChange={handleChange}
                    value={formState.fact.report || ''}
                  ></TextareaAutosize>
                </div>
              </Grid>
            </Grid>
          </form>
        </>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={7} sm={3}>
            <div className={classes.containerLabelInput}>
              <label className={classes.labelFormCrime}>Data</label>
              <Typography className={classes.inputFormCrime}>
                <Moment format="DD/MM/YYYY" local>
                  {formState.fact.date}
                </Moment>
              </Typography>
            </div>
          </Grid>
          <Grid item xs={5} sm={3}>
            <div className={classes.containerLabelInput}>
              <label className={classes.labelFormCrime}>Hora</label>
              <Typography className={classes.inputFormCrime}>
                {formState.fact.time || 'Não informado'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4} sm={2}>
            <div className={classes.containerLabelInput}>
              <label className={classes.labelFormCrime}>Turno</label>
              <Typography className={classes.inputFormCrime}>
                {formState.fact.shift || 'Não informado'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={8} sm={4}>
            <div className={classes.containerLabelInput}>
              <label className={classes.labelFormCrime}>Local</label>
              <Typography className={classes.inputFormCrime}>
                {formState.fact.locality || 'Não informado'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={7} sm={4}>
            <div className={classes.containerLabelInput}>
              <label className={classes.labelFormCrime}>Comarca</label>
              <Typography className={classes.inputFormCrime}>
                {formState.fact.judicialDistrict || 'Não informado'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={3} sm={2}>
            <div className={classes.containerLabelInput}>
              <label className={classes.labelFormCrime}>UF</label>
              <Typography className={classes.inputFormCrime}>
                {formState.fact.uf || 'Não informado'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={2} sm={1} style={{ justifyContent: 'start', display: 'flex' }}>
            <div className={classes.containerLabelInput}>
              <label className={classes.labelFormCrime}>Flagrante</label>
              <Typography className={classes.inputFormCrime}>
                {formState.fact.isFlagrant ? 'Sim' : 'Não'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div className={classes.containerLabelInput}>
              <label className={classes.labelFormCrime}>Observação</label>
              <Typography className={classes.textAreaNoEdit}>
                {status || 'Não informado'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} style={{ marginBottom: 10 }}>
            <div className={classes.containerLabelInput}>
              <label className={classes.labelFormCrime}>Relato</label>
              <Typography className={classes.textAreaNoEdit}>
                {formState.fact.report || 'Não informado'}
              </Typography>
            </div>
          </Grid>
        </Grid>
      )}
      <ModalAddFile open={openModalAddFile} onClose={handleCloseModalAddFile} />
    </Box>
  );
};

export default FormFactInquiry;
