/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  Dialog,
  Typography,
  Grid,
  CircularProgress,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  ListItemIcon,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListIcon from '@material-ui/icons/List';
import { HeaderModal, ModalConfirm, TableFiles } from 'components';
import { useUserTypes } from 'hooks/useUserTypes';
import pmService from 'services/PM/pmService';
import { toast } from 'react-toastify';
import { USER_TYPE } from 'constants/inquirys/userType';
import agreementService from 'services/agreement/agreementService';
import inquiryService from 'services/inquiries/inquiryService';
import movementTaskService from 'services/movement-task/movementTaskService';
import associationService from 'services/association/associationService';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import investigatedCertificateService from 'services/investigated-certificates/investigatedCertificateService';

const useStyles = makeStyles(theme => ({
  root: {},
  modal: {
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  },
  containerModal: {
    minHeight: 250,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerList: {
    minHeight: 250,
  },
  textTitle: {
    color: '#ffffff',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#ffffff',
  },
  dropzone: {
    height: '100%',
    width: '100%',
    border: '1px dashed #292929',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 10,
    cursor: 'pointer',
  },
  msgBody: {
    fontSize: 20,
    color: '#00597b',
  },
  circularProgress: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    zIndex: 5,
    height: '100%',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const ModalListFIles = props => {
  const { isPM, userType } = useUserTypes();

  const { enableHearingForJuvenileOffenders } = useCustomerConfig();

  const {
    inquiryInvestigatedId,
    open,
    onClose,
    agreement,
    viewDeleteAudienceButton,
    enableDeleteAudienceButton,
    className,
    investigated,
    onlyView,
    step,
    ...rest
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const inquiryIdReducer = useSelector(state => state.inquiry.inquiryId);

  const [files, setFiles] = useState([]);
  const [filesAgreement, setFilesAgreement] = useState([]);
  const [showFiles, setShowFiles] = useState(false);

  const [filesAgreementCompliance, setFilesAgreementCompliance] = useState([]);
  const [tasksFiles, setTasksFiles] = useState([]);

  const [openFiles, setOpenFiles] = useState(false);
  const [openFilesAgreement, setOpenFilesAgreement] = useState(false);
  const [openFilesHearing, setOpenFilesHearing] = useState(false);
  const [openFilesAgreementCompliance, setOpenFilesAgreementCompliance] = useState(false);
  const [openTasksFiles, setOpenTasksFiles] = useState(false);

  const [hearingsFiles, setHearingsFiles] = useState([]);
  const [openModalConfirmDelete, setOpenModalConfirmDelete] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [fileType, setFileType] = useState(null);

  const [superCertificateFiles, setSuperCertificateFiles] = useState([]);
  const [openSuperCertificateFiles, setOpenSuperCertificateFiles] = useState(false);

  const [boundDocumentsFiles, setBoundDocumentsFiles] = useState([]);
  const [openBoundDocumentsFiles, setOpenBoundDocumentsFiles] = useState(false);

  const [investigatedCertificateFiles, setInvestigatedCertificateFiles] = useState([]);
  const [openInvestigatedCertificateFiles, setOpenInvestigatedCertificateFiles  ] = useState(false);

  /*useEffect(() => {
        console.log(hearings);
    }, [hearings]);*/

  const handleOpenFiles = () => {
    setOpenFiles(!openFiles);
    setOpenFilesAgreement(false);
    setOpenFilesHearing(false);
    setOpenFilesAgreementCompliance(false);
    setOpenSuperCertificateFiles(false);
    setOpenTasksFiles(false);
    setOpenBoundDocumentsFiles(false);
    setOpenInvestigatedCertificateFiles(false);
  };

  const handleOpenFilesAgreement = () => {
    setOpenFilesAgreement(!openFilesAgreement);
    setOpenFiles(false);
    setOpenFilesHearing(false);
    setOpenFilesAgreementCompliance(false);
    setOpenSuperCertificateFiles(false);
    setOpenTasksFiles(false);
    setOpenBoundDocumentsFiles(false);
    setOpenInvestigatedCertificateFiles(false);
  };

  const handleOpenFilesAgreementCompliance = () => {
    setOpenFilesAgreement(false);
    setOpenFiles(false);
    setOpenFilesHearing(false);
    setOpenSuperCertificateFiles(false);
    setOpenFilesAgreementCompliance(!openFilesAgreementCompliance);
    setOpenTasksFiles(false);
    setOpenBoundDocumentsFiles(false);
    setOpenInvestigatedCertificateFiles(false);
  };

  const handleOpenFilesHearing = () => {
    setOpenFilesHearing(!openFilesHearing);
    setOpenFiles(false);
    setOpenFilesAgreement(false);
    setOpenFilesAgreementCompliance(false);
    setOpenSuperCertificateFiles(false);
    setOpenTasksFiles(false);
    setOpenBoundDocumentsFiles(false);
    setOpenInvestigatedCertificateFiles(false);
  };

  const handleOpenSuperCertificateFiles = () => {
    setOpenSuperCertificateFiles(!openSuperCertificateFiles);
    setOpenFilesHearing(false);
    setOpenFiles(false);
    setOpenFilesAgreement(false);
    setOpenFilesAgreementCompliance(false);
    setOpenTasksFiles(false);
    setOpenBoundDocumentsFiles(false);
    setOpenInvestigatedCertificateFiles(false);
  };

  const handleOpenTasksFiles = () => {
    setOpenFilesHearing(false);
    setOpenFiles(false);
    setOpenFilesAgreement(false);
    setOpenFilesAgreementCompliance(false);
    setOpenTasksFiles(!openTasksFiles);
    setOpenBoundDocumentsFiles(false);
    setOpenInvestigatedCertificateFiles(false);
  };

  const handleOpenBoundDocumentsFiles = () => {
    setOpenFilesHearing(false);
    setOpenFiles(false);
    setOpenFilesAgreement(false);
    setOpenFilesAgreementCompliance(false);
    setOpenTasksFiles(false);
    setOpenBoundDocumentsFiles(!openBoundDocumentsFiles);
    setOpenInvestigatedCertificateFiles(false);
  };

  const handleOpenInquiryCertificatesFiles = () => {
    setOpenFilesHearing(false);
    setOpenFiles(false);
    setOpenFilesAgreement(false);
    setOpenFilesAgreementCompliance(false);
    setOpenTasksFiles(false);
    setOpenBoundDocumentsFiles(false);
    setOpenInvestigatedCertificateFiles(!openInvestigatedCertificateFiles);
  };

  const handleDeleteFile = async () => {
    if (fileType === 'hearingFiles') {
      try {
        await inquiryService.deleteFilesInquiryService(fileToDelete);
        setOpenModalConfirmDelete(false);
        const updatedHearingFiles = hearingsFiles.filter(file => file.id !== fileToDelete);
        setHearingsFiles(updatedHearingFiles);
        toast.success('Arquivo excluído com sucesso!');
      } catch (err) {
        console.log(err);
      }
      return;
    }

    inquiryService.deleteFilesInquiryService(fileToDelete);
    setOpenModalConfirmDelete(false);
    const fileIndex = files.findIndex(e => e.id === fileToDelete);
    if (fileIndex !== -1) {
      setFiles(files.filter(arquivo => arquivo.id !== fileToDelete));
      toast.success('Arquivo excluído com sucesso!');
    }
  };

  const openModalDelete = (inquiryFileId, event, type = null) => {
    event.stopPropagation();
    setFileToDelete(inquiryFileId);
    setFileType(type);
    setOpenModalConfirmDelete(true);
  };

  useEffect(() => {
    getFiles();
    if (!isPM) {
      getFilesHearings();
      getTasksFiles();
      getFilesBoundDocuments();
      getInvestigatedCertificates();
      if (agreement) {
        getFilesAgreement(agreement.id);
        getFilesAgreementCompliance(agreement.id);
      }
    }
    const superCertificate =
      investigated &&
      investigated.map(inv => {
        return {
          fileUploadStep: inv.step,
          description: 'Antecedentes criminais',
          name:
            inv?.investigated?.rg?.code || inv?.investigated?.taxpayerRegistry
              ? `supercertidao - ${inv?.investigated?.name} - ${inv?.investigated?.rg?.code ||
                  inv?.investigated?.taxpayerRegistry}.pdf`
              : `supercertidao - ${inv?.investigated?.name}.pdf`,
          createdAt: new Date(),
        };
      });
    setSuperCertificateFiles(superCertificate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilesBoundDocuments = () => {
      inquiryService.getBoundDocumentsAttachments(inquiryIdReducer).then(response => {
        setBoundDocumentsFiles(response.data);
        setShowFiles(true);
      });
  };

  const getInvestigatedCertificates = () => {
      investigatedCertificateService.getByInvestigatedService(inquiryInvestigatedId).then(response => {
        setInvestigatedCertificateFiles(response.data);
        setShowFiles(true);
      });
  };

  const getFilesHearings = () => {
    if (userType === USER_TYPE.AssociationRepresentative) {
      associationService
        .getInvestigatedRecordingsAssociationService(inquiryInvestigatedId)
        .then(response => {
          setHearingsFiles(response.data);
          setShowFiles(true);
        });
    } else {
      inquiryService.getInvestigatedRecordingsService(inquiryInvestigatedId).then(response => {
        setHearingsFiles(response.data);
        setShowFiles(true);
      });
    }
  };

  const getFilesAgreement = agreementId => {
    agreementService.getFilesAgreementService(agreementId).then(response => {
      setFilesAgreement(response.data);
      setShowFiles(true);
    });
  };

  const getFilesAgreementCompliance = agreementId => {
    agreementService.getFilesAgreementComplianceService(agreementId).then(response => {
      setFilesAgreementCompliance(response.data);
    });
  };

  const getTasksFiles = () => {
    movementTaskService.getTasksFilesService(inquiryInvestigatedId).then(response => {
      setTasksFiles(response.data);
    });
  };

  const getFiles = () => {
    if (isPM) {
      pmService
        .getFilesInquiryPmService(inquiryIdReducer)
        .then(response => setFiles(response.data.data))
        .finally(() => setShowFiles(true));
    } else {
      if (userType === USER_TYPE.AssociationRepresentative) {
        associationService
          .getFilesInquiryAssociationService(inquiryIdReducer)
          .then(response => setFiles(response.data.data));
      } else {
        inquiryService.getFilesInquiryService(inquiryIdReducer).then(response => {
          setFiles(response.data.data);
        });
      }
    }
  };

  const handleClick = (_event, file) => {
    window.open(file.url, '_blank');
  };

  const handleOpenRecording = recording => {
    window.open(recording.url, '_blank');
  };

  const handleSuperCertificateClick = () => {
    window.open(`/certidao/${inquiryInvestigatedId}`, '_blank');
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Dialog
        fullScreen={fullScreen}
        onClose={onClose}
        open={open}
        aria-labelledby="responsive-dialog-title"
        className={classes.modal}
        maxWidth="sm"
        fullWidth
      >
        <HeaderModal onClose={onClose}>Arquivos do inquérito</HeaderModal>

        {!showFiles ? (
          <div className={classes.containerModal}>
            <div className={classes.circularProgress}>
              <CircularProgress style={{ height: 40, width: 40, color: '#00597b' }} />
            </div>
          </div>
        ) : (
          <Grid container className={classes.containerList}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {files.length < 1 &&
              filesAgreement.length < 1 &&
              hearingsFiles.length < 1 &&
              superCertificateFiles.length < 1 ? (
                <div
                  style={{
                    display: 'flex',
                    fontSize: 16,
                    justifyContent: 'center',
                    height: '100%',
                    alignItems: 'center',
                  }}
                >
                  <Typography>Não há arquivos</Typography>
                </div>
              ) : (
                <List component="nav">
                  <ListItem button onClick={handleOpenFiles}>
                    <ListItemIcon>
                      <ListIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Arquivos" />
                    {openFiles ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Divider />
                  <Collapse in={openFiles} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {files.length > 0 ? (
                        <ListItem button className={classes.nested}>
                          <TableFiles
                            step={step}
                            onlyView={onlyView}
                            files={files}
                            fileType={'generalFiles'}
                            handleClick={handleClick}
                            openModalDelete={openModalDelete}
                            viewDeleteAudienceButton={viewDeleteAudienceButton}
                            enableDeleteAudienceButton={enableDeleteAudienceButton}
                          />
                        </ListItem>
                      ) : (
                        <Box>
                          <ListItem button className={classes.nested}>
                            <ListItemText primary="Não há arquivos" />
                          </ListItem>
                          <Divider />
                        </Box>
                      )}
                    </List>
                  </Collapse>
                  <ListItem button onClick={handleOpenFilesAgreement}>
                    <ListItemIcon>
                      <ListIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Arquivos do acordo" />
                    {openFilesAgreement ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Divider />
                  <Collapse in={openFilesAgreement} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {filesAgreement.length > 0 ? (
                        <TableFiles
                          onlyView={onlyView}
                          files={filesAgreement}
                          fileType={'agreementFiles'}
                          handleClick={handleClick}
                          openModalDelete={openModalDelete}
                        />
                      ) : (
                        <Box>
                          <ListItem button className={classes.nested}>
                            <ListItemText primary="Não há arquivos" />
                          </ListItem>
                          <Divider />
                        </Box>
                      )}
                    </List>
                  </Collapse>

                  <ListItem button onClick={handleOpenFilesHearing}>
                    <ListItemIcon>
                      <ListIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={`Arquivos de Audiências${enableHearingForJuvenileOffenders ? '/Oitivas' : ''}`}
                    />
                    {openFilesHearing ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Divider />
                  <Collapse in={openFilesHearing} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {hearingsFiles.length > 0 ? (
                        <TableFiles
                          step={step}
                          onlyView={onlyView}
                          files={hearingsFiles}
                          fileType={'hearingFiles'}
                          handleClick={handleClick}
                          openModalDelete={openModalDelete}
                          handleOpenRecording={handleOpenRecording}
                          viewDeleteAudienceButton={viewDeleteAudienceButton}
                          enableDeleteAudienceButton={enableDeleteAudienceButton}
                          
                        />
                      ) : (
                        <Box>
                          <ListItem button className={classes.nested}>
                            <ListItemText primary="Não há arquivos" />
                          </ListItem>
                          <Divider />
                        </Box>
                      )}
                    </List>
                  </Collapse>

                  <ListItem button onClick={handleOpenFilesAgreementCompliance}>
                    <ListItemIcon>
                      <ListIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Conformidade do acordo" />
                    {openFilesAgreementCompliance ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Divider />
                  <Collapse in={openFilesAgreementCompliance} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {filesAgreementCompliance.length > 0 ? (
                        <TableFiles
                          onlyView={onlyView}
                          files={filesAgreementCompliance}
                          fileType={'agreementComplianceFiles'}
                          handleClick={handleClick}
                          openModalDelete={openModalDelete}
                        />
                      ) : (
                        <Box>
                          <ListItem button className={classes.nested}>
                            <ListItemText primary="Não há arquivos" />
                          </ListItem>
                          <Divider />
                        </Box>
                      )}
                    </List>
                  </Collapse>

                  <ListItem button onClick={handleOpenTasksFiles}>
                    <ListItemIcon>
                      <ListIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Arquivos das tarefas" />
                    {openTasksFiles ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Divider />
                  <Collapse in={openTasksFiles} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {tasksFiles.length > 0 ? (
                        <TableFiles
                          onlyView={onlyView}
                          files={tasksFiles}
                          fileType={'tasksFiles'}
                          handleClick={handleClick}
                        />
                      ) : (
                        <Box>
                          <ListItem button className={classes.nested}>
                            <ListItemText primary="Não há arquivos" />
                          </ListItem>
                          <Divider />
                        </Box>
                      )}
                    </List>
                  </Collapse>

                  <ListItem button onClick={handleOpenSuperCertificateFiles}>
                    <ListItemIcon>
                      <ListIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Super Certidão" />
                    {openSuperCertificateFiles ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Divider />
                  <Collapse in={openSuperCertificateFiles} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {superCertificateFiles.length > 0 ? (
                        <TableFiles
                          onlyView={onlyView}
                          files={superCertificateFiles}
                          fileType={'superCertificateFiles'}
                          // handleClick={handleSuperCertificateClick}
                          handleSuperCertificateClick={handleSuperCertificateClick}
                        />
                      ) : (
                        <Box>
                          <ListItem button className={classes.nested}>
                            <ListItemText primary="Não há arquivos" />
                          </ListItem>
                          <Divider />
                        </Box>
                      )}
                    </List>
                  </Collapse>

                  <ListItem button onClick={handleOpenBoundDocumentsFiles}>
                    <ListItemIcon>
                      <ListIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Arquivos de requisitório" />
                    {openBoundDocumentsFiles ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Divider />
                  <Collapse in={openBoundDocumentsFiles} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {boundDocumentsFiles.length > 0 ? (
                        <TableFiles
                          onlyView={onlyView}
                          files={boundDocumentsFiles}
                          fileType={'requisitionFiles'}
                          handleClick={handleClick}
                        />
                      ) : (
                        <Box>
                          <ListItem button className={classes.nested}>
                            <ListItemText primary="Não há arquivos" />
                          </ListItem>
                          <Divider />
                        </Box>
                      )}
                    </List>
                  </Collapse>

                  <ListItem button onClick={handleOpenInquiryCertificatesFiles}>
                    <ListItemIcon>
                      <ListIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Certificados de arquivamento" />
                    {openInvestigatedCertificateFiles ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Divider />
                  <Collapse in={openInvestigatedCertificateFiles} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {investigatedCertificateFiles.length > 0 ? (
                        <TableFiles
                          onlyView={onlyView}
                          files={investigatedCertificateFiles}
                          fileType={'investigatedCertificatedFiles'}
                          handleClick={handleClick}
                        />
                      ) : (
                        <Box>
                          <ListItem button className={classes.nested}>
                            <ListItemText primary="Não há arquivos" />
                          </ListItem>
                          <Divider />
                        </Box>
                      )}
                    </List>
                  </Collapse>
                </List>
              )}
            </Grid>
          </Grid>
        )}
        {openModalConfirmDelete && (
          <ModalConfirm
            title="Confirmação"
            open={openModalConfirmDelete}
            handleClose={() => setOpenModalConfirmDelete(false)}
            onClick={handleDeleteFile}
            noCloseOnConfirm={true}
          >
            <Typography variant="h5">Deseja excluir esse arquivo?</Typography>
          </ModalConfirm>
        )}
      </Dialog>
    </div>
  );
};

export default ModalListFIles;
