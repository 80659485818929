import React, { useMemo } from 'react';
import { Grid, CircularProgress, Typography, Box } from '@material-ui/core';
import { ForwardToInstitutionRadioGroup, InputConsensus } from 'components';
import associationService from 'services/association/associationService';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import LazyAutocomplete from 'components/LazyAutocomplete';
import { AutoCompleteConfig } from 'constants/autocomplete';

const PenaltiesInstitution = props => {
  const {
    institutionType,
    setInstitutionType,
    institutionSelected,
    setInstitutionSelected,
    institutionTypeOptions,
    disabledInstitutionType,
    enableResponsibleSector = false,
    judicialDistrictId,
  } = props;

  const { isMPDFT } = useCustomerConfig();

  const institutionTitle = useMemo(
    () =>
      institutionType === institutionTypeOptions.encaminhadora ? 'Encaminhadora' : 'Fiscalizadora',
    [institutionType, institutionTypeOptions.encaminhadora],
  );

  const handleChangeInstitutionType = event => {
    setInstitutionType(event.target.value);
  };

  const handleChangeInstitution = (_event, newValue) => {
    setInstitutionSelected(newValue);
  };

  const fetchAssociations = async (search, page) => {
    const params = {
      forward: true,
      search,
      page,
      size: AutoCompleteConfig.PAGINATION_SIZE,
    };

    if (!isMPDFT) {
      params.judicialDistrictId = judicialDistrictId;
    }

    const result = await associationService.getAssociationsService(params);

    return {
      data: result.data.data,
      lastPage: result.data.lastPage,
    };
  };

  return (
    <>
      {setInstitutionType && (
        <ForwardToInstitutionRadioGroup
          value={institutionType}
          onChange={handleChangeInstitutionType}
          institutionTypeOptions={institutionTypeOptions}
          disabledInstitutionType={disabledInstitutionType}
        />
      )}
      {institutionType in institutionTypeOptions && (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box mb={1}>
            <Typography variant="h5" align="left">
              {enableResponsibleSector || isMPDFT
                ? 'Selecione o SEMA responsável'
                : `Selecione a instituição ${institutionTitle}`}
            </Typography>
          </Box>
          <LazyAutocomplete
            autoHighlight
            onChange={handleChangeInstitution}
            value={institutionSelected}
            getOptionLabel={option => option.name || ''}
            renderOption={option => <>{option.name || ''}</>}
            enablePagination
            fetchOptions={fetchAssociations}
            disabled={disabledInstitutionType || enableResponsibleSector}
            renderInput={params => (
              <InputConsensus
                {...params}
                label="Instituição*"
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {params.InputProps.loading ? (
                        <CircularProgress color="primary" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Grid>
      )}
    </>
  );
};

export default PenaltiesInstitution;
