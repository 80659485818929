const documentStatus = {
  EM_SECRETARIA: 'EM_SECRETARIA',
  AGUARDANDO_AUDIENCIA: 'AGUARDANDO_AUDIENCIA',
  AGUARDANDO_DECISAO_JUDICIAL: 'AGUARDANDO_DECISAO_JUDICIAL',
  AGUARDANDO_DECURSO_DE_PRAZO: 'AGUARDANDO_DECURSO_DE_PRAZO',
  CONCLUSO_AO_MEMBRO: 'CONCLUSO_AO_MEMBRO',
  AGUARDANDO_INQUERITO: 'AGUARDANDO_INQUERITO',
  AGUARDANDO_EXPEDIENTE_RECIBADO: 'AGUARDANDO_EXPEDIENTE_RECIBADO',
  AGUARDANDO_PRECATORIA: 'AGUARDANDO_PRECATORIA',
  TRANSITADO_EM_JULGADO: 'TRANSITADO_EM_JULGADO',
  AGUARDANDO_DECURSO_DE_PRAZO_PARA_INTERPOSICAO_DE_RECURSO: 'AGUARDANDO_DECURSO_DE_PRAZO_PARA_INTERPOSICAO_DE_RECURSO',
  AGUARDANDO_LAUDO: 'AGUARDANDO_LAUDO',
  AGUARDANDO_RETORNO_DE_AUTOS: 'AGUARDANDO_RETORNO_DE_AUTOS',
  AGUARDANDO_CUMPRIMENTO_DE_RECOMENDACAO: 'AGUARDANDO_CUMPRIMENTO_DE_RECOMENDACAO',
  AGUARDANDO_PRAZO_DE_CUMPRIMENTO_DO_COMPROMISSO_DE_AJUSTAMENTO: 'AGUARDANDO_PRAZO_DE_CUMPRIMENTO_DO_COMPROMISSO_DE_AJUSTAMENTO',
  AGUARDANDO_DECURSO_DE_PRAZO_RECURSAL_DE_INDEFERIMENTO_DE_NOTICIA_DE_FATO: 'AGUARDANDO_DECURSO_DE_PRAZO_RECURSAL_DE_INDEFERIMENTO_DE_NOTICIA_DE_FATO',
  AGUARDANDO_DECISAO_ORGAO_SUPERIOR: 'AGUARDANDO_DECISAO_ORGAO_SUPERIOR',
  ARQUIVADO: 'ARQUIVADO',
  AGUARDANDO_RESPOSTA_DE_REQUISITORIO_MINISTERIAL: 'AGUARDANDO_RESPOSTA_DE_REQUISITORIO_MINISTERIAL',
  AGUARDANDO_CUMPRIMENTO_DE_ACORDO: 'AGUARDANDO_CUMPRIMENTO_DE_ACORDO',
  DECLINADO_PARA_MESMO_RAMO: 'DECLINADO_PARA_MESMO_RAMO',
  DECLINADO_PARA_OUTRO_RAMO: 'DECLINADO_PARA_OUTRO_RAMO',
  DENUNCIADO: 'DENUNCIADO',
  AGUARDANDO_HOMOLOGACAO_DE_ACORDO: 'AGUARDANDO_HOMOLOGACAO_DE_ACORDO',
  CAUTELAR_ENCAMINHADA_PARA_O_JUDICIARIO: 'CAUTELAR_ENCAMINHADA_PARA_O_JUDICIARIO',
  MINUTADO: 'MINUTADO',
};

const documentStatusList = subtype => {
  let arrayStatus = [];
  for (const key in documentStatus) {
    if (documentStatus.hasOwnProperty.call(documentStatus, key)) {
      arrayStatus.push({
        name: documentStatus[key].replace(/_/g, ' '),
        value: documentStatus[key],
      });
    }
  }
  if (listInfanciaEJuventude.some(e => e.code === subtype.code && e.name === subtype.name)) {
    arrayStatus.push(
      {
        name: 'REPRESENTADO',
        value: 'REPRESENTADO',
      },
      {
        name: 'REMIDO',
        value: 'REMIDO',
      },
    );
  }
  return arrayStatus;
};

const listSubtypeGeneric = [
  {
    code: '920015',
    name: 'Denúncia',
    targetDocumentStatus: documentStatus.AGUARDANDO_DECISAO_JUDICIAL,
  },
];

const listInfanciaEJuventude = [
  {
    code: '920478',
    name: 'Remissão - Cumulada com medida protetiva',
    targetDocumentStatus: null,
  },
  {
    code: '920078',
    name: 'Remissão - Advertência',
    targetDocumentStatus: null,
  },
  {
    code: '920081',
    name: 'Remissão - Liberdade Assistida',
    targetDocumentStatus: null,
  },
  {
    code: '920080',
    name: 'Remissão - Prestação de Serviço à Comunidade',
    targetDocumentStatus: null,
  },
  {
    code: '920079',
    name: 'Remissão - Reparação de Dano',
    targetDocumentStatus: null,
  },
  {
    code: '920076',
    name: 'Remissão - Simples',
    targetDocumentStatus: null,
  },
  {
    code: '920017',
    name: 'Representação por Ato Infracional',
    targetDocumentStatus: null,
  },
  {
    code: '920253',
    name: 'Despacho',
    targetDocumentStatus: null,
  },
  {
    code: '920198',
    name: 'Arquivamento com remessa ao Judiciário',
    targetDocumentStatus: null,
  },
];

const listDespacho = [
  {
    code: '920198',
    name: 'Manifestação ao Judiciário',
    targetDocumentStatus: documentStatus.AGUARDANDO_RETORNO_DE_AUTOS,
  },
  { code: '1000000', name: 'Manifestação ao Judiciário (Caso NF)', targetDocumentStatus: null },
  {
    code: '920198',
    name: 'Manifestação ao Judiciário (Homologação)',
    targetDocumentStatus: documentStatus.AGUARDANDO_DECISAO_JUDICIAL,
  },
  {
    code: '920253',
    name: 'Despacho',
    targetDocumentStatus: documentStatus.AGUARDANDO_RETORNO_DE_AUTOS,
  },
  {
    code: '920054',
    name: 'Dilação de prazo (com prazo)',
    targetDocumentStatus: documentStatus.AGUARDANDO_RESPOSTA_DE_REQUISITORIO_MINISTERIAL,
  },
  { code: '920261', name: 'Ofício comunicação (sem prazo)', targetDocumentStatus: null },
  { code: '920261', name: 'Ofício requisição (com prazo)', targetDocumentStatus: null },
  {
    code: '920258',
    name: 'Req. Diligência',
    targetDocumentStatus: documentStatus.AGUARDANDO_RESPOSTA_DE_REQUISITORIO_MINISTERIAL,
  },
  {
    code: '920021',
    name: 'Inst de IP (com prazo)',
    targetDocumentStatus: documentStatus.AGUARDANDO_INQUERITO,
  },
  { code: '920197', name: 'Ciência de decisão', targetDocumentStatus: null },
  {
    code: '920155',
    name: 'Ciência de arquivamento',
    targetDocumentStatus: documentStatus.ARQUIVADO,
  },
  //{ code: '920253', name: 'Iniciar cumprimento acordo', targetDocumentStatus: null },
];

const listArquivar = [
  {
    code: '1000006',
    name: 'Arquivamento com Remessa por Cumprimento de Acordo',
    targetDocumentStatus: documentStatus.AGUARDANDO_RETORNO_DE_AUTOS,
  },
  { code: '920261', name: 'Arquivamento de Flagrante Aguardando IP', targetDocumentStatus: null },
  {
    code:'920109',
    name:'Arquivamento Interno para Infância e Juventude',
    targetDocumentStatus:null
  }
];

const listArquivarResolucaoCNMP = [
  {
    code: '920198',
    name: 'Manifestação ao Judiciário',
    targetDocumentStatus: documentStatus.AGUARDANDO_RETORNO_DE_AUTOS,
  },
  {
    code: '920015',
    name: 'Inexistência de Crime',
    targetDocumentStatus: documentStatus.AGUARDANDO_RETORNO_DE_AUTOS,
  },
  {
    code: '920103',
    name: 'Prescrição',
    targetDocumentStatus: documentStatus.AGUARDANDO_RETORNO_DE_AUTOS,
  },
  {
    code: '920104',
    name: 'Decadência',
    targetDocumentStatus: documentStatus.AGUARDANDO_RETORNO_DE_AUTOS,
  },
  {
    code: '920268',
    name: 'Ausência/insuf.Prova',
    targetDocumentStatus: documentStatus.AGUARDANDO_RETORNO_DE_AUTOS,
  },
  {
    code: '920101',
    name: 'Desconhecimento do Autor',
    targetDocumentStatus: documentStatus.AGUARDANDO_RETORNO_DE_AUTOS,
  },
  {
    code: '920483',
    name: 'Morte do agente',
    targetDocumentStatus: documentStatus.AGUARDANDO_RETORNO_DE_AUTOS,
  },
  { code: '920109', name: 'Arq. Interno', targetDocumentStatus: documentStatus.ARQUIVADO },
  {
    code: '920155',
    name: 'Ciência de arquivamento',
    targetDocumentStatus: documentStatus.ARQUIVADO,
  },
];

const listDeclinar = [
  {
    code: '920027',
    name: 'Mesmo ramo',
    targetDocumentStatus: documentStatus.AGUARDANDO_DECISAO_JUDICIAL,
  },
  {
    code: '920028',
    name: 'Outro ramo',
    targetDocumentStatus: documentStatus.AGUARDANDO_DECISAO_JUDICIAL,
  },
];

export {
  listSubtypeGeneric,
  listDespacho,
  listArquivar,
  listDeclinar,
  listInfanciaEJuventude,
  listArquivarResolucaoCNMP,
  documentStatusList,
};
