import React, { useState } from 'react';
import { Box, Collapse, Divider, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { formatedPandoraFieldNames, treatPandoraKey } from '../../utils/pandoraParserForExibition';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { formatDateToBrazilian } from 'utils/date-time.utils';

const useStyles = makeStyles(theme => ({
  table: {
    width: '100%',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const BasicTable = ({ fieldNames, tableData, tableName }) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => setExpanded(!expanded);

  const formatValueIfDate = value => {
    const date = new Date(value);
    const isDate = !isNaN(date.getTime());
    if (isDate && isNaN(Number(value))) {
      return formatDateToBrazilian(date);
    }
    return value;
  }

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography color="primary" variant="h5">
          {tableName || ''}
        </Typography>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Table>
          <TableHead>
            <TableRow>
              {fieldNames.map((field, index) => (
                <TableCell key={index}>{formatedPandoraFieldNames[field] || treatPandoraKey(field)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((item, index) => (
              <TableRow key={index}>
                {fieldNames.map((value, idx) => (
                  <TableCell key={idx}>{item[value] ? formatValueIfDate(item[value]) : '-'}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Collapse>
      <Divider color="primary" />
    </Box>
  );
};

export default BasicTable;
