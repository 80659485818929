/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Grid, IconButton, Tooltip, Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { InputConsensus } from 'components';
import { Autocomplete } from '@material-ui/lab';
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import pmService from 'services/PM/pmService';
import inquiryService from 'services/inquiries/inquiryService';
import debounce from 'lodash.debounce';
import { useUserTypes } from 'hooks/useUserTypes';
import { toast } from 'react-toastify';
import { setBoundInstitutionReducer } from 'actions';
import PmService from 'services/PM/pmService';
import LazyAutocomplete from '../LazyAutocomplete';

const useStyles = makeStyles(() => ({
  root: {
    padding: 10,
  },
  titulo: {
    color: '#00597b',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 20,
    fontFamily: 'roboto-regular',
  },
  containerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleLeft: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
  },
  titleRight: {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  form: {
    heigth: '100%',
    width: '100%',
  },
  containerLabelInput: {
    padding: 5,
    width: '100%',
  },
  inputFormCrime: {
    height: 20,
    outline: 0,
    width: '100%',
    border: 0,
    backgroundColor: 'transparent',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    fontWeight: 'bold',
    color: '#00597b',
    textAlign: 'left',
  },
  itemRight: {
    fontSize: 14,
    color: '#00597b',
  },
  containerItem: {
    display: 'flex',
  },
}));

const FormBoundInstitution = props => {
  const {
    clearFormBoundInstitution,
    setClearFormBoundInstitution,
    boundInstitution = undefined,
    editBoundInstitution,
    setEditBoundInstitution,
    otherStep,
    write,
    handleOpenSnackbar,
    newInquiry,
    classe,
    inquiryId,
    refreshPage,
    pendingInquiry,
    canEdit = false,
  } = props;

  const initialState = {
    id: null,
    name: null,
    groups: null,
    documentClasses: null,
    createdAt: null,
    updatedAt: null,
  };

  const classesInquiryReducer = useSelector(state => state.classesInquiry);

  const { isPM } = useUserTypes();

  const classes = useStyles();

  const dispatch = useDispatch();

  const [classCode, setClassCode] = useState(
    newInquiry && !pendingInquiry ? classesInquiryReducer.code : classe,
  );
  const [formState, setFormState] = useState({
    boundInstitution: boundInstitution ? { ...boundInstitution } : { ...initialState },
  });

  const fetchInstitutions = async (search, page) => {
    const params = {
      page,
      size: 20,
      search,
    }

    if (isPM) {
      const result = await pmService.getInstitutionsRegistrationPmByClass(classCode, params);

      return {
        data: result.data.data,
        lastPage: result.data.lastPage,
      }
    }

    const result = await inquiryService.getInstitutionsRegistrationByClass(classCode, params);

    return {
      data: result.data.data,
      lastPage: result.data.lastPage,
    }
  }

  const handleChangeBoundInstitution = (event, newValue) => {
    setFormState(formState => ({
      ...formState,
      boundInstitution: newValue,
    }));
  };

  useEffect(() => {
    if (clearFormBoundInstitution) {
      setFormState(formState => ({
        ...formState,
        boundInstitution: initialState,
      }));
      setClearFormBoundInstitution(false);
    }
  }, [clearFormBoundInstitution]);

  const handleEditBoundInstitutionToFalse = () => {
    setFormState(formState => ({
      ...formState,
      boundInstitution: boundInstitution ? { ...boundInstitution } : { ...initialState },
    }));
    setEditBoundInstitution(false);
  };

  const handleEditBoundInstitutionToTrue = () => {
    setEditBoundInstitution(true);
  };

  const handleUpdateBoundInstitution = () => {
    let params = {
      inquiryRegistrationUserInstitutionId: formState.boundInstitution?.id || null,
    };

    if (!newInquiry && !pendingInquiry) {
      if (isPM) {
        PmService.updateInquiryService(inquiryId, params).then(() => {
          toast.success('Instituição vinculada alterada com sucesso');
          setEditBoundInstitution(false);
          refreshPage();
        });
      } else {
        inquiryService.updateInquiryService(inquiryId, params).then(() => {
          toast.success('Instituição vinculada alterada com sucesso');
          setEditBoundInstitution(false);
          refreshPage();
        });
      }
    }
  };

  useEffect(() => {
    if (!boundInstitution && formState.boundInstitution !== null) {
      dispatch(setBoundInstitutionReducer(formState.boundInstitution));
    } else if (
      (!boundInstitution && formState.boundInstitution === null) ||
      (!boundInstitution && formState.boundInstitution === '')
    ) {
      dispatch(setBoundInstitutionReducer(initialState));
    }
  }, [formState.boundInstitution]);

  return (
    <Box className={classes.root}>
      <Box className={classes.containerTitle}>
        <Box className={classes.titleLeft}>
          <Typography className={classes.titulo}>Instituição Vinculada</Typography>
        </Box>
        <Box className={classes.titleRight}>
          {!editBoundInstitution && ((!otherStep && !isPM) || canEdit) && !newInquiry ? (
            <Tooltip title="Alterar instituição vinculada" arrow placement="top">
              <IconButton
                className={classes.buttonEditar}
                size="small"
                onClick={() => (!write ? handleOpenSnackbar() : handleEditBoundInstitutionToTrue())}
              >
                <CreateIcon style={{ color: '#00b1ac' }} />
              </IconButton>
            </Tooltip>
          ) : (
            editBoundInstitution && (
              <>
                <Box style={{ marginRight: 10 }}>
                  <Tooltip title="Salvar alterações" arrow placement="top">
                    <IconButton
                      className={classes.buttonEditar}
                      size="small"
                      onClick={handleUpdateBoundInstitution}
                      form="my-form-edit"
                      type="submit"
                    >
                      <SaveIcon style={{ color: '#1976d2' }} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box>
                  <Tooltip title="Cancelar" arrow placement="top">
                    <IconButton
                      className={classes.buttonEditar}
                      size="small"
                      onClick={handleEditBoundInstitutionToFalse}
                    >
                      <ClearIcon style={{ color: '#e1296a' }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </>
            )
          )}
        </Box>
      </Box>
      {editBoundInstitution || newInquiry ? (
        <form className={classes.form}>
          <Grid container spacing={1}>
            <div className={classes.containerLabelInput} style={{ padding: 10 }}>
              <LazyAutocomplete
                value={formState.boundInstitution}
                onChange={handleChangeBoundInstitution}
                getOptionLabel={option => option.name}
                renderOption={option => <>{option.name}</>}
                fullWidth
                enablePagination
                fetchOptions={fetchInstitutions}
                renderInput={params => (
                  <InputConsensus
                    {...params}
                    label="Instituição "
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.loading ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </Grid>
        </form>
      ) : (
        <Grid container spacing={1}>
          <Grid item>
            <Box className={classes.containerItem} style={{ marginTop: 5 }}>
              <Typography variant="body1" className={classes.itemRight}>
                {formState?.boundInstitution?.name
                  ? formState.boundInstitution?.name
                  : 'Não há instituição vinculada'}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default FormBoundInstitution;
