/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Box, CircularProgress, Tooltip, IconButton, Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { InputConsensus } from 'components';
import inquiryService from 'services/inquiries/inquiryService';
import { clearInquiryForm, setResponsibleInquiryReducer } from 'actions';
import { useUserTypes } from 'hooks/useUserTypes';
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import { toast } from 'react-toastify';
import userService from 'services/user/userService';
import * as _ from 'lodash';
import LazyAutocomplete from 'components/LazyAutocomplete';

const useStyles = makeStyles(() => ({
  root: {},
  title: {
    fontWeight: 'bold',
    fontSize: 20,
  },
  subtitle: {
    fontSize: 14,
  },
}));

const ResponsibleForInquiry = props => {
  const {
    inquiry,
    otherStep,
    write,
    refreshPage,
    isPendingInquiry = false,
    canEdit = false,
  } = props;

  const classes = useStyles();

  const dispatch = useDispatch();

  const { isPM } = useUserTypes();

  const { groups } = useSelector(state => state.logedUser);
  const { selectedIndexGroup } = useSelector(state => state.groupMenu);
  const { clearForm: clearFormReducer } = useSelector(state => state.inquiry);

  const [editValues, setEditValues] = useState(false);

  const [responsibles, setResponsibles] = useState(
    inquiry?.responsibleUser
      ? [{ id: inquiry.responsibleUser.id, name: inquiry.responsibleUser.name }]
      : [],
  );

  const [selectedResponsible, setSelectedResponsible] = useState(null);

  const fetchResponsiblesForInquirys = async (search, page) => {

    let params = {
      page,
      size: 20,
      search,
    }

    const response = await userService.getResponsiblesForInquiryService(groups[selectedIndexGroup].id, params);

    return {
      data: response.data.data,
      lastPage: response.data.lastPage,
    }
  }

  const handleEditValuesToTrue = () => {
    if (!write) {
      return toast.warn('Você não tem permissão para tomar esta ação');
    }
    setEditValues(true);
  };

  const handleEditValuesToFalse = () => {
    if (inquiry?.responsibleUser) {
      const responsibleOfInquiry = {
        id: inquiry.responsibleUser.id,
        name: inquiry.responsibleUser.name,
      };

      setSelectedResponsible(responsibleOfInquiry);
    }
    setEditValues(false);
  };

  const changeValuesRequest = () => {
    let params = {
      responsibleUserId: selectedResponsible.id,
    };

    inquiryService.updateInquiryService(inquiry.id, params).then(() => {
      toast.success('Responsável alterado com sucesso!');
      setEditValues(false);
      refreshPage();
    });
  };

  useEffect(() => {
    if (clearFormReducer) {
      setSelectedResponsible(null);
      dispatch(setResponsibleInquiryReducer(null));
      dispatch(clearInquiryForm(false));
    }
  }, [clearFormReducer]);

  useEffect(() => {
    if (inquiry?.responsibleUser) {
      const responsibleOfInquiry = {
        id: inquiry.responsibleUser.id,
        name: inquiry.responsibleUser.name,
      };

      dispatch(setResponsibleInquiryReducer(responsibleOfInquiry));

      setSelectedResponsible(responsibleOfInquiry);
    } else {
      dispatch(setResponsibleInquiryReducer(null));
    }

    // if (!otherStep && (!isPM || canEdit)) {
  }, []);

  return (
    <Box width="100%" height="100%" display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          className={classes.title}
          component="h3"
          variant="h3"
          color="primary"
          align="left"
        >
          Responsável pelo Inquérito
        </Typography>
        <Box display="flex" alignItems="center">
          {!editValues && !otherStep && !isPendingInquiry && !isPM && inquiry ? (
            <Tooltip title="Alterar responsável pelo inquérito" arrow placement="top">
              <IconButton size="small" onClick={handleEditValuesToTrue}>
                <CreateIcon style={{ color: '#00b1ac' }} />
              </IconButton>
            </Tooltip>
          ) : (
            editValues && (
              <>
                <div style={{ marginRight: 10 }}>
                  <Tooltip title="Salvar alterações" arrow placement="top">
                    <IconButton
                      className={classes.buttonEditar}
                      size="small"
                      onClick={changeValuesRequest}
                    >
                      <SaveIcon style={{ color: '#1976d2' }} />
                    </IconButton>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title="Cancelar" arrow placement="top">
                    <IconButton
                      className={classes.buttonEditar}
                      size="small"
                      onClick={handleEditValuesToFalse}
                    >
                      <ClearIcon style={{ color: '#e1296a' }} />
                    </IconButton>
                  </Tooltip>
                </div>
              </>
            )
          )}
        </Box>
      </Box>
      <Box display="flex" flex={1} alignItems="center">
        {(editValues || !inquiry || isPendingInquiry) && !isPM ? (
          <LazyAutocomplete
            value={selectedResponsible}
            onChange={(_, newValue) => {
              setSelectedResponsible(newValue);
              dispatch(setResponsibleInquiryReducer(newValue));
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(option) => <>{option.name}</>}
            enablePagination={true}
            fetchOptions={fetchResponsiblesForInquirys}
            fullWidth
            disableRelativePosition={true}
            renderInput={(params) => (
              <InputConsensus
                {...params}
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {params.InputProps.loading ? (
                        <CircularProgress color="primary" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        ) : (
          <Typography
            className={classes.subtitle}
            component="h4"
            variant="body1"
            color="primary"
            align="left"
          >
            {inquiry?.responsibleUser ? inquiry?.responsibleUser.name : 'Não informado'}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ResponsibleForInquiry;
