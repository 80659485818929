export const SET_CONFIG_SDS = 'SET_CONFIG_SDS';
export const SET_CONFIG_CONSENSUS = 'SET_CONFIG_CONSENSUS';
export const SET_CONFIG_QUERY = 'SET_CONFIG_QUERY';
export const NAVBAR_LOGOUT = 'NAVBAR_LOGOUT';
export const SET_ACTIVE_INQUERITOS = 'SET_ACTIVE_INQUERITOS';
export const SET_ACTIVE_INQUERITOS_BUSCA = 'SET_ACTIVE_INQUERITOS_BUSCA';
export const SET_ACTIVE_TRIAGEM = 'SET_ACTIVE_TRIAGEM';
export const SET_ACTIVE_PESQUISA = 'SET_ACTIVE_PESQUISA';
export const SET_ACTIVE_ANALISE = 'SET_ACTIVE_ANALISE';
export const SET_ACTIVE_PROCESSING_CONTROLL = 'SET_ACTIVE_PROCESSING_CONTROLL';
export const SET_ACTIVE_INTIMACAO = 'SET_ACTIVE_INTIMACAO';
export const SET_FORWARDING_TERM = 'SET_FORWARDING_TERM';
export const SET_AGREEMENT = 'SET_AGREEMENT';
export const SET_ACTIVE_VISUALIZAR_INTIMACAO = 'SET_ACTIVE_VISUALIZAR_INTIMACAO';
export const SET_ACTIVE_VISUALIZAR_AUDIENCIA = 'SET_ACTIVE_VISUALIZAR_AUDIENCIA';
export const SET_ACTIVE_CONFIG_INTIMACAO = 'SET_ACTIVE_CONFIG_INTIMACAO';
export const SET_ACTIVE_REGISTER_USER = 'SET_ACTIVE_REGISTER_USER';
export const SET_ACTIVE_REGISTER_PAPERS = 'SET_ACTIVE_REGISTER_PAPERS';
export const SET_ACTIVE_REGISTER_SERVICE = 'SET_ACTIVE_REGISTER_SERVICE';
export const SET_ACTIVE_CONFIG_PAPERS = 'SET_ACTIVE_CONFIG_PAPERS';
export const SET_ACTIVE_REGISTER_GROUPE_OFFICE = 'SET_ACTIVE_REGISTER_GROUPE_OFFICE';
export const SET_ACTIVE_REGISTER_INQUIRY = 'SET_ACTIVE_REGISTER_INQUIRY';
export const SET_ACTIVE_IMPORT_INQUIRY = 'SET_ACTIVE_IMPORT_INQUIRY';
export const SET_ACTIVE_REGISTER_ASSOCIATION = 'SET_ACTIVE_REGISTER_ASSOCIATION';
export const SET_ACTIVE_REGISTER_REQUEST_SERVICE = 'SET_ACTIVE_REGISTER_REQUEST_SERVICE';
export const SET_ACTIVE_EMAIL_DELEGACIAS = 'SET_ACTIVE_EMAIL_DELEGACIAS';
export const SET_ACTIVE_REPORT = 'SET_ACTIVE_REPORT';
export const SET_ACTIVE_REPORT_INQUIRY = 'SET_ACTIVE_REPORT_INQUIRY';
export const SET_ACTIVE_CONFIG_LOGIN_GOOGLE_API = 'SET_ACTIVE_CONFIG_LOGIN_GOOGLE_API';
export const SET_ACTIVE_SCHEDULE = 'SET_ACTIVE_SCHEDULE';
export const SET_BATCH_DISTRIBUTION = 'SET_BATCH_DISTRIBUTION';
export const SET_BATCH_DEVOLUTION = 'SET_BATCH_DEVOLUTION';
export const SET_ACTIVE_TYPIFICATION = 'SET_ACTIVE_TYPIFICATION';
export const SET_ACTIVE_REGISTER_LAW = 'SET_ACTIVE_REGISTER_LAW';
export const SET_ACTIVE_REGISTER_ORGAN = 'SET_ACTIVE_REGISTER_ORGAN';
export const SET_ACTIVE_REPORT_CENTER_INQUIRY = 'SET_ACTIVE_REPORT_CENTER_INQUIRY';
export const SET_ACTIVE_REPORT_APFD = 'SET_ACTIVE_REPORT_APFD';
export const SET_ACTIVE_REPORT_OUT_OF_DATE_REQUESTS = 'SET_ACTIVE_REPORT_OUT_OF_DATE_REQUESTS';
export const SET_ACTIVE_REPORT_DISTRIBUTIONS_MOVE = 'SET_ACTIVE_REPORT_DISTRIBUTIONS_MOVE';
export const SET_ACTIVE_REPORT_RETURNED_PROCEDURES = 'SET_ACTIVE_REPORT_RETURNED_PROCEDURES';
export const SET_ACTIVE_REPORT_PENDING_NONPENDING = 'SET_ACTIVE_REPORT_PENDING_NONPENDING';
export const SET_ACTIVE_REPORT_PENDING_OR_TRIAGE = 'SET_ACTIVE_REPORT_PENDING_OR_TRIAGE';
export const SET_ACTIVE_REPORT_INTERNAL_AFFAIRS = 'SET_ACTIVE_REPORT_INTERNAL_AFFAIRS';
export const SET_ACTIVE_REPORT_PCPE_INTEGRATION = 'SET_ACTIVE_REPORT_PCPE_INTEGRATION';
export const SET_ACTIVE_BAIXA_DE_CARGA = 'SET_ACTIVE_BAIXA_DE_CARGA';
export const SET_ACTIVE_REGISTER_PROJECT = 'SET_ACTIVE_REGISTER_PROJECT';
export const SET_ACTIVE_PROSECUTOR_LIFETIME = 'SET_ACTIVE_PROSECUTOR_LIFETIME';
export const SET_ACTIVE_REGISTER_SUBJECT = 'SET_ACTIVE_REGISTER_SUBJECT';
export const SET_ACTIVE_REGISTER_PARTNER_ASSOCIATIONS = 'SET_ACTIVE_REGISTER_PARTNER_ASSOCIATIONS';
export const SET_ACTIVE_PROCEDURE_AUDIT_REPORT = 'SET_ACTIVE_PROCEDURE_AUDIT_REPORT';
export const SET_ACTIVE_MANUAL_SYNCHRONIZATION_OF_INVESTIGATED =
  'SET_ACTIVE_MANUAL_SYNCHRONIZATION_OF_INVESTIGATED';
export const SET_ACTIVE_REGISTER_SEMA_AND_CEMA_ASSOCIATIONS =
  'SET_ACTIVE_REGISTER_SEMA_AND_CEMA_ASSOCIATIONS';
export const SET_ACTIVE_AUDIT_LOG_VIEW =
  'SET_ACTIVE_AUDIT_LOG_VIEW';

export const SET_OPEN = 'SET_OPEN';
export const SET_INACTIVE_OPEN = 'SET_INACTIVE_OPEN';
export const SET_OPEN1 = 'SET_OPEN1';
export const SET_INACTIVE_OPEN1 = 'SET_INACTIVE_OPEN1';
export const SET_OPEN2 = 'SET_OPEN2';
export const SET_INACTIVE_OPEN2 = 'SET_INACTIVE_OPEN2';
export const SET_OPEN3 = 'SET_OPEN3';
export const SET_INACTIVE_OPEN3 = 'SET_INACTIVE_OPEN3';
export const SET_OPEN4 = 'SET_OPEN4';
export const SET_INACTIVE_OPEN4 = 'SET_INACTIVE_OPEN4';
export const SET_OPEN5 = 'SET_OPEN5';
export const SET_INACTIVE_OPEN5 = 'SET_INACTIVE_OPEN5';
export const SET_ACTIVE_DECUPLING_ARQUIMEDES_ERROR_REPORT = 'SET_ACTIVE_DECUPLING_ARQUIMEDES_ERROR_REPORT';
export const SET_ACTIVE_INQUIRIES_MIGRATION_REPORT = 'SET_ACTIVE_INQUIRIES_MIGRATION_REPORT';
export const SET_ACTIVE_AI_AUDIT_REPORT = 'SET_ACTIVE_AI_AUDIT_REPORT';
export const SET_ACTIVE_AI_AUDIT_PANDORA_REPORT = 'SET_ACTIVE_AI_AUDIT_PANDORA_REPORT';
export const SET_ACTIVE_USER_AUDIT_REPORT = 'SET_ACTIVE_USER_AUDIT_REPORT';
export const SET_ACTIVE_PROSECUTOR_OFFICE_BALANCE_REPORT = 'SET_ACTIVE_PROSECUTOR_OFFICE_BALANCE_REPORT';
export const SET_SEMA_SERVER_ACTIVITIES = 'SET_SEMA_SERVER_ACTIVITIES';
export const SET_ACTIVE_CVLI_REPORT = 'SET_ACTIVE_CVLI_REPORT';
export const SET_ACTIVE_APFD_CUSTODY_REPORT = 'SET_ACTIVE_APFD_CUSTODY_REPORT';

export const activeConfigSdsReducer = () => ({
  type: SET_CONFIG_SDS,
});

export const setActiveConfigQuery = () => ({
  type: SET_CONFIG_QUERY,
});

export const activeConfigConsensusReducer = () => ({
  type: SET_CONFIG_CONSENSUS,
});

export const navbarLogout = () => ({
  type: NAVBAR_LOGOUT,
});

export const activeInqueritosReducer = () => ({
  type: SET_ACTIVE_INQUERITOS,
});

export const activeInqueritosBuscaReducer = () => ({
  type: SET_ACTIVE_INQUERITOS_BUSCA,
});

export const activeTriagemNaoPersecucao = () => ({
  type: SET_ACTIVE_TRIAGEM,
});

export const activePesquisaDistribuicaoReducer = () => ({
  type: SET_ACTIVE_PESQUISA,
});

export const activeAcordoReducer = () => ({
  type: SET_AGREEMENT,
});

export const activeCumprimentoAcordoReducer = () => ({
  type: SET_FORWARDING_TERM,
});

export const activeAnaliseReducer = () => ({
  type: SET_ACTIVE_ANALISE,
});

export const activeProcessingControllReducer = () => ({
  type: SET_ACTIVE_PROCESSING_CONTROLL,
});

export const activeIntimacaoReducer = () => ({
  type: SET_ACTIVE_INTIMACAO,
});

export const activeVisualizarIntimacaoReducer = () => ({
  type: SET_ACTIVE_VISUALIZAR_INTIMACAO,
});

export const activeVisualizarAudienciaReducer = () => ({
  type: SET_ACTIVE_VISUALIZAR_AUDIENCIA,
});

export const activeVisualizarConfiguracoesIntimacao = () => ({
  type: SET_ACTIVE_CONFIG_INTIMACAO,
});

export const activeVisualizarRegistroUsuario = () => ({
  type: SET_ACTIVE_REGISTER_USER,
});

export const activeVisualizarRegistroPapeis = () => ({
  type: SET_ACTIVE_REGISTER_PAPERS,
});

export const activeVisualizarRegisterServices = () => ({
  type: SET_ACTIVE_REGISTER_SERVICE,
});

export const activeVisualizarConfigurarPapeis = () => ({
  type: SET_ACTIVE_CONFIG_PAPERS,
});

export const activeVisualizarRegistroGrupoOficio = () => ({
  type: SET_ACTIVE_REGISTER_GROUPE_OFFICE,
});

export const activeVisualizarRegistroAssociation = () => ({
  type: SET_ACTIVE_REGISTER_ASSOCIATION,
});

export const activeVisualizarRegistroRequestService = () => ({
  type: SET_ACTIVE_REGISTER_REQUEST_SERVICE,
});

export const setActiveVisualizarEmailDelegacias = () => ({
  type: SET_ACTIVE_EMAIL_DELEGACIAS,
});

export const activeVisualizarRelatorio = () => ({
  type: SET_ACTIVE_REPORT,
});

export const activeVisualizarRelatorioInquerito = () => ({
  type: SET_ACTIVE_REPORT_INQUIRY,
});

export const activeRelatorioCentralInquerito = () => ({
  type: SET_ACTIVE_REPORT_CENTER_INQUIRY,
});

export const setActiveReportDistributionsMove = () => ({
  type: SET_ACTIVE_REPORT_DISTRIBUTIONS_MOVE,
});

export const setActiveReportReturnedProcedures = () => ({
  type: SET_ACTIVE_REPORT_RETURNED_PROCEDURES,
});

export const activeRelatorioApfd = () => ({
  type: SET_ACTIVE_REPORT_APFD,
});

export const activeRelatorioRequisitorioForaDoPrazo = () => ({
  type: SET_ACTIVE_REPORT_OUT_OF_DATE_REQUESTS,
});

export const activeRelatorioPendentesCadastrados = () => ({
  type: SET_ACTIVE_REPORT_PENDING_NONPENDING,
});

export const setActiveReportPendingOrTriage = () => ({
  type: SET_ACTIVE_REPORT_PENDING_OR_TRIAGE,
});

export const setActiveReportInternalAffairs = () => ({
  type: SET_ACTIVE_REPORT_INTERNAL_AFFAIRS,
});

export const setActiveReportPcpeIntegration = () => ({
  type: SET_ACTIVE_REPORT_PCPE_INTEGRATION,
});

export const setActiveProcedureAuditReport = () => ({
  type: SET_ACTIVE_PROCEDURE_AUDIT_REPORT,
});

export const setActiveBaixaDeCarga = () => ({
  type: SET_ACTIVE_BAIXA_DE_CARGA,
});

export const setActiveAddInquerito = () => ({
  type: SET_ACTIVE_REGISTER_INQUIRY,
});

export const setActiveImportInquerito = () => ({
  type: SET_ACTIVE_IMPORT_INQUIRY,
});

export const setActiveConfigLoginGoogleApi = () => ({
  type: SET_ACTIVE_CONFIG_LOGIN_GOOGLE_API,
});

export const setActiveSchedule = () => ({
  type: SET_ACTIVE_SCHEDULE,
});

export const activeBatchDistribution = () => ({
  type: SET_BATCH_DISTRIBUTION,
});

export const activeBatchDevolution = () => ({
  type: SET_BATCH_DEVOLUTION,
});

export const setActiveTypification = () => ({
  type: SET_ACTIVE_TYPIFICATION,
});

export const setActiveRegisterLaw = () => ({
  type: SET_ACTIVE_REGISTER_LAW,
});

export const setActiveRegisterOrgan = () => ({
  type: SET_ACTIVE_REGISTER_ORGAN,
});

export const activeVisualizarRegisterProject = () => ({
  type: SET_ACTIVE_REGISTER_PROJECT,
});

export const setActiveProsecutorLifetime = () => ({
  type: SET_ACTIVE_PROSECUTOR_LIFETIME,
});

export const setActiveRegisterSubject = () => ({
  type: SET_ACTIVE_REGISTER_SUBJECT,
});

export const setActiveRegisterPartnerAssociations = () => ({
  type: SET_ACTIVE_REGISTER_PARTNER_ASSOCIATIONS,
});

export const setActiveRegisterSemaAndCemaAssociations = () => ({
  type: SET_ACTIVE_REGISTER_SEMA_AND_CEMA_ASSOCIATIONS,
});

export const setActiveAuditLogView = () => ({
  type: SET_ACTIVE_AUDIT_LOG_VIEW,
});

export const setActiveManualSynchronizationOfInvestigated = () => ({
  type: SET_ACTIVE_MANUAL_SYNCHRONIZATION_OF_INVESTIGATED,
});

export const activeOpenReducer = () => ({
  type: SET_OPEN,
});

export const inactiveOpenReducer = () => ({
  type: SET_INACTIVE_OPEN,
});

export const activeOpen1Reducer = () => ({
  type: SET_OPEN1,
});

export const inactiveOpen1Reducer = () => ({
  type: SET_INACTIVE_OPEN1,
});

export const activeOpen2Reducer = () => ({
  type: SET_OPEN2,
});

export const inactiveOpen2Reducer = () => ({
  type: SET_INACTIVE_OPEN2,
});

export const activeOpen3Reducer = () => ({
  type: SET_OPEN3,
});

export const inactiveOpen3Reducer = () => ({
  type: SET_INACTIVE_OPEN3,
});

export const activeOpen4Reducer = () => ({
  type: SET_OPEN4,
});

export const inactiveOpen4Reducer = () => ({
  type: SET_INACTIVE_OPEN4,
});

export const activeOpen5Reducer = () => ({
  type: SET_OPEN5,
});

export const inactiveOpen5Reducer = () => ({
  type: SET_INACTIVE_OPEN5,
});

export const setActiveDecuplingArquimedesErrorReport = () => ({
  type: SET_ACTIVE_DECUPLING_ARQUIMEDES_ERROR_REPORT,
});

export const setActiveInquiriesMigrationReport = () => ({
  type: SET_ACTIVE_INQUIRIES_MIGRATION_REPORT,
});

export const setActiveAIAuditReport = () => ({
  type: SET_ACTIVE_AI_AUDIT_REPORT,
});

export const setActiveUserAuditReport = () => ({
  type: SET_ACTIVE_USER_AUDIT_REPORT,
});

export const setActiveProsecutorOfficeBalanceReport = () => ({
  type: SET_ACTIVE_PROSECUTOR_OFFICE_BALANCE_REPORT,
});

export const setActiveSemaServersActivities = () => ({
  type: SET_SEMA_SERVER_ACTIVITIES,
});

export const setActiveCvliReport = () => ({
  type: SET_ACTIVE_CVLI_REPORT
});

export const setActiveApfdCustodyReport = () => ({
  type: SET_ACTIVE_APFD_CUSTODY_REPORT
});

export const setActiveAIAuditPandoraReport = () => ({
  type: SET_ACTIVE_AI_AUDIT_PANDORA_REPORT
});
