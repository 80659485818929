import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Grid, IconButton, Tooltip } from '@material-ui/core';
import axios from '../../utils/axios';
import { useSelector } from 'react-redux';
import ModalSocialEconomicData from './components/ModalSocialEconomicData';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ModalConfirm } from 'components';
import { toast } from 'react-toastify';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Switch } from '@mui/material';

const useStyles = makeStyles(() => ({
  containerSocial: {
    backgroundColor: '#ededed',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    boxShadow: 'none',
  },
  containerBolsaFamilia: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titulo: {
    color: '#00597b',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 20,
    marginBottom: 0,
    marginTop: 5,
    paddingLeft: 10,
    //paddingTop: 5
  },
  tituloCinza: {
    color: 'gray',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 20,
    marginBottom: 0,
    marginTop: 5,
    paddingLeft: 10,
  },
  labelInfo: {
    fontSize: 13,
    color: '#00597b',
  },
  valueProps: {
    fontSize: 13,
    color: '#00597b',
    fontWeight: 'bold',
  },
  containerGrid: {
    justifyContent: 'center',
    alignContent: 'center',
    //padding: 10,
  },
  containerLabel: {
    marginBottom: 5,
  },
  checkboxInativo: {
    cursor: 'pointer',
    color: '#000',
  },
  containerModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
}));

const SocialEconomicData = props => {
  const {
    inquiry,
    refreshPage,
    otherStep,
    read,
    write,
    canEdit = false,
    canSwitchToPersonal = false,
    onClickSwitch,
  } = props;

  const classes = useStyles();

  const [confirm, setConfirm] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);

  const [openModalSocioeconomic, setOpenModalSocioeconomic] = useState(false);
  const [showModalSocioeconomic, setShowModalSocioeconomic] = useState(false);

  const permissionsReducer = useSelector(state => state.logedUser.permissions);

  const handleCloseModalConfirm = () => {
    setConfirm(false);
    setShowModalConfirm(false);
  };

  const handleOpenModalConfirm = () => {
    setShowModalConfirm(true);
    setConfirm(true);
  };

  const handleOpenModalSocioeconomic = () => {
    setShowModalSocioeconomic(true);
    setOpenModalSocioeconomic(true);
  };

  const handleCloseModalSocioEconomic = () => {
    setShowModalSocioeconomic(false);
    setOpenModalSocioeconomic(false);
  };

  const handleSocioeconomicRetry = () => {
    socioeconomicRetry(inquiry.id);
  };

  const socioeconomicRetry = async inquiryInvestigatedId => {
    await axios
      .post(`inquiries/investigated/${inquiryInvestigatedId}/socioeconomic-data/retry`)
      .then(() => {
        //console.log(response);
        toast.success('Solicitação realizada com sucesso!');
        refreshPage();
      });
  };

  const handleOpenSnackbar = (message = 'Você não tem permissão para tomar esta ação.') => {
    return toast.warn(message);
  };

  return (
    <Card className={classes.containerSocial}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography className={classes.titulo}>Dados Socioeconômicos Procob</Typography>
        {canSwitchToPersonal ? <Switch onClick={onClickSwitch}>clica aqui</Switch> : <></>}
        {canSwitchToPersonal ? (
          <Typography className={classes.tituloCinza}>Dados Socioeconômicos Pandora</Typography>
        ) : (
          <></>
        )}
        {inquiry.socioeconomicDataSearchDone ? (
          <IconButton disabled>
            <CheckCircleIcon style={{ color: '#00b1ac' }} />
          </IconButton>
        ) : otherStep && !canEdit ? (
          <CheckCircleIcon />
        ) : write ? (
          <Tooltip title="Clique para atualizar os dados socioeconômicos" arrow placement="left">
            <IconButton
              onClick={() =>
                !permissionsReducer.inquiriesDistribuicao.write
                  ? handleOpenSnackbar()
                  : handleOpenModalConfirm()
              }
            >
              <CheckCircleIcon className={classes.checkboxInativo} />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
      </div>
      <Grid container direction="row" className={classes.containerGrid} spacing={2}>
        <Grid item container direction="column" style={{ paddingLeft: 20 }}>
          <div className={classes.containerLabel}>
            <Typography className={classes.labelInfo}>
              Escolaridade:{' '}
              <span className={classes.valueProps}>
                {inquiry.investigated?.socioeconomicData?.probableSchooling || 'não informado'}
              </span>
            </Typography>
          </div>
          <div className={classes.containerLabel}>
            <Typography className={classes.labelInfo}>
              Experiência profissional:{' '}
              <span className={classes.valueProps}>
                {inquiry.investigated?.socioeconomicData?.professionalInfo
                  ?.professionalExperience === null
                  ? inquiry.investigated?.socioeconomicData?.professionalInfo
                    ?.professionalExperience || 'não informado'
                  : inquiry.investigated?.socioeconomicData?.professionalInfo
                    ?.professionalExperience[0] || 'não informado'}
              </span>
            </Typography>
          </div>
          <div className={classes.containerLabel}>
            <Typography className={classes.labelInfo}>
              Classe Social:{' '}
              <span className={classes.valueProps}>
                {inquiry.investigated?.socioeconomicData?.incomeClass || 'não informado'}
              </span>
            </Typography>
          </div>
          <div className={classes.containerLabel}>
            <Typography className={classes.labelInfo}>
              Renda presumida:{' '}
              <span className={classes.valueProps}>
                {inquiry.investigated?.socioeconomicData?.presumedIncome
                  ? `R$ ${inquiry.investigated?.socioeconomicData?.presumedIncome}`
                  : 'não informado'}
              </span>
            </Typography>
          </div>
          <div className={classes.containerBolsaFamilia}>
            <Typography className={classes.labelInfo}>
              Beneficiário do Bolsa Família:{' '}
              <span className={classes.valueProps}>{'não informado'}</span>
            </Typography>
            {/* <Tooltip
                            arrow
                            title="Realizar busca do Bolsa Família"
                            placement="right"
                        >
                            <IconButton aria-label="findBolsaFamilia">
                                <CheckCircleIcon style={{ color: '#000' }} />
                            </IconButton>
                        </Tooltip> */}
          </div>

          {otherStep && !canEdit ? (
            <div className={classes.containerModal}>
              <IconButton style={{ backgroundColor: '#d7d7d7' }} disabled>
                <ZoomOutMapIcon style={{ color: '#ffffff' }} />
              </IconButton>
            </div>
          ) : (
            <div className={classes.containerModal}>
              <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
                <Grid
                  item
                  spacing={1}
                  container
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Tooltip title="Mais dados socioeconômicos" arrow placement="right">
                    <IconButton
                      onClick={handleOpenModalSocioeconomic}
                      style={{ backgroundColor: '#00b1ac' }}
                    >
                      <ZoomOutMapIcon style={{ color: '#ffffff' }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid
                  item
                  spacing={1}
                  container
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Tooltip
                    title="Baixar dados em pdf"
                    arrow
                    placement="right"
                    style={{ marginLeft: 10 }}
                  >
                    <IconButton style={{ backgroundColor: 'gray' }} disabled>
                      <PictureAsPdfIcon style={{ color: '#ffffff' }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </div>
          )}
        </Grid>
      </Grid>
      {showModalConfirm && (
        <ModalConfirm
          title="Confirmação"
          open={confirm}
          handleClose={handleCloseModalConfirm}
          onClick={handleSocioeconomicRetry}
        >
          <Typography variant="h5">Deseja realmente fazer esta solicitação?</Typography>
        </ModalConfirm>
      )}
      {showModalSocioeconomic && (
        <ModalSocialEconomicData
          inquiry={inquiry}
          refreshPage={refreshPage}
          open={openModalSocioeconomic}
          onClose={handleCloseModalSocioEconomic}
          read={read}
          write={write}
        />
      )}
    </Card>
  );
};

export default SocialEconomicData;
