import {
  federalInquiry,
  genericInquiry,
  noMaskInquiry,
  npuMask,
  pcpeGenericInquiry,
  pmInquiry,
  policeReportMask,
} from 'masks/masks';
import {
  listClassesAtosInfracionais,
  listClassesAtosInfracionaisAlternativas,
  listClassesGenericas,
  listClassesMedidasCautelares,
  listClassesMedidasGarantidoras,
  listClassesMedidasIncidentais,
  listClassesProJudCautelares,
  listClassesProcessosJudiciais,
} from 'utils/constsFormInvestigateds';
import { selectMask } from './selectMask';

export const formatedInquiryNumber = (number = '') => {
  number = clearInquiryNumber(number);
  let array = number.split('');
  let newNumber;
  if (array.length === 16) {
    newNumber = `${array[0]}${array[1]}${array[2]}${array[3]}.${array[4]}${array[5]}${array[6]}${array[7]}.${array[8]}${array[9]}${array[10]}${array[11]}${array[12]}${array[13]}-${array[14]}${array[15]}`;
  } else if (array.length === 20) {
    newNumber = `${array[0]}${array[1]}${array[2]}${array[3]}${array[4]}.${array[5]}${array[6]}${array[7]}${array[8]}.${array[9]}${array[10]}${array[11]}${array[12]}${array[13]}/${array[14]}${array[15]}${array[16]}${array[17]}-${array[18]}.${array[19]}`;
  } else {
    newNumber = number;
  }
  return newNumber;
};

/*
TODO
A formatação da função abaixo está igual a de cima, devido a uma alteração urgente,
solicitada por Gustavo.
Tudo indica que a máscara que está comentada abaixo voltará a ser usada 
em um momento futuro.
*/
export const formatedInquiryNumberAutoDePrisaoEmFlagrante = (number = '') => {
  number = clearInquiryNumber(number);
  let array = number.split('');
  if (array.length === 20) {
    let newNumber = `${array[0]}${array[1]}${array[2]}${array[3]}${array[4]}.${array[5]}${array[6]}${array[7]}${array[8]}.${array[9]}${array[10]}${array[11]}${array[12]}${array[13]}/${array[14]}${array[15]}${array[16]}${array[17]}-${array[18]}.${array[19]}`;
    //let newNumber = `${array[0]}${array[1]}${array[2]}${array[3]}${array[4]}${array[5]}${array[6]}-${array[7]}${array[8]}.${array[9]}${array[10]}${array[11]}${array[12]}.${array[13]}.${array[14]}${array[15]}.${array[16]}${array[17]}${array[18]}${array[19]}`;
    return newNumber;
  }
  return number;
};

export const formatedInquiryNumberNpu = (number = '') => {
  number = clearInquiryNumber(number);
  let array = number.split('');
  if (array.length === 20) {
    let newNumber = `${array[0]}${array[1]}${array[2]}${array[3]}${array[4]}${array[5]}${array[6]}-${array[7]}${array[8]}.${array[9]}${array[10]}${array[11]}${array[12]}.${array[13]}.${array[14]}${array[15]}.${array[16]}${array[17]}${array[18]}${array[19]}`;
    return newNumber;
  }
  return number;
};

export const formatedInquiryNumberPm = (number = '') => {
  number = clearInquiryNumber(number);
  let array = number.split('');
  if (array.length >= 12 && array.length <= 20) {
    let newNumber = `${array[0]}${array[1]}${array[2]}${array[3]}.${array[4]}.${array[5]}.`;
    array.slice(6, array.length).forEach(e => {
      newNumber = newNumber.concat(`${e}`);
    });
    return newNumber;
  }
  return number;
};

export const formatedInquiryNumberPoliceReport = (number = '') => {
  number = clearInquiryNumber(number);
  const array = number.split('');
  if (array.length === 12) {
    const newNumber = `${array[0]}${array[1]}E${array[2]}${array[3]}${array[4]}${array[5]}${array[6]}${array[7]}${array[8]}${array[9]}${array[10]}${array[11]}`;
    return newNumber;
  }
  return number;
};

export const clearInquiryNumber = number => {
  return (number || '').replace(/\D/g, '');
};

const verifyMask = (number = '', mask = '', allOriginNumbersAreNpu) => {
  if (allOriginNumbersAreNpu) {
    return formatedInquiryNumberNpu(number);
  } else {
    if (mask === noMaskInquiry) {
      return number;
    }
    if (mask === genericInquiry || mask === pcpeGenericInquiry) {
      return formatedInquiryNumber(number);
    }
    if (mask === npuMask) {
      return formatedInquiryNumberNpu(number);
    }
    if (mask === federalInquiry) {
      return number;
    }
    if (mask === pmInquiry) {
      return formatedInquiryNumberPm(number);
    }
    if (mask === policeReportMask) {
      return formatedInquiryNumberPoliceReport(number);
    }
  }
};

export const verifyMaskInquiryNumber = (
  policeInquiryNumber = '',
  classeDescription = '',
  allOriginNumbersAreNpu = false,
) => {
  if (classeDescription) {
    const generic = listClassesGenericas.find(classe => classe.description === classeDescription);

    const precautionary = listClassesMedidasCautelares.find(
      classe => classe.description === classeDescription,
    );

    const guarantors = listClassesMedidasGarantidoras.find(
      classe => classe.description === classeDescription,
    );

    const judicialLawsuits = listClassesProcessosJudiciais.find(
      classe => classe.description === classeDescription,
    );

    const judicialLawsuitsPrecautionaries = listClassesProJudCautelares.find(
      classe => classe.description === classeDescription,
    );

    const judicialLawsuitsIncidentals = listClassesMedidasIncidentais.find(
      classe => classe.description === classeDescription,
    );

    const infractions =
      listClassesAtosInfracionais.find(classe => classe.description === classeDescription) ||
      listClassesAtosInfracionaisAlternativas.find(
        classe => classe.description === classeDescription,
      );

    if (generic) {
      return verifyMask(
        policeInquiryNumber,
        selectMask(policeInquiryNumber, generic.masks),
        allOriginNumbersAreNpu,
      );
    }
    if (precautionary) {
      return verifyMask(
        policeInquiryNumber,
        selectMask(policeInquiryNumber, precautionary.masks),
        allOriginNumbersAreNpu,
      );
    }
    if (guarantors) {
      return verifyMask(
        policeInquiryNumber,
        selectMask(policeInquiryNumber, guarantors.masks),
        allOriginNumbersAreNpu,
      );
    }
    if (judicialLawsuits) {
      return verifyMask(
        policeInquiryNumber,
        selectMask(policeInquiryNumber, judicialLawsuits.masks),
        allOriginNumbersAreNpu,
      );
    }

    if (judicialLawsuitsPrecautionaries) {
      return verifyMask(
        policeInquiryNumber,
        selectMask(policeInquiryNumber, judicialLawsuitsPrecautionaries.masks),
        allOriginNumbersAreNpu,
      );
    }

    if (judicialLawsuitsIncidentals) {
      return verifyMask(
        policeInquiryNumber,
        selectMask(policeInquiryNumber, judicialLawsuitsIncidentals.masks),
        allOriginNumbersAreNpu,
      );
    }

    if (infractions) {
      return verifyMask(
        policeInquiryNumber,
        selectMask(policeInquiryNumber, infractions.masks),
        allOriginNumbersAreNpu,
      );
    }
  }
  if (allOriginNumbersAreNpu) {
    return verifyMask(policeInquiryNumber, npuMask, allOriginNumbersAreNpu);
  }

  return policeInquiryNumber;
};
