import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Collapse,
  Typography,
  List,
  ListItem,
  ListItemText,
  Dialog,
  Divider,
  Box,
  ListItemIcon,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ClassIcon from '@material-ui/icons/Class';
import ListIcon from '@material-ui/icons/List';
import CloseIcon from '@material-ui/icons/Close';

import {
  listSubtypeGeneric,
  listDespacho,
  listArquivar,
  listDeclinar,
  listInfanciaEJuventude,
  listArquivarResolucaoCNMP,
} from '../../../../constants/subtypes/subtypes';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h3">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const ModalSubtype = props => {
  const {
    onClose,
    open,
    onClick,
    tasks,
    isInfraction = false,
    ableToHasNotificationOfArchiving = false,
  } = props;

  const classes = useStyles();

  const [openCautelar, setOpenCautelar] = useState(false);
  const [openGarantidora, setOpenGarantidora] = useState(false);
  const [openDespacho, setOpenDepacho] = useState(false);
  const [openInfanciaEJuventude, setOpenInfanciaEJuventude] = useState(false);
  const [openArquivarResolucaoCNMP, setOpenArquivarResolucaoCNMP] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedIndexCautelar, setSelectedIndexCautelar] = useState(null);
  const [selectedIndexGarantidora, setSelectedIndexGarantidora] = useState(null);
  const [selectedIndexDespacho, setSelectedIndexDespacho] = useState(null);
  const [selectedIndexInfanciaEJuventude, setSelectedIndexInfanciaEJuventude] = useState(null);
  const [selectedIndexArquivarResolucaoCNMP, setSelectedIndexArquivarResolucaoCNMP] = useState(
    null,
  );

  const [hasComplaintPjeIntegration, setHasComplaintPjeIntegration] = useState(false);
  const [isComplaintPjeIntegrationResponded, setIsComplaintPjeIntegrationResponded] = useState(
    false,
  );
  const [isComplaintPjeIntegrationVerified, setIsComplaintPjeIntegrationVerified] = useState(false);

  const [disabled, setDisabled] = useState({
    denuncia: false,
    arquivar: false,
    declinar: false,
    despacho: false,
    infanciaEJuventude: false,
    arquivarResolucaoCNMP: false,
  });

  const handleClose = () => {
    onClose();
    clearStatesSelecteds();
  };

  const clearStatesSelecteds = () => {
    setSelectedIndex(null);
    setSelectedIndexCautelar(null);
    setSelectedIndexGarantidora(null);
    setSelectedIndexDespacho(null);
    setSelectedIndexInfanciaEJuventude(null);
    setSelectedIndexArquivarResolucaoCNMP(null);
    setOpenCautelar(false);
    setOpenGarantidora(false);
    setOpenDepacho(false);
    setOpenInfanciaEJuventude(false);
    setOpenArquivarResolucaoCNMP(false);
  };

  const handleOpenCautelar = () => {
    setOpenCautelar(!openCautelar);
    setOpenGarantidora(false);
    setOpenDepacho(false);
    setOpenInfanciaEJuventude(false);
    setOpenArquivarResolucaoCNMP(false);
  };

  const handleOpenGarantidora = () => {
    setOpenGarantidora(!openGarantidora);
    setOpenCautelar(false);
    setOpenDepacho(false);
    setOpenInfanciaEJuventude(false);
    setOpenArquivarResolucaoCNMP(false);
  };

  const handleOpenDespacho = () => {
    setOpenDepacho(!openDespacho);
    setOpenGarantidora(false);
    setOpenCautelar(false);
    setOpenInfanciaEJuventude(false);
    setOpenArquivarResolucaoCNMP(false);
  };

  const handleOpenInfanciaEJuventude = () => {
    setOpenInfanciaEJuventude(!openInfanciaEJuventude);
    setOpenDepacho(false);
    setOpenGarantidora(false);
    setOpenCautelar(false);
    setOpenArquivarResolucaoCNMP(false);
  };

  const handleOpenArquivarResolucaoCNMP = () => {
    setOpenArquivarResolucaoCNMP(!openArquivarResolucaoCNMP);
    setOpenDepacho(false);
    setOpenGarantidora(false);
    setOpenCautelar(false);
    setOpenInfanciaEJuventude(false);
  };

  useEffect(() => {
    let arrayTypes = [];
    tasks.forEach(task => {
      if (task.isActive) {
        arrayTypes.push(task.type);
      }
    });
    verifyTypeTask(arrayTypes);
  }, [tasks]);

  const verifyTypeTask = array => {
    if (array.find(task => task === 'DENUNCIA')) {
      setDisabled({
        denuncia: true,
        arquivar: true,
        declinar: true,
        despacho: false,
        infanciaEJuventude: true,
        arquivarResolucaoCNMP: true,
      });
    } else if (array.find(task => task === 'ARQUIVAR')) {
      setDisabled({
        denuncia: true,
        arquivar: true,
        declinar: true,
        despacho: false,
        infanciaEJuventude: true,
        arquivarResolucaoCNMP: true,
      });
    } else if (array.find(task => task === 'DECLINAR')) {
      setDisabled({
        denuncia: true,
        arquivar: true,
        declinar: true,
        despacho: false,
        infanciaEJuventude: true,
        arquivarResolucaoCNMP: true,
      });
    } else if (array.find(task => task === 'ENCAMINHAR_DILIGENCIAS')) {
      setDisabled({
        denuncia: false,
        arquivar: false,
        declinar: false,
        despacho: false,
        infanciaEJuventude: false,
        arquivarResolucaoCNMP: false,
      });
    } else if (array.find(task => task === 'INFANCIA_E_JUVENTUDE')) {
      setDisabled({
        denuncia: true,
        arquivar: true,
        declinar: true,
        despacho: false,
        infanciaEJuventude: true,
        arquivarResolucaoCNMP: true,
      });
    } else if (array.find(task => task === 'ARQUIVAR_RESOLUCAO_CNMP')) {
      setDisabled({
        denuncia: true,
        arquivar: true,
        declinar: true,
        despacho: false,
        infanciaEJuventude: true,
        arquivarResolucaoCNMP: true,
      });
    } else {
      setDisabled({
        denuncia: false,
        arquivar: false,
        declinar: false,
        despacho: false,
        infanciaEJuventude: false,
        arquivarResolucaoCNMP: false,
      });
    }
  };

  const handleClick = (event, subtype, index, list) => {
    let type = '';
    switch (list) {
      case '0':
        setSelectedIndex(index);
        setSelectedIndexCautelar(null);
        setSelectedIndexGarantidora(null);
        setSelectedIndexDespacho(null);
        setSelectedIndexInfanciaEJuventude(null);
        setSelectedIndexArquivarResolucaoCNMP(null);
        type = 'DENUNCIA';
        break;
      case '1':
        setSelectedIndex(null);
        setSelectedIndexCautelar(index);
        setSelectedIndexGarantidora(null);
        setSelectedIndexDespacho(null);
        setSelectedIndexInfanciaEJuventude(null);
        setSelectedIndexArquivarResolucaoCNMP(null);
        type = 'ARQUIVAR';
        break;
      case '2':
        setSelectedIndex(null);
        setSelectedIndexCautelar(null);
        setSelectedIndexGarantidora(null);
        setSelectedIndexDespacho(null);
        setSelectedIndexInfanciaEJuventude(null);
        setSelectedIndexArquivarResolucaoCNMP(index);
        type = 'ARQUIVAR_RESOLUCAO_CNMP';
        break;
      case '3':
        setSelectedIndex(null);
        setSelectedIndexCautelar(null);
        setSelectedIndexGarantidora(index);
        setSelectedIndexDespacho(null);
        setSelectedIndexInfanciaEJuventude(null);
        setSelectedIndexArquivarResolucaoCNMP(null);
        type = 'DECLINAR';
        break;
      case '4':
        setSelectedIndex(null);
        setSelectedIndexCautelar(null);
        setSelectedIndexGarantidora(null);
        setSelectedIndexDespacho(index);
        setSelectedIndexInfanciaEJuventude(null);
        setSelectedIndexArquivarResolucaoCNMP(null);
        type = 'ENCAMINHAR_DILIGENCIAS';
        break;
      case '5':
        setSelectedIndex(null);
        setSelectedIndexCautelar(null);
        setSelectedIndexGarantidora(null);
        setSelectedIndexDespacho(null);
        setSelectedIndexInfanciaEJuventude(index);
        setSelectedIndexArquivarResolucaoCNMP(null);
        type = 'INFANCIA_E_JUVENTUDE';
        break;
      default:
        break;
    }
    onClick(event, subtype, type);
    handleClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth={'sm'}
    >
      <DialogTitle onClose={handleClose} id="simple-dialog-title">
        Subtipos do {isInfraction ? 'Procedimento de Menor' : 'Inquérito'}
      </DialogTitle>
      <Divider />
      <List component="nav">
        {listSubtypeGeneric.map((subtype, index) => (
          <Box key={subtype.code}>
            <ListItem
              selected={selectedIndex === index}
              disabled={disabled.denuncia}
              button
              onClick={event => handleClick(event, subtype, index, '0')}
            >
              <ListItemIcon>
                <ClassIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={subtype.name} />
            </ListItem>
            <Divider />
          </Box>
        ))}
        <ListItem button onClick={handleOpenCautelar} disabled={disabled.arquivar}>
          <ListItemIcon>
            <ListIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Arquivar" />
          {openCautelar ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider />
        <Collapse in={openCautelar} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {listArquivar.map((subtype, index) => (
              <Box key={subtype.code}>
                <ListItem
                  selected={selectedIndexCautelar === index}
                  button
                  className={classes.nested}
                  onClick={event => handleClick(event, subtype, index, '1')}
                  disabled={
                    subtype.name === 'Arquivamento Interno para Infância e Juventude' && !isInfraction
                  }
                >
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={subtype.name} />
                </ListItem>
                <Divider />
              </Box>
            ))}
          </List>
        </Collapse>
        <ListItem
          button
          onClick={handleOpenArquivarResolucaoCNMP}
          disabled={disabled.arquivarResolucaoCNMP}
        >
          <ListItemIcon>
            <ListIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Arquivar Conforme Resolução CNMP Nº 289/2024" />
          {openArquivarResolucaoCNMP ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider />
        <Collapse in={openArquivarResolucaoCNMP} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {listArquivarResolucaoCNMP.map((subtype, index) => (
              <Box key={subtype.code}>
                <ListItem
                  selected={selectedIndexArquivarResolucaoCNMP === index}
                  button
                  className={classes.nested}
                  onClick={event => handleClick(event, subtype, index, '2')}
                  disabled={
                    subtype.name === 'Ciência de arquivamento' && !ableToHasNotificationOfArchiving
                  }
                >
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={subtype.name} />
                </ListItem>
                <Divider />
              </Box>
            ))}
          </List>
        </Collapse>
        <ListItem button onClick={handleOpenGarantidora} disabled={disabled.declinar}>
          <ListItemIcon>
            <ListIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Declinar" />
          {openGarantidora ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider />
        <Collapse in={openGarantidora} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {listDeclinar.map((subtype, index) => (
              <Box key={subtype.code}>
                <ListItem
                  selected={selectedIndexGarantidora === index}
                  button
                  className={classes.nested}
                  onClick={event => handleClick(event, subtype, index, '3')}
                >
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={subtype.name} />
                </ListItem>
                <Divider />
              </Box>
            ))}
          </List>
        </Collapse>
        <ListItem button onClick={handleOpenDespacho} disabled={disabled.despacho}>
          <ListItemIcon>
            <ListIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Despacho" />
          {openDespacho ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider />
        <Collapse in={openDespacho} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {listDespacho.map((subtype, index) => (
              <Box key={subtype.code}>
                <ListItem
                  selected={selectedIndexDespacho === index}
                  button
                  className={classes.nested}
                  onClick={event => handleClick(event, subtype, index, '4')}
                >
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={subtype.name} />
                </ListItem>
                <Divider />
              </Box>
            ))}
          </List>
        </Collapse>
        <ListItem
          button
          onClick={handleOpenInfanciaEJuventude}
          disabled={disabled.infanciaEJuventude}
        >
          <ListItemIcon>
            <ListIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Infância e Juventude" />
          {openInfanciaEJuventude ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider />
        <Collapse in={openInfanciaEJuventude} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {listInfanciaEJuventude.map((subtype, index) => (
              <Box key={subtype.code}>
                <ListItem
                  selected={selectedIndexInfanciaEJuventude === index}
                  button
                  className={classes.nested}
                  onClick={event => handleClick(event, subtype, index, '5')}
                >
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={subtype.name} />
                </ListItem>
              </Box>
            ))}
          </List>
        </Collapse>
      </List>
    </Dialog>
  );
};

export default ModalSubtype;
