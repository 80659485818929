import React, { useState, useCallback, useEffect } from 'react';
import { AutoCompleteConfig } from 'constants/autocomplete';
import { Autocomplete } from '@material-ui/lab';
import debounce from 'lodash/debounce';

const LazyAutocomplete = ({
  fetchOptions,
  label,
  value,
  onChange,
  getOptionLabel,
  renderOption,
  name,
  inputClassName,
  defaultOption,
  renderInput,
  firstLoad = false,
  enablePagination = false,
  refreshKey,
  disableRelativePosition = false,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(defaultOption ? [defaultOption] : []);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const loadOptions = useCallback(
    debounce(async (search, pageNumber = 1, append = false) => {
      if (loading) return;
      setLoading(true);
      try {
        let response;

        if (enablePagination) {
          response = await fetchOptions(search, pageNumber);
          setLastPage(response.lastPage);
        } else {
          response = await fetchOptions(search);
        }

        const data = enablePagination ? response.data : response;

        setOptions(prevOptions => {
          if (enablePagination && append) {
            return defaultOption && pageNumber === 1
              ? [defaultOption, ...prevOptions, ...data]
              : [...prevOptions, ...data];
          }
          return defaultOption ? [defaultOption, ...data] : data;
        });
      } catch (error) {
        console.error('Error fetching options:', error);
        setOptions(defaultOption ? [defaultOption] : []);
      } finally {
        setLoading(false);
      }
    }, 500),
    [fetchOptions, defaultOption, enablePagination],
  );

  useEffect(() => {
    if (firstLoad) {
      loadOptions('', 1);
    }
  }, [firstLoad]);

  useEffect(() => {
    if (refreshKey && open) {
      setPage(1);
      loadOptions(inputValue, 1);
    }
  }, [refreshKey, open, loadOptions, inputValue]);

  const handleScroll = event => {
    if (!enablePagination) return;

    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (listboxNode.scrollHeight - position <= 1 && page < lastPage && !loading) {
      const nextPage = page + 1;
      setPage(nextPage);
      loadOptions(inputValue, nextPage, true);
    }
  };

  const handleOpen = () => {
    setOpen(true);
    if (options.length === (defaultOption ? 1 : 0)) {
      setPage(1);
      loadOptions(inputValue, 1);
    }
  };

  const handleInputChange = (_event, newInputValue, reason) => {
    if (reason === 'input') {
      setInputValue(newInputValue);
      setPage(1);
      if (open) {
        loadOptions(newInputValue, 1);
      }
    }
  };

  const handleChange = (event, newValue) => {
    onChange(event, newValue);
    if (!newValue) {
      setPage(1);
      loadOptions('', 1);
    }
  };

  return disableRelativePosition ? (
    <Autocomplete
      open={open}
      onOpen={handleOpen}
      onClose={() => setOpen(false)}
      options={options}
      loading={loading}
      value={value}
      onChange={handleChange}
      onInputChange={handleInputChange}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      noOptionsText={AutoCompleteConfig.NO_OPTION_TEXT}
      loadingText={AutoCompleteConfig.LOADING_TEXT}
      ListboxProps={{
        onScroll: handleScroll,
      }}
      {...props}
      renderInput={renderInput}
    />
  ) : (
    <div style={{ position: 'relative' }}>
      <Autocomplete
        open={open}
        onOpen={handleOpen}
        onClose={() => setOpen(false)}
        options={options}
        loading={loading}
        value={value}
        onChange={handleChange}
        onInputChange={handleInputChange}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        noOptionsText={AutoCompleteConfig.NO_OPTION_TEXT}
        loadingText={AutoCompleteConfig.LOADING_TEXT}
        ListboxProps={{
          onScroll: handleScroll,
        }}
        {...props}
        renderInput={renderInput}
      />
    </div>
  );
};

export default LazyAutocomplete;
