export const NpuTypesTreated = [
    { 
        label: 'Ação rescisória',
        value: 'AR'
    },
    { 
        label: 'Competência delegada',
        value: 'CD'
    },
    { 
        label: 'Continência',
        value: 'CT'
    },
    { 
        label: 'Dependência',
        value: 'DP'
    },
    { 
        label: 'Conexão',
        value: 'CX'
    },
    { 
        label: 'Recurso repetitivo', 
        value: 'RR'
    },
    { 
        label: 'Repercussão geral', 
        value: 'RG'
    },
    { 
        label: 'Outro tipo de associação que não os anteriores',
        value: 'OR'
    }
];

export const NpuTypes = { 
    AR: 'AR',
    CD: 'CD',
    CT: 'CT',
    DP: 'DP',
    CX: 'CX',
    RR: 'RR',
    RG: 'RG',
    OR: 'OR'
}


  