import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Box,
  TextareaAutosize,
  Typography,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { InputConsensus, ButtonConsensus, KeyboardDatePicker, ModalConfirm } from 'components';
import { colors } from 'utils/colorsSteps';
import { toast } from 'react-toastify';
import { formatDateToISO8601, formatDateToBrazilianMonthYear } from 'utils/date-time.utils';
import moment from 'moment';
import { parcelsHoursSum } from 'views/Agreement/util';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import { PARTNER_SITUATIONS } from 'constants/partner-situations';
import { initialDate } from 'utils/DatesInMonth';
import { PENALTY_KINDS } from 'constants/agreement/penaltyKinds';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import LazyAutocomplete from 'components/LazyAutocomplete';
import projectService from 'services/project/projectService';
import associationCommunityService from 'services/association-community/associationCommunityService';
import communityService from 'services/community-service/communityService';
import { AutoCompleteConfig } from 'constants/autocomplete';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  containerForm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    margin: theme.spacing(2, 0, 2, 0),
    width: '100%',
  },
  containerParcels: {
    padding: theme.spacing(2),
    backgroundColor: '#ececec',
    borderRadius: '0.7rem',
    margin: theme.spacing(0, 0.8, 1, 0.8),
  },
}));

const CommunityServices = props => {
  const {
    setDisabledActionsPenaltyTable = {},
    selectedIndex = null,
    setSelectedIndex = {},
    services,
    setServices,
    description,
    setDescription,
    institutionType,
    institutionSelected,
    judicialDistrictId,
  } = props;

  const { isMPDFT } = useCustomerConfig();

  const classes = useStyles();

  const [row, setRow] = useState(null);
  const [key, setKey] = useState(false);
  const [key2, setKey2] = useState(false);

  const [projectOptions, setProjectOptions] = useState([]);
  const [associationOptions, setAssociationOptions] = useState([]);

  const [openDeleteParcelModalConfirm, setOpenDeleteParcelModalConfirm] = useState(false);
  const [parcelToDelete, setParcelToDelete] = useState(null);

  const initialValuesCompareHours = {
    hours: 0,
    attendedHours: 0,
    remainingHours: 0,
  };

  const [compareHours, setCompareHours] = useState({ ...initialValuesCompareHours });

  const [isAddingParcel, setIsAddingParcel] = useState(false);
  const [newParcel, setNewParcel] = useState({
    inicialDate: null,
    hours: '',
    inicialDateString: '',
  });

  const handleToggleAddParcel = () => {
    setIsAddingParcel(!isAddingParcel);
    setNewParcel({ inicialDate: '', hours: '' });
  };

  const initialValues = {
    relation: null,
    service: null,
    penalty: PENALTY_KINDS.SERVICES,
    hours: '',
    description: '',
    descriptionLocal: isMPDFT ? '' : description,
    inicialDate: null,
    inicialDateString: '',
    parcels: [],
    numberOfParcels: 1,
    project: null,
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: { ...initialValues },
    touched: {},
    errors: {},
  });

  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      values:
        selectedIndex !== null
          ? {
              ...services[selectedIndex.index],
              descriptionLocal: isMPDFT ? services[selectedIndex.index].description : description,
            }
          : { ...initialValues },
    }));
  }, [selectedIndex]);

  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        descriptionLocal: description,
      },
    }));
  }, [description]);

  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        project: null,
        relation: null,
      },
      touched: {
        ...formState.touched,
        relation: true,
      },
    }));
  }, [institutionSelected]);

  const fetchServices = async () => {
    const params = {
      hasDemand: true,
    };

    const result = await communityService.getCommunityService(params);

    return {
      data: result.data.data,
    };
  };

  const handleInputKeyPress = event => {
    if (event.key === ',' || event.key === '.') {
      event.preventDefault();
    }
  };

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
    if (event.target.name === 'descriptionLocal') {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          description: event.target.value,
        },
      }));
    }
  };

  const handleChangeAssociation = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        project: null,
        relation: newValue,
      },
      touched: {
        ...formState.touched,
        relation: true,
      },
    }));

    setCompareHours(compare => ({
      ...compare,
      hours: newValue ? newValue.hours : 0,
      attendedHours: newValue ? newValue.attendedHours : 0,
      remainingHours: newValue ? newValue.remainingHours : 0,
    }));

    setKey(!key);
    setProjectOptions([]);
  };

  const handleChangeService = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        service: newValue,
        project: null,
        relation: {
          ...formState.values.relation,
          association: null,
        },
      },
      touched: {
        ...formState.touched,
        service: true,
      },
    }));
    setKey2(!key2);
    setProjectOptions([]);
    setAssociationOptions([]);
  };

  const handleClearFormStat = () => {
    setRow(null);
    if (selectedIndex !== null) {
      setDisabledActionsPenaltyTable(prevState => ({
        ...prevState,
        [selectedIndex.penaltiesIndex]: { ...prevState[selectedIndex.penaltiesIndex], edit: false },
      }));
      setSelectedIndex(null);
    }
    setFormState(formState => ({
      ...formState,
      values: { ...initialValues },
    }));
    setProjectOptions([]);
    setAssociationOptions([]);
  };

  useEffect(() => {
    if (row !== null && row !== undefined) {
      setFormState(formState => ({
        ...formState,
        values: { ...row, descriptionLocal: isMPDFT ? row.description : description },
      }));
      setKey2(!key2);
      setKey(!key);
    }
  }, [row]);

  const handleSubmit = event => {
    event.preventDefault();

    const { relation, service, hours, description, descriptionLocal, parcels } = formState.values;

    if (
      !service ||
      (isMPDFT ? description === '' : descriptionLocal === '') ||
      parcels.length === 0 ||
      parcels.some(parcel => !parcel.hours) ||
      (institutionType === 'final' && !relation?.association)
    ) {
      toast.warn('Preencha todos os campos');
      return;
    }

    let intHours = parseInt(hours);

    if (selectedIndex !== null) {
      let newArray = [...services];
      let newMaterials = formState.values;
      setDescription(newMaterials.descriptionLocal);
      delete newMaterials.descriptionLocal;
      newArray[selectedIndex.index] = newMaterials;
      setServices(newArray);
    } else {
      let newMaterials = formState.values;
      setDescription(newMaterials.descriptionLocal);
      delete newMaterials.descriptionLocal;
      setServices([...services, newMaterials]);
    }

    handleClearFormStat();
  };

  const handleToRead = () => {
    handleClearFormStat();
  };

  const handleAddNewParcel = () => {
    const { inicialDateString, hours } = newParcel;

    if (!hours) {
      toast.warn('Insira a quantidade de horas');
      return;
    }

    let date;
    if (inicialDateString) {
      const brFormatDate = `01/${inicialDateString}`;
      const globalFormatDate = brFormatDate.split('/');
      date = moment(`${globalFormatDate[2]}-${globalFormatDate[1]}-${globalFormatDate[0]}`);
    }

    const parcel = {
      date: date && formatDateToISO8601(date),
      hours: hours,
    };

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        parcels: [...formState.values.parcels, parcel],
        inicialDate: '',
        hours: '',
      },
    }));

    setIsAddingParcel(false);
  };

  const handleAddParcel = () => {
    const { inicialDateString, hours, numberOfParcels } = formState.values;

    if (!hours) {
      toast.warn('Insira um valor');
      return;
    }
    if (!numberOfParcels) {
      toast.warn('Insira a quantidade de parcelas');
      return;
    }

    const newParcels = [];

    let date;
    if (inicialDateString) {
      const brFormatDate = `01/${inicialDateString}`;
      const globalFormatDate = brFormatDate.split('/');
      date = moment(`${globalFormatDate[2]}-${globalFormatDate[1]}-${globalFormatDate[0]}`);
    }

    for (let i = 0; i < numberOfParcels; i++) {
      newParcels.push({
        date: date && formatDateToISO8601(date.clone().add(i, 'months')),
        hours: hours,
      });
    }

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        parcels: newParcels,
        inicialDate: '',
        hours: '',
      },
    }));
  };

  const handleOpenDeleteParcelModalConfirm = parcel => {
    setOpenDeleteParcelModalConfirm(true);
    setParcelToDelete(parcel);
  };

  const handleCloseDeleteParcelModalConfirm = () => {
    setOpenDeleteParcelModalConfirm(false);
    setParcelToDelete(null);
  };

  const deleteParcel = () => {
    const parcel = parcelToDelete;
    const index = formState.values.parcels.findIndex(item => item.date === parcel.date);
    if (index !== -1) {
      const newParcels = formState.values.parcels.filter(
        (value, _index, _arr) => value.date !== parcel.date,
      );
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          parcels: newParcels,
        },
      }));
    }
  };

  const orderParcelsByDate = parcels => {
    return parcels.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
  };

  const handleChangeProject = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        project: newValue,
      },
    }));
  };

  const handleParcelDateChange = (index, newDate) => {
    const updatedParcels = formState.values.parcels.map((parcel, idx) =>
      idx === index ? { ...parcel, date: newDate } : parcel,
    );
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        parcels: updatedParcels,
      },
    });
  };

  const handleParcelHoursChange = (index, hours) => {
    const updatedParcels = formState.values.parcels.map((parcel, idx) =>
      idx === index ? { ...parcel, hours } : parcel,
    );
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        parcels: updatedParcels,
      },
    });
  };

  const fetchAssociations = useCallback(
    async (search, page) => {
      if (formState.values.service?.id) {
        const params = {
          active: true,
          forward: false,
          includeAssociationsWithNullSituation: true,
          judicialDistrictId: judicialDistrictId,
          search,
          page,
          size: AutoCompleteConfig.PAGINATION_SIZE,
        };

        if (isMPDFT) {
          delete params.includeAssociationsWithNullSituation;
          params.situation = PARTNER_SITUATIONS.ACCREDITED;
          delete params.judicialDistrictId;
        }

        if (formState.values.service?.id) {
          params.communityId = formState.values.service.id;
        }

        const result = await associationCommunityService.getAssociationCommunity(params);
        setAssociationOptions(result.data.data);
      }
    },
    [formState.values.service?.id],
  );

  const fetchProjects = useCallback(
    async (search, page) => {
      if (formState.values.relation?.association?.id) {
        const params = {
          associationId: formState.values.relation?.association?.id,
          onlyActiveProjects: true,
          search,
          page,
          size: AutoCompleteConfig.PAGINATION_SIZE,
        };

        const result = await projectService.getProjectsService(params);
        setProjectOptions(result.data.data);
      }
    },
    [formState.values.relation?.association?.id],
  );

  useEffect(() => {
    setFormState(formState => {
      return {
        ...formState,
        values: {
          ...formState.values,
          project: formState.values.project,
        },
      };
    });
  }, [formState.values.relation]);

  return (
    <Box className={classes.container}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} className={classes.containerForm}>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <LazyAutocomplete
              autoHighlight
              onChange={handleChangeService}
              value={formState.values.service}
              renderOption={option => <>{option.name}</>}
              fetchOptions={fetchServices}
              getOptionLabel={option => option.name || ''}
              enablePagination
              renderInput={params => (
                <InputConsensus
                  {...params}
                  name="service"
                  label="Serviço"
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.InputProps.loading ? (
                          <CircularProgress color="primary" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            {formState.values.service && institutionType === 'final' && (
              <LazyAutocomplete
                value={formState.values.relation}
                getOptionLabel={option => option.association?.name}
                onChange={handleChangeAssociation}
                renderOption={option => <>{option.association?.name}</>}
                fetchOptions={fetchAssociations}
                enablePagination
                options={associationOptions}
                renderInput={params => (
                  <InputConsensus
                    {...params}
                    name="relation"
                    label="Instituição"
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.loading ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            )}
          </Grid>
          <Grid
            item
            container
            spacing={1}
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ paddingLeft: 5, paddingRight: 5 }}
          >
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              {institutionType === 'final' && formState.values.relation && (
                <Box marginBottom={3}>
                  <LazyAutocomplete
                    value={formState.values.project}
                    getOptionLabel={option => option.name}
                    onChange={handleChangeProject}
                    renderOption={option => <>{option.name}</>}
                    fetchOptions={fetchProjects}
                    options={projectOptions}
                    enablePagination
                    renderInput={params => (
                      <InputConsensus
                        {...params}
                        name="project"
                        label="Projeto"
                        variant="standard"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.loading ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Box>
              )}
            </Grid>

            <Grid
              item
              container
              spacing={2}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={classes.containerParcels}
            >
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography align="left" variant="h5" className={classes.form}>
                    Parcelas
                  </Typography>
                  {formState.values.parcels.length > 0 && isMPDFT && !isAddingParcel && (
                    <Tooltip title="Adicionar parcela" arrow placement="top">
                      <IconButton onClick={handleToggleAddParcel}>
                        <AddIcon style={{ color: colors.primary }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </Grid>

              {isAddingParcel && (
                <>
                  <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                    <KeyboardDatePicker
                      placeholder="mm/aaaa"
                      format="MM/yyyy"
                      onlyMonthYear
                      variant="inline"
                      name="newParcelInicialDate"
                      label="Data"
                      value={newParcel.inicialDate || null}
                      onChange={newDate =>
                        setNewParcel({
                          ...newParcel,
                          inicialDate: newDate,
                          inicialDateString: formatDateToBrazilianMonthYear(newDate),
                        })
                      }
                    />
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                    <InputConsensus
                      label="Horas *"
                      name="newParcelHours"
                      variant="standard"
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      value={newParcel.hours}
                      onChange={e => setNewParcel({ ...newParcel, hours: e.target.value })}
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={12}
                    style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '30px' }}
                  >
                    <Tooltip title="Adicionar parcela" arrow placement="top">
                      <IconButton onClick={handleAddNewParcel}>
                        <AddIcon style={{ color: colors.primary }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Cancelar" arrow placement="top">
                      <IconButton onClick={handleToggleAddParcel} color="secondary">
                        <ClearIcon style={{ color: '#e1296a' }} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </>
              )}

              {formState.values.parcels.length === 0 && (
                <>
                  <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                    <KeyboardDatePicker
                      placeholder="mm/aaaa"
                      format="MM/yyyy"
                      onlyMonthYear
                      variant="inline"
                      name="inicialDate"
                      label="Data inicial"
                      value={formState.values.inicialDate || null}
                      minDate={!selectedIndex ? initialDate() : undefined}
                      onChange={newDate =>
                        setFormState(formState => ({
                          ...formState,
                          values: {
                            ...formState.values,
                            inicialDate: newDate,
                            inicialDateString: formatDateToBrazilianMonthYear(newDate),
                          },
                        }))
                      }
                    />
                  </Grid>
                  <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                    <InputConsensus
                      label="Horas por mês*"
                      name="hours"
                      variant="standard"
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      onKeyPress={handleInputKeyPress}
                      onChange={handleChange}
                      value={formState.values.hours || ''}
                      fullWidth
                    />
                  </Grid>
                </>
              )}

              {formState.values.parcels && formState.values.parcels.length === 0 && (
                <>
                  <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
                    <InputConsensus
                      label="Número de meses*"
                      name="numberOfParcels"
                      variant="standard"
                      type="number"
                      inputProps={{
                        min: 1,
                      }}
                      onKeyPress={handleInputKeyPress}
                      onChange={handleChange}
                      value={formState.values.numberOfParcels || 1}
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <ButtonConsensus
                      title="Gerar Parcelas"
                      backgroundColor={colors.primary}
                      hovercolor={colors.primary}
                      onClick={() => handleAddParcel()}
                      size="medium"
                    />
                  </Grid>
                </>
              )}

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Data</TableCell>
                        <TableCell align="center">Horas</TableCell>
                        <TableCell align="center" width={150}>
                          <Box display="flex" position="relative" justifyContent="center">
                            Ações
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderParcelsByDate(formState.values.parcels).map((item, index) => (
                        <TableRow key={index} hover>
                          <TableCell component="th" scope="row" align="center">
                            <KeyboardDatePicker
                              placeholder="mm/aaaa"
                              format="MM/yyyy"
                              onlyMonthYear
                              variant="inline"
                              value={item.date || null}
                              onChange={newDate => handleParcelDateChange(index, newDate)}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            <InputConsensus
                              name="hours"
                              variant="standard"
                              type="number"
                              inputProps={{
                                min: 0,
                              }}
                              onKeyPress={handleInputKeyPress}
                              onChange={e => handleParcelHoursChange(index, e.target.value)}
                              value={item.hours || ''}
                              fullWidth
                            />
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              color="primary"
                              size="small"
                              onClick={() => handleOpenDeleteParcelModalConfirm(item)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell align="left" colSpan={3} style={{ fontWeight: 'bold' }}>
                          Total: {parcelsHoursSum(formState.values.parcels)}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={5} lg={5} md={5} sm={5} xs={12} />
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextareaAutosize
              style={{ width: '100%', padding: 5, resize: 'none' }}
              maxRows={4}
              minRows={4}
              aria-label="maximum height"
              placeholder="Descrever como será cumprido"
              onChange={handleChange}
              name="descriptionLocal"
              value={isMPDFT ? formState.values.description : formState.values.descriptionLocal}
            />
          </Grid>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <ButtonConsensus
              title={`${selectedIndex !== null ? 'Alterar' : 'Incluir'} ${
                isMPDFT ? 'Medida' : 'Penalidade'
              }`}
              backgroundColor={colors.primary}
              hovercolor={colors.primary}
              type="submit"
              size="medium"
            />
            <ButtonConsensus
              title={`${selectedIndex !== null ? 'Cancelar' : 'Limpar'}`}
              backgroundColor={colors.primary}
              hovercolor={colors.primary}
              onClick={() => handleToRead()}
              size="medium"
              style={{ marginLeft: '-6px' }}
            />
          </Grid>
        </Grid>
      </form>
      {openDeleteParcelModalConfirm && (
        <ModalConfirm
          open={openDeleteParcelModalConfirm}
          handleClose={handleCloseDeleteParcelModalConfirm}
          onClick={deleteParcel}
          title="Excluir parcela"
        />
      )}
    </Box>
  );
};

export default CommunityServices;
