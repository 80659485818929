/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import {
  Typography,
  Grid,
  Card,
  IconButton,
  Box,
  Chip,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import { CustomerDisplayValidator, InputConsensus } from 'components';
import { Autocomplete } from '@material-ui/lab';
import { toast } from 'react-toastify';
import inquiryService from 'services/inquiries/inquiryService';
import { useUserTypes } from 'hooks/useUserTypes';
import { CUSTOMER_CONFIG as CC } from 'constants/customerConfig';
import crimeSubjectService from 'services/crime-subject/crimeSubjectService';
import PmService from 'services/PM/pmService';
import LazyAutocomplete from '../LazyAutocomplete';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const CrimeSubjectsDetailInquiry = props => {
  const {
    subjects = [],
    inquiryId,
    refreshPage,
    write = false,
    otherStep = false,
    canEdit = false,
  } = props;

  const useStyles = makeStyles(() => ({
    root: {
      marginTop: 15,
    },
    titulo: {
      color: '#00597b',
      fontWeight: 'bold',
      textAlign: 'left',
      fontSize: 20,
      fontFamily: 'roboto-regular',
    },
    containerLabelInputCrime: {
      padding: 5,
    },
    containerLabelInput: {
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 10,
    },
    selectForm: {
      height: 25,
      outline: 0,
      width: '100%',
      border: 0,
      borderBottom: `1px solid #000`,
      backgroundColor: '#ededed',
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    },
    form: {
      heigth: '100%',
      width: '100%',
      padding: '0 10px 0 10px',
    },
    labelForm: {
      display: 'flex',
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
      fontSize: 11,
    },
    labelFormCrime: {
      display: 'flex',
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
      fontSize: 11,
      color: '#00597b',
    },
    inputForm: {
      display: 'block',
      height: 25,
      outline: 0,
      padding: 5,
      width: '100%',
      border: 0,
      borderBottom: `1px solid #000`,
      backgroundColor: 'transparent',
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    },
    inputFormCrime: {
      display: 'block',
      height: 20,
      outline: 0,
      width: '100%',
      border: 0,
      backgroundColor: 'transparent',
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
      fontWeight: 'bold',
      color: '#00597b',
      textAlign: 'left',
    },
    inputFormCrimeEdit: {
      display: 'block',
      height: 20,
      outline: 0,
      width: '100%',
      border: 0,
      backgroundColor: 'transparent',
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
      fontWeight: 'bold',
      color: '#00597b',
      borderBottom: '1px solid #000',
    },
    inputMaterial: {
      width: '100%',
    },
    table: {
      width: '100%',
    },
    containerForm: {
      //padding: 15
    },
    containerCrimeGrid: {
      paddingRight: 20,
      paddingLeft: 10,
      marginTop: 5,
    },
    textCrime: {
      justifyContent: 'center',
      fontWeight: 'bold',
      fontSize: 13,
      fontFamily: 'roboto-regular',
      width: '100%',
    },
    autoContainer: {
      marginTop: -240,
      '@media (max-width:600px)': {
        marginTop: -340,
        Height: 130,
        maxHeight: 300,
        minHeight: 300,
        maxWidth: '100%',
        minWidth: '100%',
        position: 'relative',
      },
      maxHeight: 200,
      minHeight: 200,
      minWidth: 300,
      position: 'relative',
      zIndex: 1,
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: 1,
      },
      backgroundColor: '#ededed',
      borderRadius: 10,
      padding: 5,
      border: '1px solid #292929',
    },
    containerCrime: {
      position: 'relative',
      '@media (max-width:600px)': {
        maxHeight: '100%',
        minHeight: '100%',
        maxWidth: '100%',
        minWidth: '100%',
      },
      maxHeight: '100%',
      minHeight: '100%',
      maxWidth: '100%',
      minWidth: '100%',
    },
    optionCrime: {
      padding: 5,
      borderRadius: 2,
      borderBottom: '1px solid #999',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#b9b9b9',
      },
    },
    itemOptionCrime: {
      cursor: 'pointer',
    },
    addCrimeContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 5,
      minHeight: 80,
      maxHeight: 160,
      overflow: 'auto',
      border: '1px solid #999',
      borderRadius: 5,
      marginTop: 5,
    },
    textoCrimeContainer: {
      color: '#00b1ac',
      fontWeight: 'bold',
      borderBottom: '1px solid #999',
      paddingBottom: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: 40,
      textAlign: 'justify',
    },
    chipNonPersecution: {
      margin: 2,
      backgroundColor: '#00597b',
      color: '#ffffff',
    },
    chip: {
      margin: 2,
      backgroundColor: '#00b1ac',
      color: '#ffffff',
    },
    cardCrimePersecution: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'start',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      overflowX: 'auto',
      float: 'left',
      '@media (max-width:600px)': {
        float: 'none',
      },
    },
    cardCrime: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'start',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      minHeight: 40,
      overflowX: 'auto',
      float: 'left',
      '@media (max-width:600px)': {
        float: 'none',
      },
      marginBottom: 10,
    },
  }));

  const { isPM } = useUserTypes();

  const classes = useStyles();

  const [editSubject, setEditSubject] = useState(false);
  const [keySubject, setKeySubject] = useState(false);

  const initialValues = {
    subjects: [],
  };

  const [formState, setFormState] = useState({
    investigated: [],
    values: { ...initialValues },
  });

  const handleEditeSubject = () => {
    setEditSubject(true);
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        subjects: subjects,
      },
    }));
  };

  const editCrimeSubjectsInquiry = subjects => {
    const params = {
      crimeSubjects: subjects,
    };

    if (isPM) {
      PmService.updateInquiryService(inquiryId, params).then(() => {
        toast.success('Dados alterados com sucesso!');
        refreshPage();
      });
    } else {
      inquiryService.updateInquiryService(inquiryId, params).then(() => {
        toast.success('Dados alterados com sucesso!');
        refreshPage();
      });
    }
  };

  const handleUpdateCrimeSubjects = () => {
    editCrimeSubjectsInquiry(formState.values.subjects);
    setEditSubject(false);
  };

  const handleEditeCrimeSubjectsToFalse = () => {
    setEditSubject(false);
  };

  const handleRemoveSubject = (event, subjectId) => {
    event.persist();
    let newListCrimeSubjects = formState.values.subjects.filter(subj => subj.id !== subjectId);
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        subjects: newListCrimeSubjects,
      },
    }));
  };

  const fetchCrimeSubjects = async (search, page) => {
    const params = {
      page,
      size: 20,
      search,
    }

    const result = await crimeSubjectService.getCrimeSubjectsService(params);

    return {
      data: result.data.data,
      lastPage: result.data.lastPage,
    }
  }

  const handleChangeSubjectAutocomplete = (event, newValue) => {
    if (!newValue) {
      return;
    }
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        subjects: [...formState.values.subjects, newValue],
      },
    }));
    setKeySubject(!keySubject);
  };

  const handleOpenSnackbar = (message = 'Você não tem permissão para tomar esta ação.') => {
    return toast.warn(message);
  };

  return (
    <CustomerDisplayValidator customerConfig={CC.INQUIRY_DISPLAY_SUBJECTS}>
      <Box className={classes.root}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingRight: 10,
            paddingLeft: 10,
          }}
        >
          <Box style={{ width: '50%' }}>
            <Typography className={classes.titulo}>Assuntos</Typography>
          </Box>
          <Box
            style={{
              width: '50%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {!editSubject && ((!otherStep && !isPM) || canEdit) ? (
              <Tooltip title="Alterar assuntos" arrow placement="top">
                <IconButton
                  className={classes.buttonEditar}
                  size="small"
                  onClick={() => (!write ? handleOpenSnackbar() : handleEditeSubject())}
                >
                  <CreateIcon style={{ color: '#00b1ac' }} />
                </IconButton>
              </Tooltip>
            ) : (
              editSubject && (
                <>
                  <Box style={{ marginRight: 10 }}>
                    <Tooltip title="Salvar alterações" arrow placement="top">
                      <IconButton
                        className={classes.buttonEditar}
                        size="small"
                        onClick={handleUpdateCrimeSubjects}
                        form="my-form-edit"
                        type="submit"
                      >
                        <SaveIcon style={{ color: '#1976d2' }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Tooltip title="Cancelar" arrow placement="top">
                      <IconButton
                        className={classes.buttonEditar}
                        size="small"
                        onClick={handleEditeCrimeSubjectsToFalse}
                      >
                        <ClearIcon style={{ color: '#e1296a' }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </>
              )
            )}
          </Box>
        </Box>
        {!editSubject && (
          <Grid container spacing={1} className={classes.containerCrimeGrid}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card className={classes.cardCrime}>
                {subjects.length > 0 ? (
                  subjects.map((subject, index) => (
                    <Tooltip key={index} title={subject.hierarchy} arrow placement="bottom">
                      <Chip
                        key={index}
                        className={classes.chip}
                        size="small"
                        label={[...subject.hierarchy.split(':')].pop()}
                      />
                    </Tooltip>
                  ))
                ) : (
                  <Chip className={classes.chip} size="small" label="Não informado" />
                )}
              </Card>
            </Grid>
          </Grid>
        )}
        {editSubject && (
          <Grid container spacing={1} style={{ marginTop: 5 }}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className={classes.containerLabelInput}>
                <LazyAutocomplete
                  onChange={handleChangeSubjectAutocomplete}
                  getOptionLabel={option => option.hierarchy}
                  renderOption={option => (
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <Typography variant="body1">
                            {option.hierarchy && `Nome ${option.hierarchy || 'não informado'}`}
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <Typography>
                        {option.hierarchy ? option.hierarchy : 'não informado'}{' '}
                      </Typography>
                    </HtmlTooltip>
                  )}
                  enablePagination
                  fetchOptions={fetchCrimeSubjects}
                  renderInput={params => (
                    <InputConsensus
                      {...params}
                      label="Assunto *"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {params.InputProps.loading ? (
                              <CircularProgress color="primary" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  key={keySubject}
                />
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 10 }}
            >
              <div className={classes.addCrimeContainer}>
                Assunto(s):
                {formState.values.subjects.map(subject => (
                  <div key={subject.id} className={classes.textoCrimeContainer}>
                    <Typography>{subject.hierarchy}</Typography>
                    <DeleteIcon
                      fontSize="small"
                      onClick={event => handleRemoveSubject(event, subject.id)}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        )}
      </Box>
    </CustomerDisplayValidator>
  );
};

export default CrimeSubjectsDetailInquiry;
