import {
  genericInquiry,
  noMaskInquiry,
  pmInquiry,
  federalInquiry,
  npuMask,
  policeReportMask,
  pcpeGenericInquiry,
} from '../masks/masks';

export const listEstadoCivil = () => {
  const listEstadoCivil = [
    {
      name: 'Solteiro(a)',
      value: 'Solteiro(a)',
    },
    {
      name: 'Casado(a)',
      value: 'Casado(a)',
    },
    {
      name: 'Divorciado(a)',
      value: 'Divorciado(a)',
    },
    {
      name: 'Separado(a) Judicialmente',
      value: 'Separado(a) Judicialmente',
    },
    {
      name: 'União Estável',
      value: 'União Estável',
    },
    {
      name: 'Viúvo(a)',
      value: 'Viúvo(a)',
    },
  ];
  return listEstadoCivil;
};

export const listOrgaoEmissorRg = () => {
  const listOrgaoEmissorRg = [
    {
      name: 'CNH',
      value: 'CNH',
    },
    {
      name: 'CPF',
      value: 'CPF',
    },
    {
      name: 'DETRAN',
      value: 'DETRAN',
    },
    {
      name: 'DPF',
      value: 'DPF',
    },
    {
      name: 'MAER',
      value: 'MAER',
    },
    {
      name: 'MD-EXE-BR',
      value: 'MD-EXE-BR',
    },
    {
      name: 'MIN. EX.',
      value: 'MIN. EX.',
    },
    {
      name: 'MTE',
      value: 'MTE',
    },
    {
      name: '[N.INF]',
      value: '[N.INF]',
    },
    {
      name: 'OAB',
      value: 'OAB',
    },
    {
      name: 'PM',
      value: 'PM',
    },
    {
      name: 'SDS',
      value: 'SDS',
    },
    {
      name: 'SESSEG/AM',
      value: 'SESSEG/AM',
    },
    {
      name: 'SJSP-TO',
      value: 'SJSP-TO',
    },
    {
      name: 'SSP',
      value: 'SSP',
    },
    {
      name: '5ªDESEC',
      value: '5ªDESEC',
    },
  ];
  return listOrgaoEmissorRg;
};

export const listClassesInquiry = () => {
  const listClassesInquiry = [
    {
      description: 'Auto de Prisão em Flagrante',
      code: '280',
    },
    {
      description: 'Auto de Prisão Federal',
      code: '280',
    },
    {
      description: 'Carta Precatória Criminal',
      code: '355',
      masks: [noMaskInquiry],
    },
    {
      description: 'Carta Precatória do Ministério Público',
      code: '910015',
    },
    {
      description: 'Inquérito Policial',
      code: '279',
    },
    {
      description: 'Inquérito Policial Federal',
      code: '279',
    },
    {
      description: 'Inquérito Policial Militar',
      code: '11041',
    },
    {
      description: 'Medidas Cautelares',
      code: 'cautelares',
    },
    {
      description: 'Medidas Garantidoras',
      code: 'garantidoras',
    },
    {
      description: 'Notícia de Fato',
      code: '910002',
    },
    {
      description: 'Documento Administrativo',
      code: '11788',
    },
    {
      description: 'Termo Circunstanciado',
      code: '278',
    },
  ];

  /*listClassesInquiry.sort(function (a, b) {
        return a.description.localeCompare(b.description);
    });*/

  return listClassesInquiry;
};

export const listClassesMedidasCautelares = [
  {
    description: 'Cautelar Inominada Criminal',
    code: '11955',
    masks: [pcpeGenericInquiry, genericInquiry],
  },
  {
    description: 'Medidas Investigatórias Sobre Organizações Criminosas',
    code: '311',
    masks: [pcpeGenericInquiry, genericInquiry],
  },
  {
    description: 'Medidas Protetivas - Estatuto do Idoso Criminal',
    code: '10967',
    masks: [pcpeGenericInquiry, genericInquiry],
  },
  {
    description: 'Medidas Protetivas de urgência (Lei Maria da Penha) Criminal',
    code: '1268',
    masks: [pcpeGenericInquiry, genericInquiry],
  },
  {
    description: 'Pedido de Busca e Apreensão Criminal',
    code: '309',
    masks: [pcpeGenericInquiry, genericInquiry],
  },
  {
    description: 'Pedido de Prisão',
    code: '312',
    masks: [pcpeGenericInquiry, genericInquiry],
  },
  {
    description: 'Pedido de Prisão Preventiva',
    code: '313',
    masks: [pcpeGenericInquiry, genericInquiry],
  },
  {
    description: 'Pedido de Prisão Temporária',
    code: '314',
    masks: [pcpeGenericInquiry, genericInquiry],
  },
  {
    description: 'Pedido de Quebra de Sigilo de Dados e/ou Telefônico',
    code: '310',
    masks: [pcpeGenericInquiry, genericInquiry],
  },
  {
    description: 'Produção Antecipada de Provas Criminal',
    code: '11793',
    masks: [pcpeGenericInquiry, genericInquiry],
  },
];

export const listClassesMedidasGarantidoras = [
  {
    description: 'Habeas Corpus Criminal',
    code: '307',
    masks: [noMaskInquiry],
  },
  {
    description: 'Liberdade',
    code: '304',
    masks: [pcpeGenericInquiry, genericInquiry],
  },
  {
    description: 'Liberdade Provisória com ou sem fiança',
    code: '305',
    masks: [pcpeGenericInquiry, genericInquiry],
  },
  {
    description: 'Relaxamento de Prisão',
    code: '306',
    masks: [pcpeGenericInquiry, genericInquiry],
  },
  {
    description: 'Revogação de Prisão',
    code: '910016',
    masks: [pcpeGenericInquiry, genericInquiry],
  },
];

export const listClassesProcessosJudiciais = [
  {
    description: 'Auto de Prisão em Flagrante (Processos Judiciais)',
    code: '280.J',
    masks: [npuMask],
  },
  {
    description: '2º grau (Processos Judiciais)',
    code: '278.J',
    masks: [npuMask],
  },
];

export const listClassesProJudCautelares = [
  {
    description: 'Cautelar Inominada Criminal (Processos Judiciais)',
    code: '11955.J',
    masks: [npuMask],
  },
  {
    description: 'Medidas Investigatórias Sobre Organizações Criminosas (Processos Judiciais)',
    code: '311.J',
    masks: [npuMask],
  },
  {
    description: 'Medidas Protetivas - Estatuto do Idoso Criminal (Processos Judiciais)',
    code: '10967.J',
    masks: [npuMask],
  },
  {
    description:
      'Medidas Protetivas de urgência (Lei Maria da Penha) Criminal (Processos Judiciais)',
    code: '1268.J',
    masks: [npuMask],
  },
  {
    description: 'Pedido de Busca e Apreensão Criminal (Processos Judiciais)',
    code: '309.J',
    masks: [npuMask],
  },
  {
    description: 'Pedido de Prisão (Processos Judiciais)',
    code: '312.J',
    masks: [npuMask],
  },
  {
    description: 'Pedido de Prisão Preventiva (Processos Judiciais)',
    code: '313.J',
    masks: [npuMask],
  },
  {
    description: 'Pedido de Prisão Temporária (Processos Judiciais)',
    code: '314.J',
    masks: [npuMask],
  },
  {
    description: 'Pedido de Quebra de Sigilo de Dados e/ou Telefônico (Processos Judiciais)',
    code: '310.J',
    masks: [npuMask],
  },
  {
    description: 'Produção Antecipada de Provas Criminal (Processos Judiciais)',
    code: '11793.J',
    masks: [npuMask],
  },
];

export const listClassesGenericas = [
  {
    description: 'Auto de Prisão em Flagrante',
    code: '280',
    masks: [pcpeGenericInquiry, genericInquiry],
  },
  {
    description: 'Auto de Prisão Federal',
    code: '280',
    masks: [federalInquiry],
  },
  {
    description: 'Auto de Prisão em Flagrante Policial Militar',
    code: '280',
    masks: [pmInquiry],
  },
  {
    description: 'Carta Precatória Criminal',
    code: '355',
    masks: [npuMask],
  },
  {
    description: 'Carta Precatória do Ministério Público',
    code: '910015',
    masks: [noMaskInquiry],
  },
  {
    description: 'Inquérito Policial',
    code: '279',
    masks: [pcpeGenericInquiry, genericInquiry],
  },
  {
    description: 'Inquérito Policial Federal',
    code: '279',
    masks: [federalInquiry],
  },
  {
    description: 'Inquérito Policial Militar',
    code: '11041',
    masks: [pmInquiry],
  },
  {
    description: 'Instrução Provisória de Deserção - IPD',
    code: '11041.2',
    masks: [pmInquiry],
  },
  {
    description: 'Notícia de Fato',
    code: '910002',
    masks: [noMaskInquiry],
  },
  {
    description: 'Documento Administrativo',
    code: '11788',
    masks: [noMaskInquiry],
  },
  {
    description: 'Requisitório Inquérito Policial',
    code: '279.1',
    masks: [pcpeGenericInquiry, genericInquiry],
  },
  {
    description: 'Procedimento Investigatório Criminal (PIC-MP)',
    code: '1733',
    masks: [noMaskInquiry],
  },
  {
    description: 'Processo Judicial para Cumprimento de Acordo',
    code: '279.2',
    masks: [npuMask],
  },
  {
    description: 'Sindicância Administrativa Disciplinar - SAD',
    code: '11041.1',
    masks: [pmInquiry],
  },
  {
    description: 'Termo Circunstanciado',
    code: '278',
    masks: [noMaskInquiry],
  },
];

export const listClassesMedidasIncidentais = [
  {
    description: 'Pedido de Restituição de Coisa Apreendida (Processos Judiciais)',
    code: '14123.J',
    masks: [npuMask],
  },
];

export const listClassesAtosInfracionais = [
  {
    description: 'Boletim de Ocorrência Circunstanciado',
    code: '1463',
    masks: [policeReportMask, pcpeGenericInquiry, genericInquiry],
  },
  {
    description: 'Procedimento Especial de Menor',
    code: '1462',
    masks: [pcpeGenericInquiry, genericInquiry],
  },
  {
    description: 'Auto de Apreensão em Flagrante por Ato Infracional',
    code: '1461',
    masks: [pcpeGenericInquiry, genericInquiry],
  },
];

export const listClassesAtosInfracionaisAlternativas = [
  {
    description: 'Boletim de Ocorrência Circunstanciada',
    code: '1463',
    masks: [policeReportMask, pcpeGenericInquiry, genericInquiry],
  },
];

export const classeAAFAI = {
  description: 'Auto de Apreensão em Flagrante por Ato Infracional',
  code: '1461',
  masks: [pcpeGenericInquiry, genericInquiry],
};

export const listClassesAll = [
  ...listClassesMedidasCautelares,
  ...listClassesMedidasGarantidoras,
  ...listClassesGenericas,
  ...listClassesProcessosJudiciais,
  ...listClassesProJudCautelares,
  ...listClassesMedidasIncidentais,
  ...listClassesAtosInfracionais,
]