import React from 'react';
import { Grid, Typography, List, ListItem, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ButtonConsensus } from 'components';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 400,
    marginTop: theme.spacing(1),
  },
  containerList: {
    padding: 0,
  },
  listitem: {
    width: '100%',
    padding: theme.spacing(2),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
  },
  itemLeft: {
    marginRight: theme.spacing(1),
  },
  importButton: {
    margin: theme.spacing(1, 0, 0, 0),
  },
  containerSwitch: {
    marginTop: theme.spacing(5),
  },
}));

const InquiryItem = props => {
  const { inquiry, handleSelectInquiry, loadingImport } = props;
  const classes = useStyles();

  const handleImportClick = () => {
    if (inquiry?.policeInquiryNumber?.length === 0) {
      toast.error('o inquérito importado precisa ter número de IP.');
      return;
    }
    handleSelectInquiry(inquiry.internalSystemNumber);
  };

  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <List component="div" aria-label="main mailbox">
        <ListItem className={classes.containerList}>
          <Paper elevation={3} className={classes.listitem}>
            <div className={classes.row}>
              <div className={classes.item}>
                <Typography className={classes.itemLeft}>Numero interno:</Typography>
                <Typography>{inquiry.internalSystemNumber}</Typography>
              </div>
              <div className={classes.item}>
                <Typography className={classes.itemLeft}>Classe:</Typography>
                <Typography>
                  {inquiry.classe}
                  {', '}
                  {inquiry.classeDescription}
                </Typography>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.item}>
                <Typography className={classes.itemLeft}>Arquimedes - auto:</Typography>
                <Typography>{inquiry.arquimedesAuto}</Typography>
              </div>
              <div className={classes.item}>
                <Typography className={classes.itemLeft}>IP:</Typography>
                <Typography>{inquiry.policeInquiryNumber}</Typography>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.item}>
                <ButtonConsensus
                  title="Importar"
                  onClick={handleImportClick}
                  margin={0}
                  className={classes.importButton}
                  size="medium"
                  disabled={loadingImport}
                />
              </div>
            </div>
          </Paper>
        </ListItem>
      </List>
    </Grid>
  );
};

export default InquiryItem;